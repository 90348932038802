import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContactsBottomModal } from "@app/modals";
import {
  IconChevronDown24,
  IconLogo,
  IconPersonCircle32,
  IconPhone24,
} from "@app/icons";

import * as Styled from "./styled";
import { useOutsideClick, useWindowWide } from "@app/hooks";
import { ContactsInfo } from "@app/common";

interface Props {}

function Header(_props: Props) {
  const navigate = useNavigate();
  const isMobile = useWindowWide(576);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isVisible, onContactsButtonClick] = useOutsideClick(wrapperRef);
  const [isBottomModalOpen, setBottomModalOpen] = useState(false);

  const navItems = useMemo(
    () => [
      {
        label: "Возможности",
        slug: "section-possibilities",
      },
      {
        label: "GPS",
        slug: "section-gps",
      },
      {
        label: "Интеграции",
        slug: "section-integrations",
      },
      {
        label: "Сотрудничество",
        slug: "section-partnership",
      },
    ],
    []
  );

  const onClickNavLink = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const anchorElement = e.target as HTMLElement;
      const scrollToElement = document.getElementById(
        anchorElement.getAttribute("href")!.replace("#", "")
      );

      if (scrollToElement) {
        window.scrollTo({
          top: scrollToElement.offsetTop - 92,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    []
  );

  const onClickSignIn = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();

      navigate("/sign-in");
    },
    [navigate]
  );

  const onContactsClick = () => {
    if (isMobile) {
      setBottomModalOpen(true);
    } else {
      onContactsButtonClick();
    }
  };

  const onModalClose = () => {
    setBottomModalOpen(false);
  };

  return (
    <>
      <Styled.Header>
        <Styled.HeaderContainer>
          <Styled.LogoContainer to="/">
            <IconLogo />
          </Styled.LogoContainer>
          <Styled.HeaderNav>
            {navItems.map((navItem, navItemIndex) => (
              <Styled.HeaderNavItem key={navItemIndex.toString()}>
                <a href={`#${navItem.slug}`} onClick={onClickNavLink}>
                  {navItem.label}
                </a>
              </Styled.HeaderNavItem>
            ))}
          </Styled.HeaderNav>
          <div ref={wrapperRef} style={{ position: "relative" }}>
            <Styled.ContactButton onClick={onContactsClick}>
              <IconPhone24 />
              Контакты
              <IconChevronDown24 />
            </Styled.ContactButton>
            {isVisible && (
              <Styled.ContactsPopup>
                <ContactsInfo />
              </Styled.ContactsPopup>
            )}
          </div>
          <Styled.IconButton href="#" onClick={onClickSignIn}>
            <IconPersonCircle32 />
            <span className="btn-title">Войти</span>
          </Styled.IconButton>
        </Styled.HeaderContainer>
      </Styled.Header>
      <ContactsBottomModal open={isBottomModalOpen} onClose={onModalClose} />
    </>
  );
}

export default memo(Header);
