import { BaseButton, Grid, ListItem } from "@app/components";
import { SmartContractStatusBadge } from "@app/common";
import { ActDetail } from "@app/models";
import { dateFormat } from "@app/helpers";

import * as Styled from "./styled";

type Props = {
  act: ActDetail | null;
  onContractClick: () => void;
};

function AOSDetailHeader(props: Props) {
  const { act, onContractClick } = props;
  return (
    <Styled.Header>
      <Grid columns={4}>
        <ListItem title={act?.company.name} subtitle={"Организация"} />
        <ListItem title={act?.partner.name} subtitle={"Контрагент"} />
        <ListItem
          title={dateFormat(act?.startWorkDate ?? null, "dd.MM.yyyy")}
          subtitle={"Дата начала"}
        />
        <ListItem title={act?.nds?.name} subtitle={"Ставка НДС"} />
        <ListItem title={act?.project?.name} subtitle={"Проект"} />
        {act && (
          <ListItem
            title={
              <SmartContractStatusBadge status={act.contract.status}>
                <BaseButton
                  disabled={!act?.contract.contractDraftId}
                  onClick={onContractClick}
                >
                  <Styled.ContractTitle
                    clickable={!!act?.contract.contractDraftId}
                  >
                    {act?.contract?.name}
                  </Styled.ContractTitle>
                </BaseButton>
              </SmartContractStatusBadge>
            }
            subtitle={"Договор"}
          />
        )}
        <ListItem
          title={dateFormat(act?.endWorkDate ?? null, "dd.MM.yyyy")}
          subtitle={"Дата завершения"}
        />
      </Grid>
    </Styled.Header>
  );
}

export default AOSDetailHeader;
