import styled from "styled-components";
import { theme } from "styled-tools";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;
  margin-bottom: 16px;
  background: #f5f6f8;
  border-bottom: 1px solid #d8d8d8;
`;

export const Container = styled.div`
  flex: 1;
  overflow: auto;
`;

export const TableContainer = styled.div`
  margin: 16px;

  h2 {
    padding: 8px 16px;
  }
`;

export const ParentCell = styled.td`
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  padding: 12px;
  width: 1%;
  white-space: nowrap;
  text-align: left;
  border-top: 1px solid ${theme("color.grayLight")};
  font-family: ${theme("fontFamily")};
  color: ${theme("color.dark")};
  background-color: ${theme("color.blueLight")};
`;

export const WrappedCell = styled.div`
  max-width: 340px;
  font-size: 14px;
  line-height: 16px;
  white-space: normal;
`;

export const Button = styled.button`
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  color: ${theme("color.orange")};
  opacity: 0.8;

  &:hover {
    opacity: 1;
    transform: scale(1.2);
  }
  &:disabled {
    opacity: 1 !important;
    transform: scale(1);
    cursor: default;
    color: ${theme("color.gray")};
    box-shadow: none;
  }
`;

export const ContractTitle = styled.p<{ clickable: boolean }>`
  text-align: start;
  text-decoration: ${(props) => (props.clickable ? "underline" : "initial")};
`;

export const PlayBtn = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: ${theme("color.green")};
  cursor: pointer;
  border-radius: 50%;
`;
