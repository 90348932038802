import React, { memo, useCallback, useMemo, useState } from "react";
import { VehicleRequestOffer } from "@app/models";
import { currencyFormat, dateFormat, getAxiosErrorMessage } from "@app/helpers";
import { TemplateSearch } from "@app/templates";
import {
  APIResponse,
  deleteVehicleRequestOffer,
  getVehicleRequestOffers,
  PageableParams,
} from "@app/api";
import { VReqOfferStatusChip } from "@app/common";
import {
  ModalMyVehicleOfferDetail,
  ModalVehicleRequestOfferEdit,
} from "@app/modals";
import { IconPencil24, IconTrash24 } from "@app/icons";
import MoreMenu, { IMenuItem } from "../../../common/MoreMenu/MoreMenu";
import { Dialog } from "@app/components";
import { AxiosError } from "axios";
import { useNotification } from "@app/providers";

function MyVehicleRequests() {
  const [selectedOffer, setSelectedOffer] =
    useState<VehicleRequestOffer | null>(null);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState(false);

  const { showNotification } = useNotification();

  const refreshTableData = useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, []);

  const getData = useCallback(
    (params: PageableParams) => {
      if (loading) {
        return new Promise<any>(() => {});
      }
      return getVehicleRequestOffers(params);
    },
    [loading]
  );

  const onClickItem = useCallback((item: VehicleRequestOffer) => {
    setSelectedOffer(item);
    setOpenDetail(true);
  }, []);

  const onDialogOpen = useCallback((item: VehicleRequestOffer) => {
    setSelectedOffer(item);
    setShowDialog(true);
  }, []);

  const onDialogClose = useCallback(() => {
    setShowDialog(false);
    setSelectedOffer(null);
  }, []);

  const onDetailClose = useCallback(() => {
    setOpenDetail(false);
    setSelectedOffer(null);
  }, []);

  const onDeleteConfirm = useCallback(async () => {
    try {
      setPending(true);
      const res = await deleteVehicleRequestOffer(selectedOffer!.id);
      if (res.succeeded) {
        refreshTableData();
      }
      setPending(false);
      setShowDialog(false);
      setOpenDetail(false);
      setSelectedOffer(null);
    } catch (e) {
      setPending(false);
      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [refreshTableData, selectedOffer, showNotification]);

  const onOfferEdit = useCallback((item: VehicleRequestOffer) => {
    setOpenEdit(true);
    setSelectedOffer(item);
  }, []);

  const onOfferEditClose = useCallback(() => {
    refreshTableData();
    setOpenEdit(false);
    setOpenDetail(false);
    setSelectedOffer(null);
  }, [refreshTableData]);

  const tableLabels = useMemo(
    () => [
      "№",
      "Проект",
      "Регион",
      "Вид техники",
      "Гос номер",
      "Дата начала",
      "Дата завершения",
      "Цена",
      "Статус",
      "",
    ],
    []
  );

  const menuItems: (item: VehicleRequestOffer) => IMenuItem[] = useCallback(
    (item) => [
      {
        icon: IconPencil24,
        label: "Редактировать",
        onClick: () => onOfferEdit(item),
      },
      {
        icon: IconTrash24,
        label: "Удалить",
        onClick: () => onDialogOpen(item),
      },
    ],
    [onDialogOpen, onOfferEdit]
  );

  const mapTableData = useCallback(
    (item: VehicleRequestOffer) => {
      return [
        item.number,
        item.request.project.name,
        item.request.project.region?.name,
        item.request.vehicleType.name,
        item.vehicle.govNumber,
        dateFormat(item.request.startDate, "dd.MM.yyyy"),
        dateFormat(item.request.endDate, "dd.MM.yyyy"),
        currencyFormat(item.nds.isNds ? item.price : item.priceWithoutNds),
        <VReqOfferStatusChip status={item.status} />,
        <MoreMenu items={menuItems(item)} />,
      ];
    },
    [menuItems]
  );

  return (
    <>
      <TemplateSearch<VehicleRequestOffer>
        toolbarProps={{
          searchPlaceholder: "№, проект, вид техники",
        }}
        getData={getData}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        onClick={onClickItem}
      />
      {selectedOffer && (
        <ModalMyVehicleOfferDetail
          open={openDetail}
          data={selectedOffer}
          onClose={onDetailClose}
          onEdit={onOfferEdit}
          onDelete={onDialogOpen}
        />
      )}
      {selectedOffer && (
        <ModalVehicleRequestOfferEdit
          open={openEdit}
          hideInfo={true}
          offer={selectedOffer}
          request={selectedOffer.request}
          onSuccess={onOfferEditClose}
          onClose={onOfferEditClose}
        />
      )}
      <Dialog
        open={showDialog}
        title={`Удалить предложение №${selectedOffer?.number}?`}
        subtitle="Ценовое предложение на технику будет удалено"
        icon={IconTrash24}
        loading={pending}
        onConfirm={onDeleteConfirm}
        onClose={onDialogClose}
      />
    </>
  );
}

export default memo(MyVehicleRequests);
