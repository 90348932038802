export const moduleNotAvailable = "Данный раздел недоступен";
export const needToSignAdhesion =
  "Для полноценной работы, необходимо подписать договор присоединения";

export const contactsInfo = [
  {
    name: "Шолпанқұл Бекболат",
    phone: "+7 776 122 20 51",
    email: "sbs@dispatchcds.com",
  },
  {
    name: "Насимулдинов Нурхат",
    phone: "+7 777 110 75 44",
    email: "nn@dispatchcds.com",
  },
];
