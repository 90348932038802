import React, { memo, useCallback } from "react";
import Field, { FieldProps } from "../Field";
import "./styles.scss";
import PhoneInput, { getCountries, Value } from "react-phone-number-input";
import ru from "./ru.json";

interface Props extends FieldProps {
  value?: string;
  onChange?: (value: string, name: any) => void;
  onBlur?: () => void;
}

function PhoneField(props: Props) {
  const {
    value = "",
    onChange,
    onBlur,
    placeholder,
    label,
    name = "",
    className = "",
    disabled = false,
    ...fieldProps
  } = props;

  const onChangePhone = useCallback(
    (value?: Value) => {
      if (!value) {
        return;
      }
      if (!!onChange) {
        onChange(`${value}`, name);
      }
    },
    [name, onChange]
  );

  const countries = getCountries();
  const isUzbekistan = countries.includes("UZ");

  return (
    <Field
      {...fieldProps}
      disabled={disabled}
      className={`${className}`.trim()}
      label={label}
    >
      <PhoneInput
        placeholder={placeholder}
        countryCallingCodeEditable={false}
        defaultCountry="KZ"
        labels={ru}
        value={value}
        disabled={disabled}
        international={false}
        countries={["KZ", "UZ"]}
        numberInputProps={{
          maxLength: isUzbekistan ? 17 : 15,
        }}
        name="phone"
        autoComplete={"tel"}
        className="phone-input"
        onChange={onChangePhone}
        onBlur={onBlur}
      />
    </Field>
  );
}

export default memo(PhoneField);
