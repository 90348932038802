import { LayoutDefault } from "@app/layouts";
import React, { memo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "styled-tools";
import { IMainMenuItem, useMenuList } from "@app/hooks";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: ${theme("color.white")};
  height: calc(100% - 32px);
`;

const StyledGrid = styled.div`
  display: grid;
  height: 100%;
  grid-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding: 16px;
`;

const StyledListItem = styled.div<{ bg: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 16px;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  border: 1px solid ${theme("color.grayLight")};
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  p {
    color: ${theme("color.white")};
    //font-size: 18px;
    font-size: 3vh;
    font-weight: 600;
    line-height: 3.5vh;
    text-align: center;
  }

  &:hover {
    transform: scale(1.02);
    p {
      text-decoration: underline;
    }
  }
`;

const StyledIcon = styled.div`
  position: relative;
  transform: scale(2);
  filter: invert(100%);

  svg {
    display: inline-block;
    width: 4vh;
    height: 4vh;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledBadge = styled.div`
  position: absolute;
  transform: scale(0.5);
  filter: invert(100%);
  top: -1.25vh;
  right: -1.25vh;
  width: 2.5vh;
  height: 2.5vh;
  background-color: ${theme("color.red")};
  border-radius: 50%;
  padding: 0.25vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme("color.white")};
  font-size: 2vh;
  line-height: 16px;
  text-decoration: none;
`;

function MenuItem(item: IMainMenuItem) {
  const navigate = useNavigate();
  const { path, title, icon: Icon, activeIcon: IconHover, badge, bg } = item;

  const [hover, setHover] = useState(false);

  const onClick = useCallback(() => {
    navigate(path);
  }, [navigate, path]);

  return (
    <StyledListItem
      bg={bg}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <StyledIcon>
        {hover ? <IconHover /> : <Icon />}

        {!!badge && <StyledBadge>{badge}</StyledBadge>}
      </StyledIcon>
      <StyledTitle>
        <p>{title}</p>
      </StyledTitle>
    </StyledListItem>
  );
}

function PageMain() {
  const { routesForMainPage } = useMenuList();

  return (
    <LayoutDefault title="Главная">
      <StyledContainer>
        <StyledGrid>
          {routesForMainPage.map((item, index) => (
            <MenuItem key={index.toString()} {...item} />
          ))}
        </StyledGrid>
      </StyledContainer>
    </LayoutDefault>
  );
}

export default memo(PageMain);
