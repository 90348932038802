import React, { MouseEvent, useCallback, useState } from "react";
import { dateFormat, priceFormat, removeLeadingZero } from "@app/helpers";
import { IconPlayFilled24 } from "@app/icons";
import styled from "styled-components";
import { ifProp, prop, theme } from "styled-tools";
import { KIPVehicleDetails } from "@app/models";
import ShiftTime from "../ShiftTime";
import { Info } from "@app/components";

interface Props {
  data: KIPVehicleDetails;
  variant: "full" | "info";
  showTrack?: (data: KIPVehicleDetails) => void;
}

interface DetailsPosition {
  left: string;
  bottom: string;
}

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 32px auto;
  //gap: 8px;

  &:last-child {
    #shiftRow {
      border-bottom: 1px dashed ${theme("color.gray")};
    }
  }
`;

const StyledRowLeft = styled.div`
  padding-top: 2px;
`;

const StyledRowRight = styled.div`
  box-sizing: border-box;
  border-left: 1px dashed ${theme("color.gray")};
`;

const StyledDate = styled.p`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  color: ${theme("color.grayDark")};
  margin: 0;
  text-align: center;
`;

const StyledPlayButton = styled.button`
  display: block;
  padding: 0;
  margin: 4px 16px 0 8px;
  outline: none;
  border: none;
  background-color: transparent;
  width: 24px;
  height: 24px;
  color: ${theme("color.dark")};
`;

const StyledInfo = styled.div<Pick<Props, "variant">>`
  display: grid;
  grid-template-columns: ${ifProp(
    {
      variant: "full",
    },
    "auto 1fr auto",
    "auto"
  )};

  > .shifts {
    padding: 0 0 16px 8px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid transparent;

    &:hover {
      border-color: ${ifProp(
        {
          variant: "full",
        },
        theme("color.orange"),
        "transparent"
      )};
      background-color: ${ifProp(
        {
          variant: "full",
        },
        "rgba(52, 241, 218, 1)",
        "transparent"
      )};
    }
  }
`;

const StyledDetails = styled.div<DetailsPosition>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  position: fixed;
  background-color: ${theme("color.dark")};
  box-sizing: border-box;
  padding: 16px;
  left: ${prop("left")};
  bottom: calc(100% - ${prop("bottom")});
  border-radius: 8px;
  z-index: 1;
`;

const StyledGroupTitle = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${theme("color.grayLight")};
  margin: 0;
`;

const StyledGroupName = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: ${theme("color.grayLight")};
  border-top: 1px solid ${theme("color.grayLight")};
  padding-top: 16px;
`;

const StyledShiftChip = styled.div`
  height: 24px;
  border-radius: 4px;
  background-color: rgba(224, 222, 153, 0.7);

  span {
    color: ${theme("color.dark")};
    font-size: 12px;
    line-height: 24px;
    margin: 0 4px;
  }
`;

export default function (props: Props) {
  const { data, variant, showTrack } = props;
  const [position, setPosition] = useState<DetailsPosition | null>(null);

  const onClickShowTrack = useCallback(() => {
    if (!!showTrack) {
      showTrack(data);
    }
  }, [data, showTrack]);

  const getDuration = useCallback((duration: string) => {
    if (!duration) {
      return "-";
    }

    const shiftDuration = duration.split(":");
    let returnData = "";

    const hours = removeLeadingZero(shiftDuration[0]);
    const minutes = removeLeadingZero(shiftDuration[1]);

    if (hours > 0) {
      returnData = `${hours} ч`;
    }

    if (minutes > 0) {
      returnData += ` ${minutes} мин`;
    }

    return returnData.trim() || "-";
  }, []);

  const onMouseEnter = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (variant === "info") {
        return;
      }

      const { currentTarget } = e;
      const rect = currentTarget.getBoundingClientRect();

      if (rect.top + rect.height >= 480) {
        setPosition({
          left: `${(rect.left + rect.width / 2).toFixed(0)}px`,
          bottom: `${(rect.bottom - rect.height + 8).toFixed(0)}px`,
        });

        return;
      }

      setPosition({
        left: `${(rect.left + rect.width / 2).toFixed(0)}px`,
        bottom: `${(rect.top + rect.bottom + rect.height).toFixed(0)}px`,
      });
    },
    [variant]
  );

  const onMouseLeave = useCallback(() => {
    setPosition(null);
  }, []);

  const formattedDate = useCallback(() => {
    if (/\d{1,2}\.\d{1,2}\.\d{2,4}/.test(data.date)) {
      // добавилась дата формата dd.mm.yyyy
      const [d, m, y] = data.date.split(".");
      return new Date(+y, +m - 1, +d);
    } else if (/\d{1,2}\/\d{1,2}\/\d{2,4}/.test(data.date)) {
      const [d, m, y] = data.date.split("/");
      return new Date(+y, +m - 1, +d);
    }
    return null;
  }, [data.date]);

  return (
    <>
      <StyledRow>
        <StyledRowLeft>
          <StyledDate>{dateFormat(formattedDate(), "dd.MM")}</StyledDate>
        </StyledRowLeft>
        <StyledRowRight id="shiftRow">
          <StyledInfo variant={variant}>
            {variant === "full" && (
              <div>
                <StyledPlayButton onClick={onClickShowTrack}>
                  <IconPlayFilled24 />
                </StyledPlayButton>
              </div>
            )}
            <div
              className="shifts"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <ShiftTime
                data={data.rpms}
                type={data.shiftTypeCode}
                variant={variant}
                workStartDateTime={data.workStartDateTime}
                workEndDateTime={data.workEndDateTime}
              />
              {!!position && variant === "full" && (
                <StyledDetails left={position.left} bottom={position.bottom}>
                  <StyledGroupTitle>{data.date}</StyledGroupTitle>
                  <Info
                    label="Цена аренды"
                    value={
                      data.price ? `${priceFormat(data.price.toFixed(0))}` : "-"
                    }
                    color="light"
                    size="small"
                  />
                  <Info
                    label="Ед.изм"
                    value={data.unitName || "-"}
                    color="light"
                    size="small"
                  />
                  <Info
                    label="Загруженность"
                    value={data.workLoad ? `${data.workLoad.toFixed(0)}%` : "-"}
                    color="light"
                    size="small"
                  />
                  <Info
                    label="Сумма к оплате"
                    value={
                      data.costAmount
                        ? priceFormat(data.costAmount.toFixed(0))
                        : "-"
                    }
                    color="light"
                    size="small"
                  />
                  <Info
                    label="Потери"
                    value={
                      data.unusedAmount
                        ? priceFormat(data.unusedAmount.toFixed(0))
                        : "-"
                    }
                    color="light"
                    size="small"
                  />
                  <StyledGroupName>В геозоне:</StyledGroupName>
                  <Info
                    label="Моточасы вкл:"
                    value={getDuration(data.engineOperationTimeInGeofence)}
                    size="small"
                    color="light"
                  />
                  <Info
                    label="Моточасы выкл:"
                    value={getDuration(data.engineOffTimeInGeofence)}
                    size="small"
                    color="light"
                  />
                  <StyledGroupName>Итого:</StyledGroupName>
                  <Info
                    label="Моточасы вкл:"
                    value={getDuration(data.hoursGps)}
                    size="small"
                    color="light"
                  />
                  <Info
                    label="Моточасы выкл:"
                    value={getDuration(data.engineOffHours)}
                    size="small"
                    color="light"
                  />
                  <Info
                    label="Пробег:"
                    value={data.mileageGps ? `${data.mileageGps} км` : "0"}
                    size="small"
                    color="light"
                  />
                  <Info
                    label="Длительность смены:"
                    value={getDuration(data.shiftDuration)}
                    size="small"
                    color="light"
                  />
                  <Info
                    label="Количество к оплате:"
                    value={
                      data.workPerformed ? data.workPerformed.toString() : "-"
                    }
                    size="small"
                    color="light"
                  />
                </StyledDetails>
              )}
            </div>
            {variant === "full" && (
              <StyledShiftChip>
                <span>
                  {data.workPerformed} {data.unitName}
                </span>
              </StyledShiftChip>
            )}
          </StyledInfo>
        </StyledRowRight>
      </StyledRow>
    </>
  );
}
