import React, { memo, useEffect, useMemo, useState } from "react";
import {
  DateTimePicker,
  Grid,
  Select,
  SelectOption,
  TextField,
} from "@app/components";
import styled from "styled-components";

type DataErrors = {
  [key in keyof any]?: string;
};

type Region = {
  id: string;
  name: string;
};

type Country = {
  id: string;
  name: string;
};

interface Props {
  dataOfOrganization: any;
  dataParams?: any;
  dataErrors: DataErrors;
  onDataChange: (value: any[keyof any], key: keyof any) => void;
  readonly: boolean;
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledHeader = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 8px;
`;

const StyledAddressContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function Step3(props: Props) {
  const { dataParams, dataErrors, readonly, onDataChange } = props;
  const [errors, setErrors] = useState<DataErrors>({});
  const [showSeriesField, setShowSeriesField] = useState(true);

  useEffect(() => {
    setErrors(dataErrors);
  }, [dataErrors]);

  const handleChange = (value: any, name: any) => {
    onDataChange(value, name);
    if (name === "registrationCertificateDate") {
      checkDate(value);
    }
  };

  const contractBasisType: SelectOption<any>[] = useMemo(() => {
    if (dataParams.companyType && dataParams.companyType.value === 1) {
      return [
        {
          label:
            "Свидетельство (Талон) о регистрации индивидуального предпринимателя",
          value: 3,
          item: null,
        },
      ];
    }

    if (dataParams.companyType && dataParams.companyType.value === 2) {
      return [
        {
          label: "Устав",
          value: 1,
          item: null,
        },
        {
          label: "Доверенности",
          value: 2,
          item: null,
        },
      ];
    }
    return [];
  }, [dataParams.companyType]);

  useEffect(() => {
    if (dataParams.registrationCertificateDate) {
      checkDate(dataParams.registrationCertificateDate);
    }
  }, [dataParams.registrationCertificateDate]);

  const checkDate = (date: string) => {
    const selectedDate = new Date(date);
    const cutOffDate = new Date("2014-12-31");
    const show = selectedDate < cutOffDate;
    setShowSeriesField(show);
  };

  return (
    <div>
      <Header>
        <StyledHeader>Основание деятельности</StyledHeader>
        <StyledAddressContent>
          <Grid columns={1}>
            <Select
              label="Вид основания"
              name="type"
              onChange={handleChange}
              value={dataParams.type}
              error={!!errors.type}
              helperText={errors.type}
              valueKey="id"
              labelKey="name"
              options={contractBasisType}
              readonly={readonly}
            />
          </Grid>
          {dataParams.type && dataParams.type.value === 2 && (
            <Grid columns={2} style={{ marginTop: 16 }}>
              <TextField
                label="Номер доверенности"
                placeholder="Введите номер доверенности"
                name="powerOfAttorniesNumber"
                onChange={handleChange}
                value={dataParams.powerOfAttorniesNumber}
                error={!!errors.powerOfAttorniesNumber}
                helperText={errors.powerOfAttorniesNumber}
                readOnly={readonly}
              />
              <DateTimePicker
                label="Дата доверенности"
                name="powerOfAttorniesDate"
                onChange={handleChange}
                value={dataParams.powerOfAttorniesDate}
                error={!!errors.powerOfAttorniesDate}
                helperText={errors.powerOfAttorniesDate}
                hideTime={true}
                editable={!readonly}
              />
            </Grid>
          )}

          {dataParams.type && dataParams.type.value === 3 && (
            <Grid columns={2} style={{ marginTop: 16 }}>
              <Grid columns={1}>
                <DateTimePicker
                  label="Дата"
                  name="registrationCertificateDate"
                  onChange={handleChange}
                  value={dataParams.registrationCertificateDate}
                  error={!!errors.registrationCertificateDate}
                  helperText={errors.registrationCertificateDate}
                  hideTime={true}
                  editable={!readonly}
                />
              </Grid>
              <Grid columns={2}>
                {showSeriesField && (
                  <TextField
                    label="Серия"
                    placeholder="Введите серию"
                    name="registrationCertificateSeries"
                    onChange={handleChange}
                    value={dataParams.registrationCertificateSeries}
                    error={!!errors.registrationCertificateSeries}
                    helperText={errors.registrationCertificateSeries}
                    readOnly={readonly}
                  />
                )}
                <TextField
                  label="Номер"
                  placeholder="Введите номер"
                  name="registrationCertificateNumber"
                  onChange={handleChange}
                  value={dataParams.registrationCertificateNumber}
                  error={!!errors.registrationCertificateNumber}
                  helperText={errors.registrationCertificateNumber}
                  readOnly={readonly}
                />
              </Grid>
            </Grid>
          )}
        </StyledAddressContent>
      </Header>
    </div>
  );
}

export default memo(Step3);
