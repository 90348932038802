import { Dispatch, SetStateAction } from "react";
import * as yup from "yup";
import { SelectOption } from "@app/components";
import {
  ActOfWork,
  CompanyShort,
  Dictionary,
  Project,
  SmartContract,
} from "@app/models";
import { getCompanyObject, setSelectOption } from "@app/helpers";

export interface Step2Props {
  setActOfWork: Dispatch<SetStateAction<ActOfWork | null>>;
  onChange: Dispatch<SetStateAction<number>>;
  basis: Dictionary | null;
  actOfWork: ActOfWork | null;
  disabled: boolean;
  isActOfService: boolean;
}

export interface AvrCreateForm {
  senderCompany: SelectOption<CompanyShort> | null;
  recipientCompany: SelectOption<CompanyShort> | null;
  startDate: Date | null;
  endDate: Date | null;
  date: Date | null;
  project: SelectOption<Project> | null;
  contract: SelectOption<SmartContract> | null;
  nds: SelectOption<Dictionary> | null;
  registrationNumber: string;
  invoiceNumber: string;
  recipientCompanyAddress: string;
  senderCompanyAddress: string;
}

export interface NomenclatureData {
  name: string;
  executionDate: Date | null;
  unit: SelectOption<Dictionary> | null;
  quantity: string;
  price: string;
  sum: string;
  sumNds: string;
}

export const avrCreateSchema = yup.object().shape({
  senderCompany: yup.object().nullable().required("Выберите заказчика"),
  recipientCompany: yup.object().nullable().required("Выберите исполнителя"),
  startDate: yup.date().nullable().required("Укажите дату начала"),
  endDate: yup.date().nullable().required("Укажите дату окончания"),
  date: yup.date().nullable().required("Укажите дату выставления АВР"),
  project: yup.object().nullable().required("Выберите проект"),
  contract: yup.object().nullable().required("Выберите договор"),
  nds: yup.object().nullable().required("Выберите НДС"),
  registrationNumber: yup.string().required("Заполните номер АВР"),
  recipientCompanyAddress: yup.string().required("Укажите адрес"),
  senderCompanyAddress: yup.string().required("Укажите адрес"),
  invoiceNumber: yup.string().when("recipientCompany", {
    is: (value: SelectOption<ActOfWork["recipientCompany"]> | null) =>
      value?.item?.generateInvoiceInAvr,
    then: yup.string().nullable().required("Обязательное поле"),
  }),
});

export const getAvrFormValues = (actOfWork: ActOfWork | null) => {
  return {
    senderCompany: setSelectOption(getCompanyObject(actOfWork?.senderCompany)),
    recipientCompany: setSelectOption(
      getCompanyObject(actOfWork?.recipientCompany)
    ),
    startDate: actOfWork ? new Date(actOfWork.startDate) : null,
    endDate: actOfWork ? new Date(actOfWork.endDate) : null,
    date: actOfWork?.date ? new Date(actOfWork.date) : null,
    project: setSelectOption(actOfWork?.project ?? null),
    contract: setSelectOption(actOfWork?.contract ?? null),
    nds: setSelectOption({
      id: actOfWork?.ndsRate ?? "—",
      name: actOfWork?.ndsRate ?? "—",
      item: null,
    }),
    registrationNumber: actOfWork?.registrationNumber ?? "",
    invoiceNumber: actOfWork?.invoiceNumber ?? "",
    recipientCompanyAddress: actOfWork?.recipientCompanyAddress ?? "",
    senderCompanyAddress: actOfWork?.senderCompanyAddress ?? "",
  };
};
