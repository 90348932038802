import React, { useCallback, useMemo } from "react";
import { Button, Modal } from "@app/components";
import { useNavigate } from "react-router-dom";
import { IconBlock100 } from "@app/icons";
import styled from "styled-components";

type Props = {
  show: boolean;
  title: string;
  message: string;
  actionTitle?: string;
  redirectPath: string;
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  svg {
    min-width: 100px;
  }
`;

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  p {
    font-size: 14px;
    line-height: 20px;
  }
`;

const BlockingModal = (props: Props) => {
  const navigate = useNavigate();
  const {
    show,
    title,
    message,
    actionTitle = "Подписать",
    redirectPath,
  } = props;

  const onBackClick = () => {
    window.history.back();
  };

  const onRedirect = useCallback(() => {
    navigate(redirectPath);
  }, [navigate, redirectPath]);

  const actions = useMemo(
    () => [
      <Button text="Назад" variant="outlined" onClick={onBackClick} />,
      <Button text={actionTitle} onClick={onRedirect} />,
    ],
    [actionTitle, onRedirect]
  );

  return (
    <Modal
      open={show}
      title={""}
      hideHeader
      size={"xsmall"}
      position={"center"}
      actions={actions}
      onClose={() => {}}
    >
      <StyledContent>
        <IconBlock100 />
        <StyledInfo>
          <h3>{title}</h3>
          <p>{message}</p>
        </StyledInfo>
      </StyledContent>
    </Modal>
  );
};

export default BlockingModal;
