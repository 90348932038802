import { ActVehicleItemV2, VehicleJournalForActV2 } from "@app/models";
import { excludeVAT } from "./getNDS";
import { currencyFormat } from "./currencyFormat";
import { roundToTwo } from "./priceFormat";

const getVehicleItemsSum = (
  vehicleItems: ActVehicleItemV2[] | null,
  key: keyof ActVehicleItemV2,
  ndsRate: number | undefined
) => {
  if (!vehicleItems) return 0;

  return vehicleItems.reduce((sum, item) => {
    if (key === "summaNds") {
      return sum + excludeVAT(item.summa, ndsRate);
    }

    return sum + ((item[key] || 0) as number);
  }, 0);
};

export const getVehiclesSum = (
  actVehicles: VehicleJournalForActV2[],
  key: keyof ActVehicleItemV2,
  ndsRate: number | undefined
) => {
  const summa = actVehicles.reduce((acc, cur) => {
    return acc + getVehicleItemsSum(cur.items, key, ndsRate);
  }, 0);

  return currencyFormat(roundToTwo(summa));
};
