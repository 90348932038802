import React, { FC, useCallback, useState } from "react";
import { Button, DocumentFile } from "@app/components";
import { FileObject } from "@app/models";
import { getFileById } from "@app/api";
import { IconFileFilled24 } from "@app/icons";
import { useNotification } from "@app/providers";
import { downloadFile } from "../../helpers/downloadFile";
import { PopupMenu } from "@app/common";
import styled from "styled-components";

type Props = {
  files: FileObject[] | null;
  documentDefaultName?: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 16px 24px;

  button:last-child {
    margin-top: 16px;
  }
`;

const downloadDocument = async (fileId: string, fileName?: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await getFileById(fileId);
      const url = window.URL.createObjectURL(res);
      downloadFile(url, fileName || "Документ");
      resolve(true);
    } catch (e) {
      reject(e);
    }
  });
};

export const downloadAllFiles = async (files: FileObject[] | null) => {
  if (files?.length) {
    return new Promise(async (resolve, reject) => {
      const downloads = files.map((item) => {
        return downloadDocument(item.id, item?.name || "");
      });
      try {
        await Promise.all(downloads);
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }
};

const TableRowFile: FC<Props> = (props) => {
  const { files } = props;
  const { showNotification } = useNotification();

  const [fileLoading, setFileLoading] = useState(false);

  const downloadFiles = useCallback(async () => {
    if (files?.length) {
      try {
        setFileLoading(true);
        await downloadAllFiles(files);
        setFileLoading(false);
      } catch (e) {
        setFileLoading(false);
        showNotification({
          message: "Не удалось скачать все файлы",
          variant: "error",
        });
      }
    }
  }, [files, showNotification]);

  if (!files?.length) {
    return null;
  }

  return (
    <PopupMenu icon={IconFileFilled24} onClick={() => {}}>
      <Wrapper>
        {files?.map((item, index) => (
          <DocumentFile
            key={index}
            id={item.id}
            name={item.name || ""}
            previewEnabled
          />
        ))}
        <Button
          variant={"contained"}
          text={"Скачать все файлы"}
          size={"small"}
          showLoader={fileLoading}
          onClick={downloadFiles}
        />
      </Wrapper>
    </PopupMenu>
  );
};

export default TableRowFile;
