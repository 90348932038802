import { ActOfWorkStatus, ActOfWorkStatusName } from "@app/models";
import { Chip, ChipColor } from "@app/components";
import React, { memo } from "react";

interface Props {
  status: ActOfWorkStatus;
}

const getStatusVariant = (status: ActOfWorkStatus): ChipColor => {
  switch (status) {
    case ActOfWorkStatus.Updated:
    case ActOfWorkStatus.Created:
    case ActOfWorkStatus.OnSenderSigning:
      return "info";
    case ActOfWorkStatus.OnRecipientSigning:
    case ActOfWorkStatus.OnRecipientApproval:
      return "warning";
    case ActOfWorkStatus.Signed:
      return "success";
    case ActOfWorkStatus.Rejected:
    case ActOfWorkStatus.Revoked:
      return "danger";
    default:
      return "default";
  }
};

function ActOfWorkStatusChip(props: Props) {
  const { status } = props;

  return (
    <Chip text={ActOfWorkStatusName[status]} color={getStatusVariant(status)} />
  );
}

export default memo(ActOfWorkStatusChip);
