import React, { memo } from "react";
import { Modal, ModalProps } from "@app/components";
import { ContactsInfo } from "@app/common";

interface Props extends Pick<ModalProps, "open" | "onClose"> {}

const ContactsBottomModal = (props: Props) => {
  return (
    <Modal title={""} hideHeader position={"bottom"} {...props}>
      <ContactsInfo />
    </Modal>
  );
};

export default memo(ContactsBottomModal);
