import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../styles.scss";
import { VehicleMonitoring } from "@app/models";
import { getMonitoringVehicles, MonitoringVehiclesParams } from "@app/api";
import styled, { css } from "styled-components";
import { ifProp, switchProp, theme } from "styled-tools";
import { Loader, Pagination } from "@app/components";
import { VehiclesFilterParams, VehiclesHeader } from "./components";
import MonitoringVehicle from "../Vehicle";
import { useMonitoring } from "@app/providers";
import { useLocation } from "react-router-dom";
import qs from "qs";

const StyledVehicleResults = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledLoaderContainer = styled.div<{
  absolute: boolean;
}>`
  ${switchProp("absolute", {
    false: css`
      text-align: center;
      padding: 20px;
      box-sizing: border-box;
    `,
    true: css`
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 1;
    `,
  })}
`;

const StyledVehicles = styled.div<{
  overflowHidden: boolean;
}>`
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  //overflow-y: ${ifProp("overflowHidden", "hidden", "auto")};
  position: relative;
`;

const StyledPagination = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  height: 48px;
  border-top: 1px solid ${theme("color.grayLight")};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme("color.white")};
`;

interface Props {}

const getDefaultDateMin = () => {
  const date = new Date();

  date.setHours(0, 0, 0);

  return date;
};

const getDefaultDateMax = () => {
  const date = new Date();

  date.setHours(23, 59, 59);

  return date;
};

export default function (_props: Props) {
  const { search } = useLocation();
  const { setVehicles, setGEOFence } = useMonitoring();
  const [pending, setPending] = useState<boolean>(true);
  const [pendingVehicle, setPendingVehicle] = useState<boolean>(false);
  const [gpsStatus, setGpsStatus] = useState<number | null>(null);
  const [dateMin, setDateMin] = useState<Date | null>(getDefaultDateMin);
  const [dateMax, setDateMax] = useState<Date | null>(getDefaultDateMax);
  const [govNumber, setGovNumber] = useState<string>("");
  const [statusData, setStatusData] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState<VehicleMonitoring[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [quantity, setQuantity] = useState(0);

  const items = useMemo(() => {
    if (totalItems.length === 0) {
      return [];
    }

    const startIndex = (page - 1) * 20;
    const endIndex = startIndex + 20;

    if (!!govNumber) {
      const filteredItems = totalItems.filter(
        (item) =>
          item.govNumber.toLowerCase().indexOf(govNumber.toLowerCase()) > -1
      );

      setTotalCount(filteredItems.length);

      return filteredItems
        .sort((a, b) => a.gpsDataUpdatedStatusId - b.gpsDataUpdatedStatusId)
        .slice(startIndex, endIndex);
    }

    setTotalCount(totalItems.length);

    return totalItems
      .sort((a, b) => a.gpsDataUpdatedStatusId - b.gpsDataUpdatedStatusId)
      .slice(startIndex, endIndex);
  }, [totalItems, govNumber, page]);

  const defaultFilter = useMemo<VehiclesFilterParams>(() => {
    return {
      gps: null,
      organization: null,
      project: null,
      partner: null,
      vehicleType: null,
      characteristic: null,
      isAvailable: null,
    };
  }, []);

  const filterParams = useRef<VehiclesFilterParams>(defaultFilter);

  const getData = useCallback(
    async (
      filter: VehiclesFilterParams & {
        page: number;
        gpsStatus: number | null;
        dateMax: Date;
        dateMin: Date;
      }
    ) => {
      try {
        setPage(1);
        setPending(true);

        const params: MonitoringVehiclesParams = {
          dateMax: filter.dateMax!.toJSON(),
        };

        if (!!filter.gpsStatus) {
          params.gpsDataUpdateStatusIds = filter.gpsStatus;
        }

        if (!!filter) {
          if (filter.organization) {
            params.organizationIds = [filter.organization.value];
          }

          if (filter.project) {
            params.projectIds = [filter.project.value];
          }

          if (filter.partner) {
            params.partnerIds = [filter.partner.value];
          }

          if (filter.vehicleType) {
            params.vehicleTypeIds = [filter.vehicleType.value];
          }

          if (filter.characteristic) {
            params.characteristicsIds = [filter.characteristic.value];
          }

          if (filter.isAvailable) {
            params.isAvailable = filter.isAvailable.value;
          }

          if (filter.gps) {
            params.gpsId = filter.gps.value;
          }
        }

        const response = await getMonitoringVehicles({
          pageSize: 100000,
          pageNumber: filter.page,
          ...params,
        });
        if (response.data) {
          setStatusData(response.data!.statusesQty);
          setTotalItems(
            response.data.vehicles.map((item) => ({
              ...item,
              dateMin: filter.dateMin!,
              dateMax: filter.dateMax,
            })) || []
          );
          setQuantity(response.recordsFiltered);
        } else {
          setStatusData([]);
          setTotalItems([]);
          setQuantity(0);
        }
        setPending(false);
      } catch (e) {
        setPending(false);
      }
    },
    []
  );

  const onChangeGpsStatus = useCallback(
    async (value: number | null) => {
      setGpsStatus(value);

      setVehicles([]);

      await getData({
        gpsStatus: value,
        dateMax: dateMax!,
        dateMin: dateMin!,
        page: 1,
        ...filterParams.current,
      });
    },
    [setVehicles, dateMax, dateMin, getData]
  );

  const onChangeDateMin = useCallback((date: Date | null) => {
    setDateMin(date);
  }, []);

  const onChangeDateMax = useCallback((date: Date | null) => {
    setDateMax(date);
  }, []);

  const onChangeGovNumber = useCallback(
    (text: string) => {
      setVehicles([]);
      setGovNumber(text);
    },
    [setVehicles]
  );

  const onCheckAll = useCallback(async () => {
    try {
      setPendingVehicle(true);

      setVehicles(
        totalItems.map((item) => ({
          ...item,
          dateTime: !!item.gpsDataUpdated
            ? Math.floor(new Date(item.gpsDataUpdated).getTime() / 1000)
            : 0,
          speed: 0,
          latitude: item.lastPositionLatitude || 0,
          longitude: item.lastPositionLongitude || 0,
          points: [],
        }))
      );

      setPendingVehicle(false);
    } catch (e) {
      setPendingVehicle(false);
    }
  }, [setVehicles, totalItems]);

  const onChangePage = useCallback(async (page: number) => {
    setPage(page);
  }, []);

  const onSubmitFilter = useCallback(
    async (values: VehiclesFilterParams) => {
      filterParams.current = values;

      setVehicles([]);

      await getData({
        gpsStatus,
        page: 1,
        dateMax: dateMax!,
        dateMin: dateMin!,
        ...values,
      });
    },
    [setVehicles, dateMax, dateMin, getData, gpsStatus]
  );

  useEffect(() => {
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    setGovNumber(params.govNumber?.toString() || "");

    getData({
      gpsStatus,
      dateMax: dateMax!,
      dateMin: dateMin!,
      page: 1,
      ...filterParams.current,
    });
  }, [getData, gpsStatus, dateMax, dateMin, search]);

  useEffect(() => {
    setVehicles([]);
    setGEOFence(null);
  }, [setGEOFence, setVehicles]);

  return (
    <StyledVehicleResults>
      <VehiclesHeader
        gpsStatus={gpsStatus}
        dateMin={dateMin}
        dateMax={dateMax}
        govNumber={govNumber}
        onChangeGpsStatus={onChangeGpsStatus}
        onChangeDateMin={onChangeDateMin}
        onChangeDateMax={onChangeDateMax}
        onChangeGovNumber={onChangeGovNumber}
        onCheckAll={onCheckAll}
        onSubmitFilter={onSubmitFilter}
        defaultFilter={defaultFilter}
        disabled={pending}
        statusData={statusData}
        totalQuantity={quantity}
      />
      {pending && (
        <StyledLoaderContainer absolute={false}>
          <Loader size="small" />
        </StyledLoaderContainer>
      )}
      <StyledVehicles overflowHidden={pendingVehicle}>
        {pendingVehicle && (
          <StyledLoaderContainer absolute={true}>
            <Loader size="small" />
          </StyledLoaderContainer>
        )}
        {!pending
          ? items.map((item) => (
              <MonitoringVehicle
                vehicle={item}
                setPending={setPendingVehicle}
                key={item.id}
              />
            ))
          : null}
      </StyledVehicles>
      <StyledPagination>
        <Pagination
          onChange={onChangePage}
          pageSize={20}
          initialPage={page}
          totalCount={totalCount}
          siblingCount={4}
        />
      </StyledPagination>
    </StyledVehicleResults>
  );
}
