import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Modal, ModalProps, Stepper } from "@app/components";
import { ActListItem, ActOfWork, ActOfWorkStatus } from "@app/models";
import { Step1, Step2, Step3 } from "./components";
import { getActById, getVehiclesByActIdV2 } from "@app/api";
import { BasisDictionary } from "./components/Step1/Step1";
import { NcaLayerClientProvider } from "@app/providers";
import { getActNomenclatures, getActOfWorkInit } from "@app/helpers";
import * as Styled from "./components/styled";

interface Props extends Pick<ModalProps, "open" | "onClose"> {
  actOfWork: ActOfWork | null;
  isActOfService?: boolean;
}

const initStatuses = [ActOfWorkStatus.Created, ActOfWorkStatus.Updated];

function ModalActOfWorkDetail(props: Props) {
  const {
    onClose,
    actOfWork: propsActOfWork,
    open,
    isActOfService: propsIsActOfService,
    ...restProps
  } = props;
  const [pending, setPending] = useState<boolean>(true);
  const [isActOfService, setIsActOfService] = useState<boolean>(false);
  const [act, setAct] = useState<ActListItem | null>(null);
  const [actOfWork, setActOfWork] = useState<ActOfWork | null>(null);
  const [basis, setBasis] = useState<BasisDictionary | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(1);

  const onSubmitBasis = useCallback((data: BasisDictionary | null) => {
    if (!!data) {
      setBasis(data);
    }

    setCurrentStep(2);
  }, []);

  const modalTitle = useMemo(() => {
    if (pending) {
      return "Загружается...";
    }
    if (propsActOfWork?.number) {
      return `Акт №${propsActOfWork.number}`;
    }
    return "Создание АВР";
  }, [pending, propsActOfWork?.number]);

  const steps = useMemo(
    () => ["Выбор основания", "Формирование", "Подписание"],
    []
  );

  const onChangeAct = useCallback(
    async (actData: ActListItem | null) => {
      try {
        if (!actData) {
          setIsActOfService(false);

          setAct(null);
          setActOfWork(null);

          return;
        }

        const { data: vehiclesByAct } = await getVehiclesByActIdV2(
          actData.id,
          true
        );

        setIsActOfService(true);

        const actOfWorkInit = getActOfWorkInit(actData);

        setActOfWork({
          ...actOfWorkInit,
          basis,
          nomenclatures: getActNomenclatures(
            vehiclesByAct,
            actData.endWorkDate
          ),
        });

        setAct(actData);
      } catch (e) {
        console.log(e);
      }
    },
    [basis]
  );

  const onCloseModal = useCallback(() => {
    onClose();
    setCurrentStep(1);
    setBasis(null);
    setActOfWork(null);
    setAct(null);
    setPending(true);
  }, [onClose]);

  const getData = useCallback(async () => {
    try {
      const { data: actData } = await getActById(propsActOfWork!.actId);

      setAct(actData);
      if (propsActOfWork && actData.actDataForAvrDto?.length) {
        setActOfWork({
          ...propsActOfWork,
          nomenclatures: actData.actDataForAvrDto.map((item) => ({
            ...item,
            executionDate: actData.endWorkDate,
            avrId: propsActOfWork.id ?? "",
            name: item.nomenclature ?? "—",
            unit: item.unitDto,
          })),
        });
      }
      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [propsActOfWork]);

  const disabled = useMemo(() => {
    if (isActOfService || !actOfWork?.statusId) {
      return false;
    }

    return !initStatuses.includes(actOfWork.statusId);
  }, [isActOfService, actOfWork]);

  useEffect(() => {
    if (!!propsActOfWork) {
      setActOfWork(propsActOfWork);
      setCurrentStep(
        !!propsActOfWork.statusId &&
          !initStatuses.includes(propsActOfWork.statusId)
          ? 3
          : 1
      );

      if (!!propsActOfWork!.basis) {
        setBasis({
          id: propsActOfWork!.basis.id,
          name: propsActOfWork!.basis.name,
          isActBasis: (propsActOfWork!.basis as BasisDictionary).isActBasis,
        });
      }

      if (!!propsActOfWork.actId) {
        setIsActOfService(!!propsIsActOfService);

        getData();

        return;
      }
    }

    setPending(false);
  }, [getData, propsActOfWork, propsIsActOfService]);

  if (!open) {
    return null;
  }

  return (
    <Modal
      {...restProps}
      open={open}
      title={modalTitle}
      size={currentStep === 2 ? "xlarge" : "medium"}
      dense
      onClose={onCloseModal}
    >
      <Styled.Content>
        <Styled.Stepper>
          <Stepper steps={steps} currentStep={currentStep} />
        </Styled.Stepper>
        <Styled.Detail>
          {currentStep === 1 && (
            <Step1
              onSubmit={onSubmitBasis}
              basis={basis}
              disabled={!!propsIsActOfService || disabled}
              act={act}
              setAct={onChangeAct}
              actOfWorkStatus={actOfWork?.statusId || null}
            />
          )}
          {currentStep === 2 && (
            <Step2
              onChange={setCurrentStep}
              basis={basis}
              actOfWork={actOfWork}
              setActOfWork={setActOfWork}
              isActOfService={isActOfService}
              disabled={disabled}
            />
          )}
          {currentStep === 3 && actOfWork && (
            <NcaLayerClientProvider>
              <Step3
                act={act}
                actOfWork={actOfWork}
                onChange={setCurrentStep}
                setActOfWork={setActOfWork}
                disabled={disabled}
              />
            </NcaLayerClientProvider>
          )}
        </Styled.Detail>
      </Styled.Content>
    </Modal>
  );
}

export default memo(ModalActOfWorkDetail);
