import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import {
  Button,
  Dialog,
  Modal,
  ModalProps,
  Stepper,
  useForm,
} from "@app/components";
import { StepView1, StepView2, StepView3 } from "./components";
import { useNotification } from "@app/providers";
import * as yup from "yup";
import {
  addVehicleFile,
  APIResponse,
  createVehicle,
  deleteVehicleFile,
  getVehicleById,
  getVehicleFileList,
  getVehicleFileTypeList,
  sendToModerate,
  updateVehicle,
  updateVehicleStatus,
} from "@app/api";
import { FileType } from "./components/Step2";
import { IconTrash24 } from "@app/icons";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import { CompanyVehicle, VehicleListItem } from "@app/models";

interface ModalCreateVehicleProps extends Omit<ModalProps, "title"> {
  data: VehicleListItem | null;
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 24px 16px;
  padding: 16px 0;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
`;

const StyledStepper = styled.div`
  flex-grow: 1;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
`;

const StyledButtonBack = styled.div`
  display: flex;
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #d8d8d8;
  justify-content: space-between;
  padding: 16px 16px 48px 16px;
  box-sizing: border-box;
`;

const schema = yup.object().shape({
  organization: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required("ID организации обязательно"),
      })
    )
    .min(1, "Необходимо выбрать хотя бы одну организацию")
    .required("Поле 'Организация' обязательно к заполнению"),
  vehicleType: yup
    .object()
    .nullable()
    .required("Поле обязательно к заполнению"),
  vehicleModel: yup
    .object()
    .nullable()
    .required("Поле обязательно к заполнению"),
  characteristic: yup
    .object()
    .nullable()
    .required("Поле обязательно к заполнению"),
  gps: yup.object().nullable().required("Поле обязательно к заполнению"),
  govNumber: yup.string().nullable().required("Поле обязательно к заполнению"),
  yearOfIssue: yup.date().nullable().required(),
});

function ModalCreateVehicle(props: ModalCreateVehicleProps) {
  const { onClose, open, data, ...restProps } = props;
  const { showNotification } = useNotification();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [companyFileTypes, setCompanyFileTypes] = useState<FileType[]>([]);
  const [fileTypes, setFileTypes] = useState<FileType[]>([]);
  const [files, setFiles] = useState<{ [key: string]: string[] }>({});
  const [biotFiles, setBiotFiles] = useState<{ [key: string]: string[] }>({});
  // const [validationSchema, setValidationSchema] = useState<any>();
  const [vehicleId, setVehicleId] = useState<string>("");
  const [filesToDelete, setFilesToDelete] = useState<any>([]);
  const [biotFilesToDelete, setBiotFilesToDelete] = useState<any>([]);
  const [initialOrganizationIds, setInitialOrganizationIds] = useState<
    string[]
  >([]);
  const [companies, setCompanies] = useState<CompanyVehicle[]>([]);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showBiotDialog, setShowBiotDialog] = useState<boolean>(false);
  const [isStep2Saved, setIsStep2Saved] = useState(false);
  const [isStep3Saved, setIsStep3Saved] = useState(false);
  const [isConfirmingStatus, setIsConfirmingStatus] = useState(false);
  const [isOwnedByOrganization, setIsOwnedByOrganization] = useState<{
    [key: string]: boolean;
  }>({});
  const [uploadedFiles, setUploadedFiles] = useState<Set<string>>(new Set());
  const [addedOrganizations, setAddedOrganizations] = useState<Set<string>>(
    new Set()
  );

  const {
    pending,
    onChange,
    values: formData,
    errors,
    validate,
    setPending,
    resetForm,
    setValues,
  } = useForm<any>({
    values: {
      organization: [],
      vehicleType: null,
      vehicleModel: null,
      characteristic: null,
      gps: null,
      govNumber: "",
      yearOfIssue: null,
      files: [],
    },
    schema: schema,
  });

  useEffect(() => {
    if (open) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [open]);

  const steps = useMemo(
    () => ["Общая информация", "Документы техники", "Документы БиОТ"],
    []
  );

  const modalTitle = useMemo<ReactNode>(() => {
    if (!data) {
      return "Добавление техники";
    } else {
      return "Редактирование техники";
    }
  }, [data]);

  const handleRemoveOrganization = async (orgId: string) => {
    if (vehicleId) {
      try {
        await updateVehicleStatus({
          statusId: 50,
          companyId: orgId,
          vehicleId,
        });
      } catch (error) {
        console.error("Ошибка при обновлении статуса: ", error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (data && data.id) {
        try {
          const response = await getVehicleById(data?.id);
          if (response && response.succeeded) {
            const vehicleData = response.data;
            setVehicleId(vehicleData?.id);
            setCompanies(vehicleData?.companies);
            const organizationIds = vehicleData.companies.map(
              (company: CompanyVehicle) => company.companyId
            );
            setInitialOrganizationIds(organizationIds);

            const updatedFormData: any = {
              organization: vehicleData.companies.map(
                (companyInfo: CompanyVehicle) => ({
                  id: companyInfo.companyId,
                  item: companyInfo,
                  name: companyInfo.company?.name,
                })
              ),
              characteristic: vehicleData.characteristic
                ? {
                    value: vehicleData.characteristic?.id,
                    label: vehicleData.characteristic?.name,
                  }
                : null,
              gps: vehicleData.gps
                ? {
                    value: vehicleData.gps.id,
                    label: vehicleData.gps.name,
                  }
                : null,
              govNumber: vehicleData.govNumber || "",
              yearOfIssue: new Date(vehicleData.yearOfIssue) || null,
              vehicleType: vehicleData.type
                ? { value: vehicleData.type?.id, label: vehicleData.type?.name }
                : null,
              vehicleModel: vehicleData.model
                ? {
                    value: vehicleData.model?.id,
                    label: vehicleData.model?.name,
                  }
                : null,
            };
            const includeTypeIds = [
              "6e9e2c0f-3986-4ae0-862d-2f0abbe6b9cb",
              "4abf83cf-c0d8-4240-b5b6-a3072706a480",
              "82977601-caa7-4687-b6f8-6df8fb185529",
            ];

            const filesToSet: any = {};
            const biotFilesToSet: any = {};

            vehicleData.files.forEach((fileData: any) => {
              if (fileData.isDeleted || fileData.file?.isDeleted) return;
              const fileKey = fileData.companyId
                ? `${fileData.typeId}-${fileData.companyId}`
                : fileData.typeId;

              if (!filesToSet[fileKey]) {
                filesToSet[fileKey] = [];
              }
              if (!biotFilesToSet[fileData.typeId]) {
                biotFilesToSet[fileData.typeId] = [];
              }

              const fileInfo = {
                name: fileData.file?.name,
                url: fileData.file?.path,
                id: fileData.file?.id,
                item: fileData,
                expirationDate: fileData.expirationDate,
              };

              if (includeTypeIds.includes(fileData?.type?.id)) {
                filesToSet[fileKey].push(fileInfo);
              } else {
                biotFilesToSet[fileData.typeId].push(fileInfo);
              }

              if (fileData.expirationDate) {
                updatedFormData[fileKey] = fileData.expirationDate;
              }
            });

            setFiles(filesToSet);
            setBiotFiles(biotFilesToSet);

            setValues(updatedFormData);
          }
        } catch (error) {
          console.error("Ошибка при загрузке данных компании: ", error);
        }
      }
    };

    fetchData();
  }, [data, setValues]);

  const loadCompanyFileTypes = useCallback(async () => {
    try {
      const response = await getVehicleFileList();
      let initialData = response.data
        .filter(
          (companyFileType) =>
            companyFileType.name === "Договор аренды" ||
            companyFileType.name === "Тех.паспорт (сторона 1)" ||
            companyFileType.name === "Тех.паспорт (сторона 2)"
        )
        .map((companyFileType) => ({
          id: companyFileType.id,
          name: companyFileType.name,
          isExpirationDateRequired: companyFileType.isExpirationDateRequired,
          isDeleted: companyFileType.isDeleted,
        }));

      setCompanyFileTypes(initialData);
    } catch (error) {
      console.error("Ошибка при загрузке списка: ", error);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await loadCompanyFileTypes();
    })();
  }, [loadCompanyFileTypes]);

  const loadFileTypes = useCallback(async () => {
    try {
      if (formData.vehicleType && formData.vehicleType.value) {
        const response = await getVehicleFileTypeList(
          formData.vehicleType && formData.vehicleType.value
        );
        let initialData = response.data.map((fileType) => ({
          id: fileType.id,
          name: fileType.name,
          isExpirationDateRequired: fileType.isExpirationDateRequired,
          isDeleted: fileType.isDeleted,
        }));

        setFileTypes(initialData);
      }
    } catch (error) {
      console.error("Ошибка при загрузке списка:  ", error);
    }
  }, [formData.vehicleType]);

  useEffect(() => {
    (async () => {
      await loadFileTypes();
    })();
  }, [loadFileTypes]);

  const onFileDelete = useCallback(
    (fileTypeId: string, fileIndex: number) => {
      setFiles((prevFiles) => {
        const updatedFiles = { ...prevFiles };
        const filesList = updatedFiles[fileTypeId];

        if (filesList && filesList.length > fileIndex) {
          const fileToDelete = filesList[fileIndex];
          setFilesToDelete({ file: fileToDelete, fileTypeId, fileIndex });
          setShowDialog(true);
        }

        return updatedFiles;
      });
      const input = document.getElementById(
        `file-input-${fileTypeId}`
      ) as HTMLInputElement;
      if (input) {
        input.value = "";
      }
    },
    [setFiles]
  );

  const onBiotFileDelete = useCallback(
    (fileTypeId: string, fileIndex: number) => {
      setBiotFiles((prevBiotFiles) => {
        const updatedBiotFiles = { ...prevBiotFiles };
        const filesList = updatedBiotFiles[fileTypeId];

        if (filesList && filesList.length > fileIndex) {
          const fileToDelete = filesList[fileIndex];
          setBiotFilesToDelete({ file: fileToDelete, fileTypeId, fileIndex });
          setShowBiotDialog(true);
        }

        return updatedBiotFiles;
      });
      const input = document.getElementById(
        `file-input-${fileTypeId}`
      ) as HTMLInputElement;
      if (input) {
        input.value = "";
      }
    },
    [setBiotFiles, setShowBiotDialog]
  );

  const onDeleteConfirm = async () => {
    if (filesToDelete.file.id) {
      try {
        await deleteVehicleFile({
          vehicleId: vehicleId,
          fileId: filesToDelete.file.id,
        });
      } catch (error) {
        console.error("Error deleting vehicle file: ", error);
      }
    }
    setFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      const fileType = filesToDelete.fileTypeId;
      if (
        updatedFiles[fileType] &&
        updatedFiles[fileType].length > filesToDelete.fileIndex
      ) {
        updatedFiles[fileType].splice(filesToDelete.fileIndex, 1);
        if (updatedFiles[fileType].length === 0) {
          delete updatedFiles[fileType];
        }
      }

      return updatedFiles;
    });

    setShowDialog(false);
    setFilesToDelete([]);
  };

  const onBiotFileDeleteConfirm = async () => {
    if (biotFilesToDelete.file.id) {
      try {
        await deleteVehicleFile({
          vehicleId: vehicleId,
          fileId: biotFilesToDelete.file.id,
        });
      } catch (error) {
        console.error("Ошибка при удалении файла БиОТ: ", error);
      }
    }

    const updatedBiotFiles = { ...biotFiles };
    const fileType = biotFilesToDelete.fileTypeId;
    updatedBiotFiles[fileType] = updatedBiotFiles[fileType].filter(
      (file, index) => index !== biotFilesToDelete.fileIndex
    );

    if (updatedBiotFiles[fileType].length === 0) {
      delete updatedBiotFiles[fileType];
    }

    setBiotFiles(updatedBiotFiles);
    setShowBiotDialog(false);
    setBiotFilesToDelete([]);
  };

  const onSaveClick = useCallback(
    async (isSaving = true) => {
      const isValid = await validate();

      if (!isValid) {
        return;
      }
      setPending(true);
      const currentOrganizationIds = formData.organization.map(
        (org: { id: string; item: CompanyVehicle; name: string }) => org.id
      );

      const body = {
        companyIds: currentOrganizationIds,
        govNumber: formData.govNumber,
        vehicleTypeId: formData.vehicleType?.value,
        characteristicsId: formData.characteristic?.value,
        yearOfIssue: formData.yearOfIssue!.toJSON(),
        vehicleModelId: formData.vehicleModel?.value,
        gpsId: formData.gps?.value,
        imeiGps: null,
        idInGps: null,
      };

      try {
        let response;
        if (!vehicleId) {
          response = await createVehicle(body);
          if (response?.succeeded) {
            const newVehicleId = response.data.id;
            setVehicleId(newVehicleId);
            setCompanies(response.data.companies);
            setInitialOrganizationIds(currentOrganizationIds);
            setAddedOrganizations(new Set(currentOrganizationIds));
          }
        } else {
          response = await updateVehicle({ ...body, id: vehicleId });

          if (response?.succeeded) {
            const newOrganizations = currentOrganizationIds.filter(
              (id: any) => !initialOrganizationIds.includes(id)
            );

            for (const orgId of newOrganizations) {
              await sendToModerate({ companyId: orgId, vehicleId });
            }

            setInitialOrganizationIds(currentOrganizationIds);
            setAddedOrganizations(
              new Set([...addedOrganizations, ...newOrganizations])
            );
          }
        }

        if (response?.succeeded) {
          setIsSuccessful(true);
          showNotification({
            message: "Общая информация техники сохранена",
            variant: "success",
          });
        }
        setPending(false);
      } catch (error) {
        console.error(
          "Ошибка при создании/обновлении транспортного средства: ",
          error
        );
        showNotification({
          message: getAxiosErrorMessage(error as AxiosError<APIResponse>),
          variant: "error",
        });
        setIsSuccessful(false);
        setPending(false);
      }
    },
    [
      validate,
      setPending,
      vehicleId,
      showNotification,
      initialOrganizationIds,
      formData,
      addedOrganizations,
    ]
  );

  const generateFileId = (file: any, step: string) =>
    `${step}-${file.name}-${file.size}-${new Date().getTime()}`;

  const onSaveStep2 = useCallback(async () => {
    setPending(true);
    const leaseAgreementTypeId = "6e9e2c0f-3986-4ae0-862d-2f0abbe6b9cb";
    let updatedFiles = { ...files };
    const updatedUploadedFiles = new Set(uploadedFiles);

    const uploadTasks: any = [];

    formData.organization.forEach((org: any) => {
      const fileKey = `${leaseAgreementTypeId}-${org.id}`;
      const filesForOrg = updatedFiles[fileKey] || [];

      filesForOrg.forEach((file, index) => {
        const fileId = generateFileId(file, "step2");

        if (!updatedUploadedFiles.has(fileId)) {
          const task = addVehicleFile({
            VehicleId: vehicleId,
            File: file,
            TypeId: leaseAgreementTypeId,
            CompanyId: org.id,
            ExpirationDate: formData[`${fileKey}`],
          }).then((response) => {
            if (response?.succeeded) {
              updatedUploadedFiles.add(fileId);
              updatedFiles[fileKey][index] = {
                // @ts-ignore
                ...file,
                id: response.data.file.id,
                name: response.data.file.name,
                url: response.data.file.path,
                typeId: response.data.typeId,
              };
            }
          });

          uploadTasks.push(task);
        }
      });
    });

    Object.keys(updatedFiles).forEach((typeId) => {
      if (typeId.startsWith(leaseAgreementTypeId)) return;

      updatedFiles[typeId].forEach((file, index) => {
        const fileId = generateFileId(file, "step2");

        if (!updatedUploadedFiles.has(fileId)) {
          const task = addVehicleFile({
            VehicleId: vehicleId,
            File: file,
            TypeId: typeId,
          }).then((response) => {
            if (response?.succeeded) {
              updatedUploadedFiles.add(fileId);
              updatedFiles[typeId][index] = {
                // @ts-ignore
                ...file,
                id: response.data.file.id,
                name: response.data.file.name,
                url: response.data.file.path,
                typeId: response.data.typeId,
              };
            }
          });

          uploadTasks.push(task);
        }
      });
    });

    await Promise.all(uploadTasks);

    setFiles(updatedFiles);
    setUploadedFiles(updatedUploadedFiles);

    setIsStep2Saved(true);
    showNotification({
      message: "Документы техники и договоры аренды успешно сохранены",
      variant: "success",
    });

    setPending(false);
  }, [setPending, files, showNotification, vehicleId, formData, uploadedFiles]);

  const onSaveStep3 = async () => {
    setPending(true);
    try {
      for (const type of fileTypes) {
        const typeFiles = biotFiles[type.id] || [];
        const updatedTypeFiles = [...typeFiles];

        for (let fileIndex = 0; fileIndex < typeFiles.length; fileIndex++) {
          const file = typeFiles[fileIndex];
          const fileId = generateFileId(file, "step3");
          if (!uploadedFiles.has(fileId)) {
            const response = await addVehicleFile({
              VehicleId: vehicleId,
              File: file,
              TypeId: type.id,
              ExpirationDate: formData[`${type.id}`],
            });

            if (response?.succeeded) {
              setUploadedFiles((prev) => new Set(prev).add(fileId));

              const newFile = {
                // @ts-ignore
                ...file,
                id: response.data.file.id,
                name: response.data.file.name,
                path: response.data.file.path,
                typeId: response.data.typeId,
              };

              updatedTypeFiles[fileIndex] = newFile;
            }
          }
        }
        setBiotFiles((prevBiotFiles) => ({
          ...prevBiotFiles,
          [type.id]: updatedTypeFiles,
        }));
      }

      setIsStep3Saved(true);
      showNotification({
        message: "Документы БиОТ успешно сохранены",
        variant: "success",
      });
    } catch (error) {
      console.error("Error saving БиОТ documents: ", error);
      showNotification({
        message: "Ошибка при сохранении",
        variant: "error",
      });
      setIsStep3Saved(false);
    } finally {
      setPending(false);
    }
  };

  const areFilesUploaded = () => {
    const LEASE_AGREEMENT_TYPE_ID = "6e9e2c0f-3986-4ae0-862d-2f0abbe6b9cb";

    const allRequiredFilesUploaded = companyFileTypes.every((fileType) => {
      if (fileType.id === LEASE_AGREEMENT_TYPE_ID) {
        return true;
      }
      return files[fileType.id] && files[fileType.id].length > 0;
    });

    const allLeaseAgreementsUploadedOrOwned = formData.organization.every(
      (org: any) => {
        const isOwned = isOwnedByOrganization[org.id];
        if (isOwned) {
          return true;
        }
        const fileKey = `${LEASE_AGREEMENT_TYPE_ID}-${org.id}`;
        return files[fileKey] && files[fileKey].length > 0;
      }
    );

    return allRequiredFilesUploaded && allLeaseAgreementsUploadedOrOwned;
  };

  const onModalClose = useCallback(() => {
    setValues({});
    resetForm();
    setFiles({});
    setVehicleId("");
    setFilesToDelete([]);
    setBiotFilesToDelete([]);
    setCompanies([]);
    setCurrentStep(1);
    setIsSuccessful(false);
    window.location.reload();
    onClose();
  }, [onClose]);

  const onConfirmStatus = useCallback(async () => {
    setIsConfirmingStatus(true);
    try {
      const companiesToUpdate = Array.from(addedOrganizations);
      if (vehicleId && companiesToUpdate.length > 0) {
        for (const companyId of companiesToUpdate) {
          await updateVehicleStatus({
            statusId: 20,
            companyId: companyId,
            vehicleId,
          });
        }
      }
      setIsConfirmingStatus(false);

      onModalClose();
    } catch (error) {
      console.error("Ошибка при подтверждении статуса компании: ", error);
      setIsConfirmingStatus(false);
      showNotification({
        message: "Ошибка при подтверждении статуса компании",
        variant: "error",
      });
    }
  }, [addedOrganizations, vehicleId, onModalClose, showNotification]);

  const onPrevClick = useCallback(() => {
    if (currentStep <= 1) {
      return;
    }
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const onNextClick = useCallback(async () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else if (currentStep === 3) {
      await onSaveClick(false);
    }
  }, [currentStep, onSaveClick]);

  const onNextStep = useCallback(async () => {
    if (currentStep === 2) {
      setCurrentStep(3);
    }
  }, [currentStep]);

  if (!open) {
    return null;
  }

  return (
    <Modal
      title={modalTitle}
      onClose={onModalClose}
      open={open}
      dense
      {...restProps}
    >
      <ModalContainer>
        <StyledHeader>
          <StyledStepper>
            <Stepper steps={steps} disabled={false} currentStep={currentStep} />
          </StyledStepper>
        </StyledHeader>
        <StyledContent>
          <Dialog
            open={showDialog}
            title={`Вы уверены, что хотите удалить этот файл?`}
            icon={IconTrash24}
            onConfirm={onDeleteConfirm}
            onClose={() => {
              setShowDialog(false);
            }}
          />
          <Dialog
            open={showBiotDialog}
            title={`Вы уверены, что хотите удалить этот файл?`}
            icon={IconTrash24}
            onConfirm={onBiotFileDeleteConfirm}
            onClose={() => {
              setShowBiotDialog(false);
            }}
          />
          {currentStep === 1 && (
            <StepView1
              dataOfOrganization={formData}
              dataParams={formData}
              dataErrors={errors}
              handleRemoveOrganization={handleRemoveOrganization}
              companies={companies}
              onDataChange={onChange}
            />
          )}
          {currentStep === 2 && (
            <StepView2
              filterParams={formData}
              files={files}
              updateData={data}
              setFiles={setFiles}
              onDataChange={onChange}
              companyFileTypes={companyFileTypes}
              onFileDelete={onFileDelete}
              isOwnedByOrganization={isOwnedByOrganization}
              setIsOwnedByOrganization={setIsOwnedByOrganization}
            />
          )}
          {currentStep === 3 && (
            <StepView3
              filterParams={formData}
              files={biotFiles}
              updateData={data}
              onDataChange={onChange}
              setFiles={setBiotFiles}
              fileTypes={fileTypes}
              onFileDelete={onBiotFileDelete}
            />
          )}
        </StyledContent>

        <StyledFooter>
          <StyledButtonBack>
            <Button
              text="Назад"
              variant="text"
              disabled={currentStep <= 1}
              showLoader={false}
              onClick={onPrevClick}
            />
          </StyledButtonBack>
          <ButtonRow>
            {currentStep === 1 && (
              <Button
                text="Сохранить"
                variant="outlined"
                showLoader={pending}
                onClick={onSaveClick}
              />
            )}
            {currentStep === 2 && (
              <Button
                text="Сохранить"
                variant="outlined"
                showLoader={currentStep === 2 && pending}
                onClick={onSaveStep2}
              />
            )}
            {currentStep === 3 && (
              <Button
                text="Сохранить"
                variant="outlined"
                showLoader={currentStep === 3 && pending}
                onClick={onSaveStep3}
              />
            )}
            {currentStep === 1 && (
              <Button
                text={"Далее"}
                onClick={onNextClick}
                disabled={data ? false : !isSuccessful}
              />
            )}
            {currentStep === 2 && (
              <Button
                text="Продолжить"
                onClick={onNextStep}
                disabled={!areFilesUploaded() || !isStep2Saved}
              />
            )}
            {currentStep === 3 && (
              <Button
                text={"Отправить на модерацию"}
                onClick={onConfirmStatus}
                showLoader={isConfirmingStatus}
                // disabled={data ? false : !isStep3Saved}
              />
            )}
          </ButtonRow>
        </StyledFooter>
      </ModalContainer>
    </Modal>
  );
}

export default memo(ModalCreateVehicle);
