import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Dialog,
  Modal,
  ModalProps,
  SelectOption,
  Tabs,
  useForm,
} from "@app/components";
import styled from "styled-components";
import { Step1, Step2, Step3, Step4, Step5 } from "./components";
import { useNotification, useUser } from "@app/providers";
import * as yup from "yup";
import {
  APIResponse,
  confirmCompanyStatus,
  deleteFile,
  getCompanyById,
  getCompanyFileTypes,
  joinAnCompany,
  onAddBankAccount,
  onAddCompanyFile,
  onUpdateAddress,
  onUpdateBankAccount,
  onUpdateCompany,
} from "@app/api";
import { format } from "date-fns";
import CompanyStatusChip from "../../common/CompanyStatusChip/CompanyStatusChip";
import UpdateDialog from "components/UpdateDialog";
import { IconTrash24 } from "@app/icons";
import {
  formatPhoneNumber,
  getAxiosErrorMessage,
  stripPhoneNumberFormatting,
} from "@app/helpers";
import { AxiosError } from "axios";
import {
  CompanyBankAccountData,
  CompanyFile,
  CompanyFileTypes,
} from "@app/models";

interface Props extends Omit<ModalProps, "title"> {
  data: any | null;
  readonly?: boolean;
  onClose: () => void;
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 24px 16px;
  padding: 16px 0;
  box-sizing: border-box;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #d8d8d8;
  justify-content: flex-end;
  padding: 16px 16px 48px 16px;
  box-sizing: border-box;
`;

const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #1c2246;
`;

const StyledChip = styled.div`
  margin-left: 10px;
`;

function ModalOrganizationEdit(props: Props) {
  const { onClose, open, data, readonly = false, ...restProps } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFilesAdded, setIsFilesAdded] = useState(false);
  const [isSavingBankAccount, setIsSavingBankAccount] = useState(false);
  const [isConfirmingStatus, setIsConfirmingStatus] = useState(false);
  const [companyId, setCompanyId] = useState<string>("");
  const [regionIdUpdate, setRegionIdUpdate] = useState<string>("");
  const [addressIdUpdate, setAddressIdUpdate] = useState<string>("");
  const [statusId, setStatusId] = useState<number | null>(null);
  const [companyFileTypes, setCompanyFileTypes] = useState<SelectOption<any>[]>(
    []
  );
  const [files, setFiles] = useState<{ [key: string]: any[] }>({});
  const [isUpdateComplete, setIsUpdateComplete] = useState(false);
  const [isBankAccountSaved, setIsBankAccountSaved] = useState(false);
  const [validationSchema, setValidationSchema] = useState<any>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [isFilesSaved, setIsFilesSaved] = useState<boolean>(false);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const {
    pending,
    onChange,
    values: formData,
    errors,
    validate,
    setPending,
    resetForm,
    setValues,
  } = useForm<any>({
    values: {
      organization: "",
      companyType: null,
      type: null,
      name: user,
      bin: "",
      nds: null,
      powerOfAttorniesNumber: "",
      powerOfAttorniesDate: null,
      registrationCertificateNumber: "",
      registrationCertificateSeries: "",
      registrationCertificateDate: null,
      region: null,
      street: "",
      block: "",
      apartment: "",
      headName: "",
      headIdn: "",
      registrationNumber: "",
      email: "",
      phone: "",
      kbe: "",
      country: null,
    },
    schema: validationSchema,
  });
  const [filesToDelete, setFilesToDelete] = useState<any>([]);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (props.data && props.data.companyStatus === 2) {
      setShowUpdateDialog(true);
    }
  }, [props.data]);

  const onConfirmToEdit = useCallback(() => {
    setShowUpdateDialog(false);
  }, []);

  const handleFieldChange = (field: any, value: any) => {
    onChange(field, value);
    setHasChanged(true);
  };

  useEffect(() => {
    const typeValue = formData.type?.value;
    const isKZ = formData.country?.label === "Казахстан";
    setValidationSchema(
      yup.object().shape({
        organization: yup
          .string()
          .nullable()
          .required("Поле обязательно к заполнению"),
        companyType: yup
          .object()
          .nullable()
          .required("Поле обязательно к заполнению"),
        type: yup.object().nullable().required("Поле обязательно к заполнению"),
        nds: yup.object().nullable().required("Поле обязательно к заполнению"),
        bin: yup
          .string()
          .nullable()
          .matches(/^\d{12}$/, "ИИН/БИН должен содержать ровно 12 цифр")
          .required("Поле обязательно к заполнению"),
        headName: yup
          .string()
          .nullable()
          .required("Поле обязательно к заполнению"),
        headIdn: yup
          .string()
          .nullable()
          .matches(/^\d{12}$/, "ИИН/БИН должен содержать ровно 12 цифр")
          .required("Поле обязательно к заполнению"),
        registrationNumber: !isKZ
          ? yup.string().nullable().required("Поле обязательно к заполнению")
          : yup.string().nullable().notRequired(),
        registrationCertificateNumber:
          typeValue === 3
            ? yup.string().nullable().required("Поле обязательно к заполнению")
            : yup.string().nullable().notRequired(),
        registrationCertificateSeries:
          typeValue === 3
            ? yup.string().nullable().notRequired()
            : yup.string().nullable().notRequired(),
        registrationCertificateDate:
          typeValue === 3
            ? yup.date().required("Поле обязательно к заполнению")
            : yup.date().nullable().notRequired(),
        powerOfAttorniesNumber:
          typeValue === 2
            ? yup.string().nullable().notRequired()
            : yup.string().nullable().notRequired(),
        powerOfAttorniesDate:
          typeValue === 2
            ? yup.date().required("Поле обязательно к заполнению")
            : yup.date().nullable().notRequired(),
        region: yup
          .object()
          .nullable()
          .required("Поле обязательно к заполнению"),
        street: yup
          .string()
          .nullable()
          .required("Поле обязательно к заполнению"),
        block: yup
          .string()
          .nullable()
          .required("Поле обязательно к заполнению"),
        phone: isKZ
          ? yup
              .string()
              .nullable()
              .required("Поле обязательно к заполнению")
              .matches(
                /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
                "Номер телефона должен быть в формате +7 (###) ###-##-##"
              )
          : yup.string().nullable().required("Поле обязательно к заполнению"),
        kbe: yup.string().nullable().required("Поле обязательно к заполнению"),
        email: yup
          .string()
          .nullable()
          .email("Введите корректный адрес электронной почты")
          .required("Поле обязательно к заполнению"),
      })
    );
  }, [formData]);

  useEffect(() => {
    const fetchData = async () => {
      if (data && data.id) {
        try {
          const response = await getCompanyById(data?.id);
          if (response && response.succeeded) {
            const companyData = response.data;
            const firstAddress = response.data?.addresses?.[0] || {};
            setCompanyId(response.data.id);
            setRegionIdUpdate(firstAddress.region && firstAddress.region.id);
            setAddressIdUpdate(firstAddress.id);
            const currentUserData = response.data.users.find(
              (u: any) => u.user.id === user?.id
            );

            const registrationDate =
              currentUserData?.registrationCertificate &&
              currentUserData?.registrationCertificate?.date;
            const powerOfAttorneyDate =
              currentUserData?.powerOfAttorney &&
              // @ts-ignore
              currentUserData?.powerOfAttorney?.date;

            if (companyData && companyData.files) {
              const initialFiles: any = {};
              companyData.files
                .filter((fileData: any) => !fileData.isDeleted)
                .forEach((fileData: any) => {
                  const typeId = fileData.typeId;
                  if (!initialFiles[typeId]) {
                    initialFiles[typeId] = [];
                  }
                  initialFiles[typeId].push({
                    name: fileData.file.name,
                    url: fileData.file.path,
                    id: fileData.file.id,
                  });
                });

              setFiles(initialFiles);
            }
            if (companyData && companyData.bankAccounts) {
              const bankAccounts = companyData.bankAccounts.map(
                (account: CompanyBankAccountData) => ({
                  bank: {
                    value: account.bank && account.bank.id,
                    label: account.bank && account.bank.name,
                    item: account.bank,
                  },
                  accountNumber: account.accountNumber,
                  isMain: account.isMain,
                  bankAccountId: account.id,
                })
              );
              setValues({
                ...formData,
                bank: bankAccounts,
              });
            }

            const formattedPhone = formatPhoneNumber(
              companyData.phone,
              companyData.country?.name || ""
            );

            const initialFormData = {
              organization: companyData.name || "",
              bin: companyData.bin || "",
              email: companyData.email || "",
              phone: formattedPhone || "",
              registrationNumber: companyData.registrationNumber || "",
              headIdn: companyData.headIdn || "",
              headName: companyData.headName || "",
              kbe: companyData.kbe || "",

              country: companyData.country
                ? {
                    value: companyData.country.id,
                    label: companyData.country.name,
                    item: companyData.country,
                  }
                : null,
              nds: companyData.nds
                ? {
                    value: companyData.nds.id,
                    label: companyData.nds.name,
                    item: companyData.nds,
                  }
                : null,
              companyType: companyData.type
                ? { value: companyData.type.id, label: companyData.type.name }
                : null,
              type: currentUserData?.employmentType
                ? {
                    value: currentUserData?.employmentType.id,
                    label: currentUserData?.employmentType.name,
                    item: currentUserData?.employmentType,
                  }
                : null,
              region: firstAddress.region
                ? {
                    value: firstAddress.region.id,
                    label: firstAddress.region.name,
                    item: firstAddress.region,
                  }
                : null,
              street: firstAddress.street || "",
              block: firstAddress.block || "",
              apartment: firstAddress.apartment || "",
              powerOfAttorniesNumber:
                // @ts-ignore
                currentUserData?.powerOfAttorney?.number || "",
              powerOfAttorniesDate: new Date(powerOfAttorneyDate) || null,
              registrationCertificateNumber:
                currentUserData?.registrationCertificate?.number || "",
              registrationCertificateSeries:
                currentUserData?.registrationCertificate?.series || "",
              registrationCertificateDate:
                new Date(registrationDate ?? "") || null,
            };

            setValues(initialFormData);
          }
        } catch (error) {
          console.error("Ошибка при загрузке данных компании:  ", error);
        }
      }
    };

    fetchData();
  }, [data, setValues, user]);

  const modalTitle = useMemo<ReactNode>(() => {
    return (
      <StyledModalHeader>
        <StyledModalTitle>
          Редактирование организации
          <StyledChip>
            <CompanyStatusChip
              status={data?.companyStatus ?? data?.status}
              comment={data?.comment}
            />
          </StyledChip>
        </StyledModalTitle>
      </StyledModalHeader>
    );
  }, [data]);

  const loadCompanyFileTypes = useCallback(async () => {
    try {
      const response = await getCompanyFileTypes();
      let initialData = response.data.map(
        (companyFileTypes: CompanyFileTypes) => ({
          value: companyFileTypes.id,
          label: companyFileTypes.name,
        })
      );

      const isCompanyType1 = formData.companyType?.value === 1;
      const registrationCertificateDate = formData.registrationCertificateDate
        ? new Date(formData.registrationCertificateDate)
        : null;
      const requiresNdsCertificate =
        formData.nds && formData.nds.item && formData.nds.item.exemptTurnover;

      let allowedTypesForConditions = [
        {
          condition: isCompanyType1 && formData.type?.value === 3,
          types: [
            "Свидетельство о гос.регистрации",
            "Удостоверение личности владельца",
            "Справка о наличии расчетного счета",
            "Уведомление о начале деятельности в качестве индивидуального предпринимателя",
            "Дополнительные файлы по запросу заказчиков",
          ],
        },
        {
          condition: isCompanyType1 && formData.type?.value === 2,
          types: [
            "Свидетельство о гос.регистрации",
            "Удостоверение доверенного лица",
            "Доверенность",
            "Справка о наличии расчетного счета",
            "Удостоверение личности владельца",
            "Уведомление о начале деятельности в качестве индивидуального предпринимателя",
            "Дополнительные файлы по запросу заказчиков",
          ],
        },
        {
          condition:
            formData.companyType?.value === 2 && formData.type?.value === 1,
          types: [
            "Свидетельство о гос.регистрации",
            "Удостоверение личности владельца",
            "Приказ",
            "Устав",
            "Справка о наличии расчетного счета",
            "Дополнительные файлы по запросу заказчиков",
          ],
        },
        {
          condition:
            formData.companyType?.value === 2 && formData.type?.value === 2,
          types: [
            "Свидетельство о гос.регистрации",
            "Удостоверение личности владельца",
            "Приказ",
            "Устав",
            "Доверенность",
            "Справка о наличии расчетного счета",
            "Удостоверение доверенного лица",
            "Дополнительные файлы по запросу заказчиков",
          ],
        },
      ];

      let filteredData = initialData.filter(({ label }: any) => {
        for (let condition of allowedTypesForConditions) {
          if (condition.condition && condition.types.includes(label)) {
            return true;
          }
        }
        if (label === "Свидетельство о постановке на НДС") {
          return !requiresNdsCertificate;
        }
        return false;
      });

      const registrationCertificateIndex = filteredData.findIndex(
        (fileType: CompanyFile) =>
          fileType.label === "Свидетельство о гос.регистрации"
      );

      const notificationIndex = initialData.findIndex(
        (fileType: CompanyFile) =>
          fileType.label === "Уведомление о государственной регистрации ИП"
      );

      if (
        isCompanyType1 &&
        registrationCertificateDate &&
        registrationCertificateDate >= new Date("2014-12-31")
      ) {
        if (registrationCertificateIndex > -1 && notificationIndex > -1) {
          const [registrationCertificateFile] = filteredData.splice(
            registrationCertificateIndex,
            1
          );
          const [notificationFile] = initialData.splice(notificationIndex, 1);
          filteredData.unshift(notificationFile);
          filteredData.unshift(registrationCertificateFile);
        } else if (registrationCertificateIndex > -1) {
          const [registrationCertificateFile] = filteredData.splice(
            registrationCertificateIndex,
            1
          );
          filteredData.unshift(registrationCertificateFile);

          const [notificationFile] = initialData.splice(notificationIndex, 1);
          filteredData.splice(1, 0, notificationFile);
        }
      } else {
        if (registrationCertificateIndex > -1) {
          const [registrationCertificateFile] = filteredData.splice(
            registrationCertificateIndex,
            1
          );
          filteredData.unshift(registrationCertificateFile);
        }
      }

      setCompanyFileTypes(filteredData);
    } catch (error) {
      console.error("Ошибка при загрузке списка: ", error);
    }
  }, [formData]);

  useEffect(() => {
    (async () => {
      await loadCompanyFileTypes();
    })();
  }, [loadCompanyFileTypes]);

  const onFileDelete = useCallback(
    (fileTypeId: string, fileIndex: number) => {
      setFiles((prevFiles) => {
        const updatedFiles = { ...prevFiles };
        const filesList = updatedFiles[fileTypeId];

        if (filesList && filesList.length > fileIndex) {
          const fileToDelete = filesList[fileIndex];
          setFilesToDelete({ file: fileToDelete, fileTypeId, fileIndex });
          setShowDialog(true);
        }

        return updatedFiles;
      });
      const input = document.getElementById(
        `file-input-${fileTypeId}`
      ) as HTMLInputElement;
      if (input) {
        input.value = "";
      }
    },
    [setFiles]
  );

  const onDeleteConfirm = async () => {
    if (filesToDelete.file.id) {
      if (!companyId) {
        console.error("Error: CompanyId is not set.");
        showNotification({
          message: "Не указана компания",
          variant: "error",
        });
        return;
      }

      try {
        await deleteFile({
          companyId: companyId,
          fileId: filesToDelete.file.id,
        });
        setIsFilesSaved(false);
      } catch (error) {
        console.error("Error deleting vehicle file: ", error);
        showNotification({
          message: "Ошибка при удалении файла.",
          variant: "error",
        });
      }
    }

    setFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      const fileType = filesToDelete.fileTypeId;
      if (
        updatedFiles[fileType] &&
        updatedFiles[fileType].length > filesToDelete.fileIndex
      ) {
        updatedFiles[fileType].splice(filesToDelete.fileIndex, 1);
        if (updatedFiles[fileType].length === 0) {
          delete updatedFiles[fileType];
        }
      }
      return updatedFiles;
    });

    setShowDialog(false);
    setFilesToDelete([]);
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [open]);

  const onConfirmStatus = useCallback(async () => {
    setIsConfirmingStatus(true);

    try {
      const body = {
        companyId: !!data ? data && data.id : companyId,
        statusId: 1,
      };
      await confirmCompanyStatus(body);
    } catch (error) {
      console.error("Ошибка при подтверждении статуса компании: ", error);
      showNotification({
        message: "Ошибка при подтверждении статуса компании",
        variant: "error",
      });
      setIsConfirmingStatus(false);
    }

    setIsConfirmingStatus(false);
  }, [data, companyId, onClose, resetForm, showNotification]);

  const handleSaveFiles = async (companyId: string) => {
    if (isFilesSaved) return;

    try {
      const actualCompanyId = data && data.id ? data.id : companyId;

      for (const typeId of Object.keys(files)) {
        for (const file of files[typeId]) {
          if (!file.id || file.isNew) {
            const response = await onAddCompanyFile({
              CompanyId: actualCompanyId,
              File: file,
              TypeId: typeId,
            });

            if (response.succeeded) {
              const updatedFile = response.data.file;

              setFiles((prevFiles) => {
                const updatedFilesForType = prevFiles[typeId].map((f) =>
                  f.name === file.name ? { ...f, ...updatedFile } : f
                );
                return { ...prevFiles, [typeId]: updatedFilesForType };
              });
            }
          }
        }
      }
      setIsFilesAdded(true);
      setIsFilesSaved(true);
    } catch (error) {
      console.error("Ошибка при сохранении файлов: ", error);
      setIsFilesAdded(false);
    }
  };

  const onSaveStep1 = useCallback(
    async (isSaving = true) => {
      const isValid = await validate();

      if (!isValid) {
        return;
      }
      setPending(true);

      const phoneNumber = formData.phone
        ? stripPhoneNumberFormatting(formData.phone)
        : "";

      const formattedPhoneNumber =
        formData.country.label === "Казахстан"
          ? `7${phoneNumber.slice(1)}`
          : formData.country.label === "Узбекистан"
          ? `9${phoneNumber.slice(1)}`
          : phoneNumber;

      const body: any = {
        typeId: formData.companyType && formData.companyType.value,
        name: formData.organization,
        bin: formData.bin,
        headName: formData.headName,
        kbe: formData.kbe,
        headIdn: formData.headIdn,
        countryId: formData.country.value,
        registrationNumber: formData.registrationNumber,
        phone: formattedPhoneNumber,
        email: formData.email,
        ndsId: formData.nds && formData.nds.value,
      };

      let response;
      if (isSaving) {
        if (companyId) {
          response = await onUpdateCompany({ ...body, id: companyId });

          if (response.succeeded) {
            showNotification({
              message: "Общая информация успешно отредактирован",
              variant: "success",
            });
          }
        }
      }
      await onConfirmStatus();
      setPending(false);
    },
    [
      addressIdUpdate,
      companyId,
      data,
      formData,
      regionIdUpdate,
      setPending,
      showNotification,
      validate,
      onUpdateCompany,
    ]
  );

  const onSaveStep2 = useCallback(
    async (isSaving = true) => {
      const isValid = await validate();

      if (!isValid) {
        return;
      }
      setPending(true);

      let response;
      if (isSaving) {
        if (companyId) {
          response = await onUpdateAddress({
            companyId: companyId,
            addressId: addressIdUpdate,
            regionId: formData.region?.value,
            street: formData.street,
            block: formData.block,
            apartment: formData.apartment,
            isLegalAddress: true,
          });

          if (response.succeeded) {
            showNotification({
              message: "Адрес организации успешно отредактирован",
              variant: "success",
            });
          }
        }
      }
      setPending(false);
      await onConfirmStatus();
    },
    [
      addressIdUpdate,
      companyId,
      data,
      formData,
      regionIdUpdate,
      setPending,
      showNotification,
      validate,
      onUpdateAddress,
    ]
  );

  const onSaveStep3 = useCallback(
    async (isSaving = true) => {
      const isValid = await validate();

      if (!isValid) {
        return;
      }
      setPending(true);

      let response;
      let organizationId;
      if (isSaving) {
        if (companyId) {
          organizationId = companyId;
          setIsUpdateComplete(true);
          response = await joinAnCompany({
            companyId: organizationId,
            isSigner: true,
            employmentTypeId: formData.type && formData.type.value,
            registrationCertificateNumber:
              formData.registrationCertificateNumber,
            registrationCertificateSeries:
              formData.registrationCertificateSeries || "",
            registrationCertificateDate: formData.registrationCertificateDate
              ? format(
                  formData.registrationCertificateDate,
                  "yyyy-MM-dd'T'HH:mm:ss"
                )
              : "",
            powerOfAttorneyNumber: formData.powerOfAttorniesNumber || "",
            powerOfAttorneyDate: formData.powerOfAttorniesDate
              ? format(formData.powerOfAttorniesDate, "yyyy-MM-dd'T'HH:mm:ss")
              : "",
            fileId: formData.fileId,
          });
          if (response.succeeded) {
            showNotification({
              message: "Основание деятельности успешно отредактирован",
              variant: "success",
            });
          }
        }
      }
      setPending(false);
      await onConfirmStatus();
    },
    [
      addressIdUpdate,
      companyId,
      data,
      formData,
      regionIdUpdate,
      setPending,
      showNotification,
      validate,
    ]
  );

  const onSaveDocuments = useCallback(async () => {
    setPending(true);
    await handleSaveFiles(companyId);
    setPending(false);
    await onConfirmStatus();
    showNotification({
      message: "Документы сохранены",
      variant: "success",
    });
  }, [companyId, handleSaveFiles, setPending, showNotification]);

  const onConfirmLastStepStatus = useCallback(async () => {
    setIsConfirmingStatus(true);

    try {
      const body = {
        companyId: !!data ? data && data.id : companyId,
        statusId: 1,
      };

      const response = await confirmCompanyStatus(body);

      if (response?.succeeded) {
        showNotification({
          message: "Компания отправлена на проверку",
          variant: "success",
        });
        setIsConfirmingStatus(false);
      } else {
        showNotification({
          message:
            response.message || "Ошибка при подтверждении статуса компании",
          variant: "error",
        });
        setIsConfirmingStatus(false);
      }
      onClose();
      resetForm();
      setCurrentTab(0);
    } catch (error) {
      console.error("Ошибка при подтверждении статуса компании: ", error);
      showNotification({
        message: "Ошибка при подтверждении статуса компании",
        variant: "error",
      });
      setIsConfirmingStatus(false);
    }

    setIsConfirmingStatus(false);
  }, [data, companyId, onClose, resetForm, showNotification]);

  const handleSaveAndConfirm = useCallback(async () => {
    setIsSavingBankAccount(true);
    try {
      if (data?.companyStatus === 1) {
        const allFilesAdded = companyFileTypes.every((fileType) => {
          if (fileType.value === "26043326-f5cb-454a-81a0-ddd479669431") {
            return true;
          }
          return files[fileType.value] && files[fileType.value].length > 0;
        });

        if (!allFilesAdded) {
          showNotification({
            message: "Добавьте все необходимые файлы перед продолжением",
            variant: "error",
          });
          setIsSavingBankAccount(false);
          return;
        }
      }

      if (Array.isArray(formData.bank)) {
        const responses = await Promise.all(
          formData.bank.map(async (bankItem: any) => {
            const body = {
              companyId: !!data ? data.id : companyId,
              bankId: bankItem.bank.value,
              accountNumber: bankItem.accountNumber,
              isMain: bankItem.isMain,
            };

            if (!!data && !!bankItem.bankAccountId) {
              return onUpdateBankAccount({
                ...body,
                bankAccountId: bankItem.bankAccountId,
              });
            } else {
              return onAddBankAccount(body);
            }
          })
        );
        const succeeded = responses.every((response) => response?.succeeded);
        if (succeeded) {
          showNotification({
            message: "Банковские реквизиты сохранены",
            variant: "success",
          });
          setIsBankAccountSaved(true);
          // await onConfirmLastStepStatus();
        } else {
          showNotification({
            message: "Ошибка при сохранении банковских реквизитов",
            variant: "error",
          });
        }
      }
    } catch (error) {
      console.error("Ошибка при сохранении банковских реквизитов: ", error);
      setIsSavingBankAccount(false);
      showNotification({
        message: getAxiosErrorMessage(error as AxiosError<APIResponse>),
        variant: "error",
      });
    } finally {
      setIsSavingBankAccount(false);
    }
  }, [companyId, data, formData.bank, showNotification, onConfirmStatus]);

  const areFilesUploaded = () => {
    const optionalFileTypeId = "26043326-f5cb-454a-81a0-ddd479669431";

    return companyFileTypes.every((fileType) => {
      if (fileType.value === optionalFileTypeId) return true;
      return files[fileType.value] && files[fileType.value].length > 0;
    });
  };

  useEffect(() => {
    if (data) {
      setValues(data);
    } else {
      resetForm();
    }
  }, [data]);

  const onModalClose = useCallback(() => {
    setValues({});
    resetForm();
    setFiles({});
    setFilesToDelete([]);
    setStatusId(null);
    setCurrentTab(0);
    setIsSuccessful(false);
    window.location.reload();
    onClose();
  }, [onClose]);

  if (!open) {
    return null;
  }

  const tabLabels = [
    "Общая информация",
    "Адрес организации",
    "Основание деятельности",
    "Документы",
    "Банковские реквизиты",
  ];

  const onEditClick = async () => {
    const isValid = await validate();

    if (!isValid) {
      return showNotification({
        message: "Заполните все обязательные поля",
        variant: "error",
      });
    }
    setShowEditDialog(true);
    setHasChanged(false);
  };

  const handleConfirmEdit = async () => {
    setShowEditDialog(false);
    switch (currentTab) {
      case 0:
        await onSaveStep1();
        break;
      case 1:
        await onSaveStep2();
        break;
      case 2:
        await onSaveStep3();
        break;
      case 3:
        await onSaveDocuments();
        break;
      case 4:
        await handleSaveAndConfirm();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {showUpdateDialog && (
        <UpdateDialog
          open={showUpdateDialog}
          title="Организация уже подтверждена."
          subtitle="Хотите отредактировать данные?"
          loading={false}
          onClose={onModalClose}
          onConfirm={onConfirmToEdit}
        />
      )}
      <Modal
        title={modalTitle}
        onClose={onModalClose}
        open={open}
        dense
        {...restProps}
      >
        <ModalContainer>
          <StyledHeader>
            <Tabs
              labels={tabLabels}
              onChange={(index: number) => setCurrentTab(index)}
            >
              <Step1
                dataOfOrganization={formData}
                dataParams={formData}
                dataErrors={errors}
                onDataChange={handleFieldChange}
                readonly={readonly}
              />
              <Step2
                dataOfOrganization={formData}
                dataParams={formData}
                dataErrors={errors}
                onDataChange={handleFieldChange}
                readonly={readonly}
              />
              <Step3
                dataOfOrganization={formData}
                dataParams={formData}
                dataErrors={errors}
                onDataChange={handleFieldChange}
                readonly={readonly}
              />
              <Step4
                filterParams={formData}
                files={files}
                setFiles={setFiles}
                companyFileTypes={companyFileTypes}
                onFileDelete={onFileDelete}
                readonly={readonly}
              />
              <Step5
                dataParams={formData}
                readonly={readonly}
                onDataChange={handleFieldChange}
              />
            </Tabs>
          </StyledHeader>
          <StyledContent>
            <Dialog
              open={showDialog}
              title={`Вы уверены, что хотите удалить этот файл?`}
              icon={IconTrash24}
              onConfirm={onDeleteConfirm}
              onClose={() => {
                setShowDialog(false);
              }}
            />
            <Dialog
              open={showEditDialog}
              title="Хотите отредактировать информацию и отправить на модерацию?"
              onConfirm={handleConfirmEdit}
              confirmLabel={"Редактировать"}
              onClose={() => setShowEditDialog(false)}
            />
          </StyledContent>

          {!readonly && (
            <StyledFooter>
              <ButtonRow>
                {currentTab === 0 && (
                  <Button
                    text="Сохранить"
                    variant="outlined"
                    showLoader={pending}
                    onClick={onEditClick}
                    disabled={!hasChanged}
                  />
                )}
                {currentTab === 1 && (
                  <Button
                    text="Сохранить"
                    variant="outlined"
                    showLoader={pending}
                    onClick={onEditClick}
                    disabled={!hasChanged}
                  />
                )}
                {currentTab === 2 && (
                  <Button
                    text="Сохранить"
                    variant="outlined"
                    showLoader={pending}
                    onClick={onEditClick}
                    disabled={!hasChanged}
                  />
                )}
                {currentTab === 3 && (
                  <Button
                    text="Сохранить"
                    variant="outlined"
                    showLoader={pending}
                    disabled={!areFilesUploaded()}
                    onClick={onEditClick}
                  />
                )}
                {currentTab === 4 && (
                  <Button
                    text="Сохранить"
                    variant="outlined"
                    showLoader={pending}
                    onClick={onEditClick}
                    disabled={!hasChanged}
                  />
                )}
                {currentTab === 4 && (
                  <Button
                    showLoader={pending}
                    onClick={onConfirmLastStepStatus}
                    text={"Отправить на модерацию"}
                  />
                )}
              </ButtonRow>
            </StyledFooter>
          )}
        </ModalContainer>
      </Modal>
    </>
  );
}

export default memo(ModalOrganizationEdit);
