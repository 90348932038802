import {
  APIResponse,
  PageableParams,
  PageableResponse,
  PageableResponseObject,
} from "./interfaces";
import {
  AddVehicleFileResponse,
  VehicleFileData,
  VehicleHistory,
  VehicleInfo,
  VehicleJournalCommentDto,
  VehicleJournalConfirmRequest,
  VehicleJournalDetail,
  VehicleJournalDetailParams,
  VehicleJournalForAct,
  VehicleJournalForActV2,
  VehicleJournalList,
  VehicleJournalLoadDataRequest,
  VehicleJournalNotWorkRequest,
  VehicleListItem,
  VehicleModel,
  VehicleMonitoring,
  VehicleReport,
  VehicleTrackPoint,
  VehicleTrackState,
  VehicleType,
} from "@app/models";
import instance from "./instance";

interface VehicleCreateDTO {
  companyIds: string[];
  vehicleTypeId: string;
  vehicleModelId: string;
  characteristicsId: string;
  govNumber: string;
  gpsId: string;
  files: File[];
}

export interface MonitoringVehiclesParams {
  dateMax: string;
  dateMin?: string;
  govNumber?: string;
  organizationIds?: string[];
  projectIds?: string[];
  partnerIds?: string[];
  vehicleTypeIds?: string[];
  characteristicsIds?: string[];
  isAvailable?: boolean;
  gpsId?: string;
  gpsDataUpdateStatusIds?: number;
}

export interface JournalCommentCreate {
  vehicleJournalId: string;
  comment: string;
}

export interface JournalCommentUpdate {
  id: string;
  comment: string;
}

export interface JournalFile {
  vehicleJournalId: string;
  files: {
    fileId: string;
    typeId: string;
  }[];
}

export interface VehicleFileTypesResponse {
  id: string;
  name: string;
}

export function getVehicles(
  params: PageableParams<VehicleListItem> & {
    companyId?: string;
  }
): Promise<PageableResponse<VehicleListItem>> {
  return instance()
    .get("/vehicle", {
      params,
    })
    .then((response) => response.data);
}

export function getVehicleDetail(
  id: string,
  params?: PageableParams
): Promise<APIResponse<VehicleInfo>> {
  return instance()
    .get(`/vehicle/${id}`, {
      params,
    })
    .then((response) => response.data);
}

export function getVehicleTypes(
  params: PageableParams<VehicleType>
): Promise<PageableResponse<VehicleType>> {
  return instance()
    .get("/vehicle/type", {
      params,
    })
    .then((response) => response.data);
}

export function getVehicleModels(
  params: PageableParams<VehicleModel>
): Promise<PageableResponse<VehicleModel>> {
  return instance()
    .get("/vehicle/model", {
      params,
    })
    .then((response) => response.data);
}

export function createVehicle(data: any): Promise<APIResponse<any>> {
  return instance()
    .post("/vehicle", data)
    .then((response) => response.data);
}

export function updateVehicle(data: any): Promise<APIResponse<any>> {
  return instance()
    .put("/vehicle", data)
    .then((response) => response.data);
}

export function updateVehicleStatus(data: any): Promise<APIResponse<any>> {
  return instance()
    .put("/vehicle/company", data)
    .then((response) => response.data);
}

export function sendToModerate(data: any): Promise<APIResponse<any>> {
  return instance()
    .post("/vehicle/company", data)
    .then((response) => response.data);
}

export function addCommentInJournal(
  data: JournalCommentCreate
): Promise<APIResponse<VehicleJournalCommentDto>> {
  return instance()
    .post("/vehicle/journal/comment", data)
    .then((response) => response.data);
}

export function updateCommentInJournal(
  data: JournalCommentUpdate
): Promise<APIResponse<VehicleJournalCommentDto>> {
  return instance()
    .put("/vehicle/journal/comment", data)
    .then((response) => response.data);
}

export function deleteCommentInJournal(
  id: VehicleJournalCommentDto["id"]
): Promise<APIResponse<VehicleJournalCommentDto>> {
  return instance()
    .delete(`/vehicle/journal/comment/${id}`)
    .then((response) => response.data);
}

export function getVehicleFileList(): Promise<PageableResponse<any>> {
  return instance()
    .get("/vehicleFileType/list")
    .then((response) => response.data);
}

export function getVehicleFileTypeList(
  id: string
): Promise<PageableResponse<any>> {
  return instance()
    .get(`/vehicle/type/${id}/fileType/list`)
    .then((response) => response.data);
}

export function getVehicleById(id: string): Promise<APIResponse<VehicleInfo>> {
  return instance()
    .get(`/vehicle/${id}`)
    .then((response) => response.data);
}

export function addVehicleFile(
  data: VehicleFileData
): Promise<APIResponse<AddVehicleFileResponse>> {
  const formData = new FormData();
  formData.append("VehicleId", data.VehicleId);
  formData.append("File", data.File);
  formData.append("TypeId", data.TypeId);

  if (data.ExpirationDate) {
    formData.append("ExpirationDate", data.ExpirationDate);
  }

  if (data.CompanyId) {
    formData.append("CompanyId", data.CompanyId);
  }

  return instance()
    .post("/vehicle/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log("FILE ERROR: ", error);
    });
}

export function deleteVehicleFile(data: any): Promise<APIResponse<any>> {
  return instance()
    .delete(`/vehicle/file`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })
    .then((response) => response.data);
}

export function getVehicleJournalForAct(
  params: any
): Promise<APIResponse<VehicleJournalForAct[]>> {
  return instance()
    .get("/vehicle/journal/for-act", { params })
    .then((response) => response.data);
}

export function getVehicleJournalForActV2(
  params: any
): Promise<APIResponse<VehicleJournalForActV2[]>> {
  return instance()
    .get(`vehicle/journal/v2-for-act`, { params })
    .then((response) => response.data);
}

export function getVehicleJournalById(
  id: any
): Promise<APIResponse<VehicleJournalDetail>> {
  return instance()
    .get(`/vehicle/journal/${id}`, {})
    .then((response) => response.data);
}

export function getVehicleJournalListByDate(
  params: PageableParams
): Promise<PageableResponse<VehicleJournalList>> {
  return instance()
    .get("/vehicle/journal/list/group-by-date", { params })
    .then((response) => response.data);
}

export function getVehicleJournalListByPartner(
  params: PageableParams
): Promise<PageableResponse<VehicleJournalList>> {
  return instance()
    .get("/vehicle/journal/list/group-by-partner", { params })
    .then((response) => response.data);
}

export function getVehicleJournalListByTechnic(
  params: PageableParams
): Promise<PageableResponse<VehicleJournalList>> {
  return instance()
    .get("/vehicle/journal/list/group-by-vehicleType", { params })
    .then((response) => response.data);
}

export function getVehicleJournalDetailByDate(
  params: VehicleJournalDetailParams
): Promise<PageableResponse<VehicleJournalDetail>> {
  return instance()
    .get("/vehicle/journal/detail/by-date", { params })
    .then((response) => response.data);
}

export function getVehicleJournalDetailByPartner(
  params: VehicleJournalDetailParams
): Promise<PageableResponse<VehicleJournalDetail>> {
  return instance()
    .get("/vehicle/journal/detail/by-partner", { params })
    .then((response) => response.data);
}

export function getVehicleJournalDetailByTechnic(
  params: VehicleJournalDetailParams
): Promise<PageableResponse<VehicleJournalDetail>> {
  return instance()
    .get("/vehicle/journal/detail/by-vehicleType", { params })
    .then((response) => response.data);
}

export function setVehicleJournalConfirmed(
  body: VehicleJournalConfirmRequest
): Promise<APIResponse<VehicleJournalDetail>> {
  const formData = new FormData();

  Object.keys(body).forEach((fieldName) => {
    if (["files"].indexOf(fieldName) > -1) {
      formData.append(
        `${fieldName}[]`,
        body[fieldName as keyof VehicleJournalConfirmRequest] as string
      );
    } else {
      formData.append(
        fieldName,
        body[fieldName as keyof VehicleJournalConfirmRequest] as string
      );
    }
  }, []);

  return instance()
    .put("/vehicle/journal/set", formData)
    .then((response) => response.data);
}

export function setVehicleJournalNotWork(
  data: VehicleJournalNotWorkRequest
): Promise<APIResponse<VehicleJournalDetail>> {
  const formData = new FormData();

  Object.keys(data).forEach((fieldName) => {
    if (["files"].indexOf(fieldName) > -1) {
      formData.append(
        `${fieldName}[]`,
        data[fieldName as keyof VehicleJournalNotWorkRequest] as string
      );
    } else {
      formData.append(
        fieldName,
        data[fieldName as keyof VehicleJournalNotWorkRequest] as string
      );
    }
  }, []);

  return instance()
    .put(`/vehicle/journal/not-work`, formData)
    .then((response) => response.data);
}

export function setVehicleJournalNotWorkFurther(
  data: VehicleJournalNotWorkRequest
): Promise<APIResponse<VehicleJournalDetail>> {
  const formData = new FormData();

  Object.keys(data).forEach((fieldName) => {
    if (["files"].indexOf(fieldName) > -1) {
      formData.append(
        `${fieldName}[]`,
        data[fieldName as keyof VehicleJournalNotWorkRequest] as string
      );
    } else {
      formData.append(
        fieldName,
        data[fieldName as keyof VehicleJournalNotWorkRequest] as string
      );
    }
  }, []);

  return instance()
    .put(`/vehicle/journal/not-work-further`, formData)
    .then((response) => response.data);
}

export function loadVehicleJournalData(
  body: VehicleJournalLoadDataRequest
): Promise<PageableResponse<unknown>> {
  return instance()
    .put("/vehicle/journal/load-data", body)
    .then((response) => response.data);
}

export function getMonitoringVehicles(
  params: PageableParams & MonitoringVehiclesParams
): Promise<
  PageableResponseObject<{
    vehicles: VehicleMonitoring[];
    statusesQty: {
      statusId: number;
      quantity: number;
    }[];
  } | null>
> {
  return instance()
    .get("/vehicle/monitoring/list", {
      params,
    })
    .then((response) => response.data);
}

export function getMonitoringVehiclesState(
  vehicleIds: string[]
): Promise<APIResponse<VehicleTrackState[]>> {
  return instance()
    .get("/vehicle/gps/state/list", {
      params: {
        vehicleIds,
      },
    })
    .then((response) => response.data);
}

export function getVehicleTrack(
  id: string,
  params: {
    fromTime: number;
    toTime: number;
  }
): Promise<
  APIResponse<{
    points: VehicleTrackPoint[];
  }>
> {
  return instance()
    .get(`/vehicle/${id}/gps/track`, {
      params,
    })
    .then((response) => response.data);
}

export function getVehicleState(
  id: string
): Promise<APIResponse<VehicleTrackState>> {
  return instance()
    .get(`/vehicle/${id}/gps/state`)
    .then((response) => response.data);
}

export function getVehicleReport(
  id: string,
  params: {
    fromDateTime: string;
    toDateTime: string;
  }
): Promise<APIResponse<VehicleReport>> {
  return instance()
    .get(`/vehicle/${id}/gps/report`, {
      params,
    })
    .then((response) => response.data);
}

export function getVehicleHistory(
  id: string,
  params: PageableParams & {
    date: string;
  }
): Promise<PageableResponse<VehicleHistory>> {
  return instance()
    .get(`/vehicle/${id}/request/list`, {
      params,
    })
    .then((response) => response.data);
}

export const uploadVehicleFile = (
  formData: FormData
): Promise<APIResponse<any>> => {
  return instance()
    .post("/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

export interface VehicleFileType {
  id: string;
  name: string;
}

export const createVehicleFile = (
  data: JournalFile
): Promise<APIResponse<any>> => {
  return instance()
    .post<APIResponse<boolean>>("/vehicle/journal/files", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data);
};

export function removeVehicleFile(id: string): Promise<APIResponse<null>> {
  return instance()
    .delete<APIResponse<null>>(`/vehicle/journal/files/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data);
}

export function getVehicleFileTypes(): Promise<APIResponse<VehicleFileType[]>> {
  return instance()
    .get<APIResponse<VehicleFileType[]>>(`/VehicleJurnalFileType/short`)
    .then((response) => response.data);
}
