import { useCallback } from "react";
import { Button, ModalTitle, Tooltip } from "@app/components";
import { IconPrint16 } from "@app/icons";
import { ActListItem } from "@app/models";

type Props = {
  data: ActListItem;
  avrId: string | null | undefined;
  isActNotAgreed: boolean;
  isContractSigned: boolean;
  canCreateAVR: boolean;
  pending: boolean;
  onAvrCreate: () => {};
};

function AOSModalTitle({
  data,
  avrId,
  isActNotAgreed,
  isContractSigned,
  canCreateAVR,
  pending,
  onAvrCreate,
}: Props) {
  const openLink = (path: string) => () => {
    window.open(`/act-of-provided-works${path}`, "_blank");
  };

  const onPrintButtonClick = useCallback(() => {
    window.open(
      `./act-of-provided-services/printing/${data!.id}`,
      "_blank",
      "noopener,noreferrer"
    );
  }, [data]);

  return (
    <ModalTitle text={`Табель оказанных услуг №${data.actNumber}`}>
      <Tooltip
        direction={"right"}
        content={
          isActNotAgreed
            ? "Табель должен быть согласован заказчиком для создания АВР"
            : !isContractSigned
            ? "Договор еще не подписан. Обратитесь к ответственному лицу"
            : ""
        }
      >
        <Button
          text="Создать АВР"
          size="small"
          showLoader={pending}
          disabled={!canCreateAVR}
          onClick={onAvrCreate}
        />
      </Tooltip>

      {avrId && (
        <Button
          text="Открыть АВР"
          size="small"
          onClick={openLink(`?avrId=${avrId}`)}
        />
      )}
      {/* попросили убрать условие */}
      {!!data.status /*&& !hidePrintButton*/ && (
        <Button
          text={"Печатная форма"}
          size={"small"}
          variant={"outlined"}
          startIcon={IconPrint16}
          onClick={onPrintButtonClick}
        />
      )}
    </ModalTitle>
  );
}

export default AOSModalTitle;
