import styled from "styled-components";
import { theme } from "styled-tools";
import { StyledContainer } from "@app/components";
import { Link } from "react-router-dom";

export const Header = styled.header`
  background-color: ${theme("color.white")};
  border-bottom: 1px solid ${theme("color.grayLight")};
  display: flex;
  align-items: center;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
`;

export const HeaderContainer = styled(StyledContainer)`
  display: flex;
  gap: 40px;
  align-items: center;
  width: 100%;
`;

export const LogoContainer = styled(Link)`
  width: 90px;
  color: inherit;

  > svg {
    width: 100%;
  }
`;

export const HeaderNav = styled.ul`
  display: flex;
  gap: 20px;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0;
  margin: 0;
  list-style: none;

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

export const HeaderNavItem = styled.li`
  a {
    font-size: 16px;
    line-height: 16px;
    color: ${theme("color.dark")};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const IconButton = styled.a`
  display: inline-flex;
  gap: 8px;
  align-items: center;
  text-decoration: none;
  margin-left: auto;

  > svg {
    color: ${theme("color.orange")};
  }

  span {
    font-size: 16px;
    line-height: 16px;
    color: ${theme("color.dark")};
    white-space: nowrap;

    @media only screen and (max-width: 576px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  @media only screen and (max-width: 576px) {
    span {
      display: none;
    }
  }
`;

export const ContactButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 8px;
  border: none;
  border-radius: 100px;
  color: ${theme("color.white")};
  background: linear-gradient(
    90deg,
    ${theme("color.orange")} 20%,
    ${theme("color.orangeLight")} 80%
  );
  cursor: pointer;
`;

export const ContactsPopup = styled.div`
  position: absolute;
  right: 0;
  min-width: 400px;

  padding: 16px;
  border-radius: 16px;
  border: 1px solid ${theme("color.grayLight")};
  background-color: ${theme("color.white")};
  box-shadow: 12px 12px 8px 0 #494a4729;
`;
