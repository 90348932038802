import React, { memo, useCallback, useMemo, useState } from "react";
import {
  Company,
  Document,
  Project,
  SmartContractStatus,
  SmartContractStatusName,
} from "@app/models";
import {
  companyLabelKeys,
  dateFormat,
  enumToArray,
  getDateFilterValue,
  getFilterValues,
  isDispatcher,
  isPartner,
} from "@app/helpers";
import { TemplateSearch } from "@app/templates";
import {
  getCompanies,
  getContractDocuments,
  getMyCompanies,
  getMyProjects,
  getProjects,
  PageableParams,
  PageableResponse,
} from "@app/api";
import { ModalContractDocDetail } from "@app/modals";
import {
  Combobox,
  DateTimePicker,
  DefaultObject,
  Select,
  SelectOption,
} from "@app/components";
import { useUser } from "@app/providers";
import { useDebounce } from "@app/hooks";
import { IconCheck20 } from "@app/icons";

const FILTER_STORAGE_KEY = "contractDocumentsFilters";

interface FilterParamsProps {
  companies: Company[] | null;
  partners: Company[] | null;
  projects: Project[] | null;
  statuses: DefaultObject<SmartContractStatus>[] | null;
  startDate: Date | null;
  endDate: Date | null;
  isContractAccepted: SelectOption<boolean> | null;
}

interface FilterValuesProps {
  CustomerIds: string[];
  PartnerIds: string[];
  ProjectIds: string[];
  StatusIds: (string | number)[];
  StartDate?: string;
  EndDate?: string;
  IsContractAccepted?: boolean;
}

const initialFiltersParams = JSON.stringify({
  companies: null,
  partners: null,
  projects: null,
  statuses: null,
  startDate: null,
  endDate: null,
  isContractAccepted: null,
});

const yesNoOptions: SelectOption<boolean>[] = [
  {
    value: true,
    label: "Да",
  },
  {
    value: false,
    label: "Нет",
  },
];

function ContractDocuments() {
  const { user } = useUser();
  const isUserPartner = user && isPartner(user.role);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [documentSelected, setDocumentSelected] = useState<Document | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<FilterParamsProps>(() => {
    const savedFilters = localStorage.getItem(FILTER_STORAGE_KEY);
    return savedFilters
      ? JSON.parse(savedFilters)
      : JSON.parse(initialFiltersParams);
  });

  const tableLabels = useMemo(
    () =>
      [
        "Наименование документа",
        isUserPartner ? "" : "Принят заказчиком",
        "Заказчик",
        "Проект",
        "Исполнитель/Контрагент",
        "Дата начала",
        "Дата завершения",
        "Статус",
      ].filter(Boolean),
    [isUserPartner]
  );

  const mapTableData = useCallback(
    (item: Document) => {
      return [
        item.name,
        !isUserPartner && item.isContractAccepted ? <IconCheck20 /> : <span />,
        item.company.name || "-",
        item.project.name,
        item.partner?.name || "-",
        item.startDate ? dateFormat(item.startDate, "dd.MM.yyyy") : "-",
        item.endDate ? dateFormat(item.endDate, "dd.MM.yyyy") : "-",
        SmartContractStatusName[item.status],
      ].filter(Boolean);
    },
    [isUserPartner]
  );

  const onClickItem = useCallback((data: any) => {
    setDocumentSelected(data);
    setModalVisible(true);
  }, []);

  const getData = useCallback(
    (params: PageableParams) => {
      if (loading) {
        return new Promise<PageableResponse<Document>>(() => []);
      }
      return getContractDocuments(params);
    },
    [loading]
  );

  const closeModal = useCallback(() => {
    setDocumentSelected(null);
    setModalVisible(false);

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, []);

  const onFilterChange = useCallback(
    (value: any, key: string) => {
      console.log({ key, value });
      const updatedFilters = { ...filterParams, [key]: value };
      setFilterParams(updatedFilters);
      localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(updatedFilters));
    },
    [filterParams]
  );

  const onFilterClear = useCallback(() => {
    const clearedFilters = JSON.parse(initialFiltersParams);
    setFilterParams(clearedFilters);
    localStorage.removeItem(FILTER_STORAGE_KEY);
  }, []);

  const filtersValue = useDebounce(
    useMemo(
      (): FilterValuesProps => ({
        CustomerIds: getFilterValues(filterParams.companies),
        PartnerIds: getFilterValues(filterParams.partners),
        ProjectIds: getFilterValues(filterParams.projects),
        StatusIds: getFilterValues(filterParams.statuses),
        StartDate: getDateFilterValue(filterParams.startDate),
        EndDate: getDateFilterValue(filterParams.endDate),
        IsContractAccepted: filterParams.isContractAccepted?.value,
      }),
      [filterParams]
    ),
    600
  );

  const filters = useMemo(
    () => [
      <Combobox<Company>
        label="Организация"
        name="companies"
        values={filterParams.companies}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
        onChange={onFilterChange}
      />,
      <Combobox<Company>
        label="Контрагент"
        name="partners"
        onChange={onFilterChange}
        values={filterParams.partners}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
      />,
      <Combobox<Project>
        label="Проект"
        name="projects"
        values={filterParams.projects}
        loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
        onChange={onFilterChange}
      />,
      <Combobox<DefaultObject<SmartContractStatus>>
        label="Статус"
        name="statuses"
        values={filterParams.statuses}
        options={enumToArray(SmartContractStatusName)}
        onChange={onFilterChange}
      />,
      <DateTimePicker
        label="Дата начала"
        name="startDate"
        onChange={onFilterChange}
        value={filterParams.startDate}
        hideTime
      />,
      <DateTimePicker
        label="Дата завершения"
        name="endDate"
        onChange={onFilterChange}
        value={filterParams.endDate}
        hideTime
      />,
      !isUserPartner && (
        <Select
          label="Договор принят"
          name="isContractAccepted"
          onChange={onFilterChange}
          value={filterParams.isContractAccepted}
          options={yesNoOptions}
        />
      ),
    ],
    [
      filterParams.companies,
      filterParams.endDate,
      filterParams.isContractAccepted,
      filterParams.partners,
      filterParams.projects,
      filterParams.startDate,
      filterParams.statuses,
      isUserPartner,
      onFilterChange,
      user,
    ]
  );

  return (
    <>
      <TemplateSearch
        toolbarProps={{
          onFilter: () => {},
          searchPlaceholder: "Наименование документа, организация, проект",
        }}
        filterProps={{
          filters,
          filterParams: filtersValue,
          onClear: onFilterClear,
        }}
        getData={getData}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        onClick={onClickItem}
      />
      <ModalContractDocDetail
        open={modalVisible}
        contractId={documentSelected?.sourceId || null}
        size={"medium"}
        onClose={closeModal}
      />
    </>
  );
}

export default memo(ContractDocuments);
