import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AlertBox,
  Button,
  Checkbox,
  Chip,
  DocFile,
  Grid,
  Tooltip,
} from "@app/components";
import {
  ActListItem,
  ActOfWork,
  ActOfWorkApprover,
  ActOfWorkApproverStatusName,
  ActOfWorkSigner,
  ActOfWorkStatus,
  ActOfWorkStatusName,
  FileObject,
} from "@app/models";
import { getAxiosErrorMessage, getFullName } from "@app/helpers";
import { IconInfo24, IconLinkBlank30, IconPrinter30 } from "@app/icons";
import { useNcaLayerClient, useNotification, useUser } from "@app/providers";
import { AxiosError } from "axios";
import {
  ActOfProvidedWorkDTO,
  APIResponse,
  approveActOfProvidedWork,
  changeActOfProvidedWorkStatus,
  createActOfProvidedWork,
  getActOfWorksById,
  getCompanySigners,
  getFileById,
  getSingingDocumentBase64,
  signActOfProvidedWork,
} from "@app/api";
import { ModalActOfWorkRedirect, ModalRevokeOrReject } from "@app/modals";
import { PreviewModal } from "@app/common";
import {
  StyledBox,
  StyledBoxStatus,
  StyledBoxText,
  StyledBoxTitle,
  StyledContent,
  StyledFileName,
  StyledFooter,
  StyledRow,
  StyledStatus,
  StyledStep,
} from "../styled";

interface Props {
  act: ActListItem | null;
  actOfWork: ActOfWork;
  disabled: boolean;
  setActOfWork: Dispatch<SetStateAction<ActOfWork | null>>;
  onChange: Dispatch<SetStateAction<number>>;
}

type modalOptions = {
  title: string;
  btnText: string;
  message: string;
};

const statusChangeModalOptions = {
  APPROVE: {
    title: "Согласовать АВР",
    btnText: "Согласовать",
    message: "АВР согласован",
  },
  REJECT: {
    title: "Отклонить АВР",
    btnText: "Отклонить",
    message: "АВР отклонен",
  },
  REVOKE: {
    title: "Отозвать АВР",
    btnText: "Отозвать",
    message: "АВР отозван",
  },
};

const rejectedStatuses = [ActOfWorkStatus.Rejected, ActOfWorkStatus.Revoked];

export default function (props: Props) {
  const {
    onChange: propsOnChange,
    act,
    actOfWork,
    disabled,
    setActOfWork,
  } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const { isRunning, signCMS } = useNcaLayerClient();
  const [, setPending] = useState<boolean>(false);
  const [statusChanging, setStatusChanging] = useState<modalOptions>();
  const [signing, setSigning] = useState<boolean>(false);
  const [canRevokeOrReject, setCanRevokeOrReject] = useState<boolean>(false);
  const [canSign, setCanSign] = useState<boolean>(false);
  const [isRecipient, setIsRecipient] = useState<boolean>(false);
  const [conditionAccepted, setConditionAccepted] = useState<boolean>(disabled);
  // file preview
  const [currentFile, setCurrentFile] = useState<Blob | null>(null);
  const [loading, setLoading] = useState(false);
  const [showRedirectModal, setShowRedirectModal] = useState(false);

  const isAvrRejected =
    !!actOfWork.statusId && rejectedStatuses.includes(actOfWork.statusId);

  const refreshActOfWork = useCallback(async () => {
    try {
      setPending(true);

      const res = await getActOfWorksById(actOfWork.id);
      setActOfWork(res.data);

      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [actOfWork, setActOfWork, showNotification]);

  const onChangeConditionAccept = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setConditionAccepted(e.target.checked);
    },
    []
  );

  const onClickBack = useCallback(() => {
    propsOnChange(2);
  }, [propsOnChange]);

  const onCloseModalStatusChanging = useCallback(() => {
    setStatusChanging(undefined);
  }, []);

  const changeStatusActOfWork = useCallback(
    (comment: string): Promise<void> => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true);
          if (statusChanging === statusChangeModalOptions.APPROVE) {
            await approveActOfProvidedWork({
              id: actOfWork.id,
              isApproved: true,
              comment,
            });

            setActOfWork({
              ...actOfWork,
              statusId: ActOfWorkStatus.OnRecipientSigning,
            });
          } else {
            const isRejecting =
              statusChanging === statusChangeModalOptions.REJECT;

            const statusId = isRejecting
              ? ActOfWorkStatus.Rejected
              : ActOfWorkStatus.Revoked;

            await changeActOfProvidedWorkStatus({
              id: actOfWork.id,
              comment,
              statusId,
            });

            setActOfWork({
              ...actOfWork,
              statusId,
            });
          }
          setLoading(false);

          showNotification({
            message: statusChanging?.message || "Успешно",
            variant: "success",
          });

          resolve();
        } catch (e) {
          setLoading(false);
          showNotification({
            message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
            variant: "error",
          });

          reject(e);
        }
      });
    },
    [actOfWork, statusChanging, setActOfWork, showNotification]
  );

  const onClickReject = useCallback(async () => {
    setStatusChanging(statusChangeModalOptions.REJECT);
  }, []);

  const onClickRevoke = useCallback(async () => {
    setStatusChanging(statusChangeModalOptions.REVOKE);
  }, []);

  const onClickApprove = useCallback(async () => {
    setStatusChanging(statusChangeModalOptions.APPROVE);
  }, []);

  const onRedirectModalClose = useCallback(() => {
    setShowRedirectModal(false);
  }, []);

  const openRedirectModal = useCallback(() => {
    setShowRedirectModal(true);
  }, []);

  /*const onClickSigning = useCallback(async () => {
    try {
      setPending(true);

      await changeActOfProvidedWorkStatus({
        id: actOfWork.id,
        statusId: ActOfWorkStatus.OnSenderSigning,
      });

      setActOfWork({
        ...actOfWork,
        statusId: ActOfWorkStatus.OnSenderSigning,
      });

      showNotification({
        message: "АВР отправлен на подписание",
        variant: "success",
      });

      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [actOfWork, setActOfWork, showNotification]);*/

  const onClickSign = useCallback(async () => {
    if (!isRunning) {
      return;
    }

    setSigning(true);

    await refreshActOfWork();

    let fileUrl = actOfWork.files[0].file.url;

    // выбираем отправителя с подписанным ранее файл (если есть)
    const signer = actOfWork.signers.filter(
      (item) => !item.isRecipient && item.signFile && item.signFile.url
    );
    if (isRecipient && signer.length && signer[0]?.signFile?.url) {
      fileUrl = signer[0].signFile.url;
    }

    try {
      const documentBase64 = await getSingingDocumentBase64(fileUrl);
      const signedFiles = await signCMS([documentBase64]);

      const response = await signActOfProvidedWork({
        avrId: actOfWork.id,
        signedFile: signedFiles[0],
        isRecipient,
      });

      setActOfWork(response.data);

      showNotification({
        message: "АВР успешно подписан",
        variant: "success",
      });

      setSigning(false);
    } catch (e) {
      if (typeof e !== "string") {
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
      setSigning(false);
    }
  }, [
    isRunning,
    signCMS,
    actOfWork.files,
    actOfWork.id,
    actOfWork.signers,
    isRecipient,
    refreshActOfWork,
    setActOfWork,
    showNotification,
  ]);

  const checkSigners = useCallback(async () => {
    try {
      setLoading(true);
      const signers = await Promise.all([
        getCompanySigners(actOfWork.senderCompany.id),
        getCompanySigners(actOfWork.recipientCompany.id),
      ]);

      const isRecipientSigner = !!signers[1].data.find(
        (item) => item.id === user!.id
      );

      const isRecipientSigned = actOfWork.signers.find(
        (item) => item.isRecipient && item.isSigned
      );
      const isSenderSigned = actOfWork.signers.find(
        (item) => !item.isRecipient && item.isSigned
      );

      const signersIds = signers
        .map((item) => item.data.map((item) => item.id))
        .reduce((a, b) => a.concat(b));

      setCanRevokeOrReject(signersIds.indexOf(user!.id) > -1);
      setIsRecipient(isRecipientSigner);
      setCanSign(
        signersIds.indexOf(user!.id) > -1 &&
          (isRecipientSigner ? !isRecipientSigned : !isSenderSigned)
      );

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [
    actOfWork.recipientCompany.id,
    actOfWork.senderCompany.id,
    actOfWork.signers,
    user,
  ]);

  const renderSignerStatus = useCallback(
    (item: ActOfWorkSigner) => {
      if (actOfWork.statusId === ActOfWorkStatus.Revoked && !item.isRecipient) {
        return (
          <Chip
            text={ActOfWorkStatusName[ActOfWorkStatus.Revoked]}
            color="danger"
          />
        );
      }

      if (actOfWork.statusId === ActOfWorkStatus.Rejected && item.isRecipient) {
        return (
          <Chip
            text={ActOfWorkStatusName[ActOfWorkStatus.Rejected]}
            color="danger"
          />
        );
      }

      if (item.isSigned) {
        return (
          <Chip
            text={
              item.isRecipient ? "Подписан заказчиком" : "Подписан исполнителем"
            }
            color="success"
          />
        );
      }

      return <Chip text="Ожидает подписания" color="warning" />;
    },
    [actOfWork]
  );

  const renderApproverStatus = useCallback(
    (isApproved: ActOfWorkApprover["isApproved"]) => {
      if (
        (isRecipient &&
          actOfWork?.statusId &&
          [
            ActOfWorkStatus.Created,
            ActOfWorkStatus.Updated,
            ActOfWorkStatus.OnSenderSigning,
          ].includes(actOfWork.statusId)) ||
        loading
      ) {
        return "—";
      }

      if (isApproved === true) {
        return <Chip text={ActOfWorkApproverStatusName[1]} color="success" />;
      } else if (isApproved === false) {
        return <Chip text={ActOfWorkApproverStatusName[2]} color="danger" />;
      }
      return <Chip text={ActOfWorkApproverStatusName[0]} color="warning" />;
    },
    [actOfWork.statusId, isRecipient, loading]
  );

  const getRejectReason = useCallback(() => {
    const rejectedItems = actOfWork.histories.filter(
      (item) => item.statusId === ActOfWorkStatus.Rejected
    );

    if (rejectedItems.length === 0) {
      return "";
    }

    let latestRejected = rejectedItems.reduce((latest, current) => {
      let currentTimestamp = new Date(current.created).getTime();
      let latestTimestamp = new Date(latest.created).getTime();

      return currentTimestamp > latestTimestamp ? current : latest;
    });

    return latestRejected.comment;
  }, [actOfWork.histories]);

  const getRevokeReason = useCallback(() => {
    const revokedItems = actOfWork.histories.filter(
      (item) => item.statusId === ActOfWorkStatus.Revoked
    );

    if (revokedItems.length === 0) {
      return "";
    }

    let latestRejected = revokedItems.reduce((latest, current) => {
      let currentTimestamp = new Date(current.created).getTime();
      let latestTimestamp = new Date(latest.created).getTime();

      return currentTimestamp > latestTimestamp ? current : latest;
    });

    return latestRejected.comment;
  }, [actOfWork.histories]);

  useEffect(() => {
    if (actOfWork.signers.filter((item) => item.isSigned).length < 2) {
      checkSigners().then(() => {});
    }
  }, [actOfWork.signers, checkSigners]);

  const currentApprover = useMemo(() => {
    return actOfWork.approvers?.find((item) => item.userId === user?.id);
  }, [actOfWork.approvers, user?.id]);

  const showDocument = useCallback(
    async (file: FileObject) => {
      try {
        setLoading(true);
        const res = await getFileById(file.id);
        setCurrentFile(res);
        setLoading(false);
      } catch (e) {
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
        setLoading(false);
      }
    },
    [showNotification]
  );

  const openLink = (path: string) => () => {
    window.open(`/act-of-provided-services${path}`, "_blank");
  };

  const onAvrCreate = useCallback(async () => {
    try {
      setPending(true);

      const data: ActOfProvidedWorkDTO = {
        senderCompanyId: actOfWork.senderCompany.id,
        recipientCompanyId: actOfWork.recipientCompany.id,
        projectId: actOfWork.project.id,
        actId: actOfWork.actId,
        registrationNumber: actOfWork.registrationNumber,
        date: actOfWork.date ?? "",
        startDate: actOfWork.startDate,
        endDate: actOfWork.endDate,
        contractId: actOfWork.contract.id,
        senderCompanyAddress: actOfWork.senderCompanyAddress,
        recipientCompanyAddress: actOfWork.recipientCompanyAddress,
        ndsRate: actOfWork.ndsRate ?? "",
        basisId: actOfWork.basis?.id ?? "",
        invoiceNumber: "",
        nomenclatures: actOfWork.nomenclatures.map((item) => ({
          price: item.price ?? 0,
          sum: item.sum ?? 0,
          sumNds: item.sumNds ?? 0,
          quantity: item.quantity ?? 0,
          unitId: item.unit.id,
          name: item.name,
          executionDate: item.executionDate,
        })),
      };

      const response = await createActOfProvidedWork(data);
      setPending(false);

      if (response.succeeded) {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("avrId", response.data.id);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.open(newUrl, "_self");

        showNotification({
          message: "АВР успешно создан",
          variant: "success",
        });
        return;
      }

      showNotification({
        variant: "error",
        message: response.message as string,
      });
    } catch (e) {
      setPending(false);
      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [actOfWork, showNotification]);

  return (
    <StyledStep>
      <StyledContent>
        {!!actOfWork && (
          <>
            {canSign &&
              [
                ActOfWorkStatus.Created,
                ActOfWorkStatus.Updated,
                ActOfWorkStatus.OnSenderSigning,
                ActOfWorkStatus.OnRecipientSigning,
              ].indexOf(actOfWork.statusId!) > -1 &&
              !isRunning && (
                <AlertBox
                  title="Внимание!"
                  description="NCALayer не запущен. Для подписания запустите ncaLayer и нажмите подписать"
                />
              )}
            {[
              ActOfWorkStatus.Signed,
              ActOfWorkStatus.OnRecipientApproval,
              ActOfWorkStatus.OnRecipientSigning,
            ].indexOf(actOfWork.statusId!) === -1 && (
              <StyledBox transparent={true}>
                <StyledBoxTitle>Сформированный АВР</StyledBoxTitle>
                {actOfWork.files.map((item, itemIndex) => (
                  <div key={itemIndex}>
                    <DocFile
                      file={item.file as FileObject}
                      loading={loading}
                      onPreview={showDocument}
                    />
                  </div>
                ))}
                {!disabled && (
                  <Checkbox
                    label="Ознакомлен(-а) с документом"
                    onChange={onChangeConditionAccept}
                    checked={conditionAccepted}
                  />
                )}
              </StyledBox>
            )}
            {!!actOfWork.signedFile &&
              actOfWork.statusId !== ActOfWorkStatus.Updated && (
                <StyledBox transparent={true}>
                  <StyledBoxTitle>Подписанный АВР</StyledBoxTitle>
                  {!!actOfWork.signedFile && (
                    <div>
                      <DocFile
                        file={actOfWork.signedFile}
                        loading={loading}
                        onPreview={showDocument}
                      />
                    </div>
                  )}
                </StyledBox>
              )}
            {!!actOfWork.actId && (
              <StyledBox transparent={true}>
                <StyledBoxTitle>
                  Согласованный табель №{act?.actNumber ?? ""}
                </StyledBoxTitle>
                <StyledRow>
                  <Button
                    text={"Открыть табель"}
                    variant={"outlined"}
                    startIcon={IconLinkBlank30}
                    onClick={openLink(`?id=${actOfWork.actId}`)}
                  />
                  <Button
                    text={"Печатная форма"}
                    variant={"outlined"}
                    startIcon={IconPrinter30}
                    onClick={openLink(`/printing/${actOfWork.actId}`)}
                  />
                </StyledRow>
              </StyledBox>
            )}
            {actOfWork.signers.map((item, itemIndex) => (
              <StyledBox key={itemIndex.toString()} transparent={false}>
                {disabled && (
                  <StyledStatus>{renderSignerStatus(item)}</StyledStatus>
                )}
                <StyledBoxTitle>
                  {item.isRecipient ? "Заказчик" : "Исполнитель"}
                </StyledBoxTitle>
                <StyledBoxText>
                  {item.isRecipient
                    ? actOfWork.recipientCompany?.name
                    : actOfWork.senderCompany?.name}
                </StyledBoxText>
                <StyledBoxText>
                  {item.isRecipient
                    ? actOfWork.recipientCompany?.bin || "-"
                    : actOfWork.senderCompany?.bin || "-"}
                </StyledBoxText>
                <StyledBoxText>
                  {!!item.user ? getFullName(item.user) : "-"}
                </StyledBoxText>
                {actOfWork.statusId === ActOfWorkStatus.Rejected &&
                  item.isRecipient && (
                    <StyledBoxText>
                      Причина отклонения: {getRejectReason()}
                    </StyledBoxText>
                  )}
                {actOfWork.statusId === ActOfWorkStatus.Revoked &&
                  !item.isRecipient && (
                    <StyledBoxText>
                      Причина отзыва: {getRevokeReason()}
                    </StyledBoxText>
                  )}
              </StyledBox>
            ))}
            {/* Согласующие */}
            <StyledBox transparent={false}>
              <StyledBoxTitle>Согласующие</StyledBoxTitle>
              <Grid columns={3}>
                <StyledFileName>ФИО</StyledFileName>
                <StyledFileName>Дата согласования</StyledFileName>
                <StyledFileName>Статус</StyledFileName>
                {actOfWork.approvers?.map((item, index) => (
                  <React.Fragment key={index.toString()}>
                    <StyledBoxText>{getFullName(item.user)}</StyledBoxText>
                    <StyledBoxText>{"—"}</StyledBoxText>
                    <StyledBoxStatus>
                      {renderApproverStatus(item.isApproved)}
                      {item.comment && (
                        <Tooltip content={item.comment} direction={"left"}>
                          <IconInfo24 />
                        </Tooltip>
                      )}
                    </StyledBoxStatus>
                  </React.Fragment>
                ))}
              </Grid>
            </StyledBox>
          </>
        )}
      </StyledContent>
      <StyledFooter>
        <Button
          text="Назад"
          disabled={disabled}
          onClick={onClickBack}
          variant="text"
        />
        {/*{!!actOfWork.statusId &&
          actOfWork.statusId <= ActOfWorkStatus.Updated && (
            <Button
              text="Отправить на подписание"
              onClick={onClickSigning}
              disabled={pending || !conditionAccepted}
              showLoader={pending}
            />
          )}*/}
        {!!actOfWork.statusId &&
          [ActOfWorkStatus.OnRecipientApproval].indexOf(actOfWork.statusId) >
            -1 &&
          !!currentApprover &&
          currentApprover.isApproved === null && (
            <>
              <Button
                text="Перенаправить"
                showLoader={loading}
                variant={"text"}
                onClick={openRedirectModal}
              />
              <Button
                text="Отклонить"
                showLoader={loading}
                variant="outlined"
                onClick={onClickReject}
              />
              <Button
                text="Согласовать"
                showLoader={loading}
                onClick={onClickApprove}
              />
            </>
          )}
        {!!actOfWork.statusId &&
          [
            ActOfWorkStatus.OnSenderSigning,
            ActOfWorkStatus.OnRecipientSigning,
          ].indexOf(actOfWork.statusId) > -1 &&
          canRevokeOrReject &&
          !isRecipient && (
            <Button
              text="Отозвать"
              onClick={onClickRevoke}
              showLoader={loading}
              variant="outlined"
            />
          )}
        {canSign &&
          [
            ActOfWorkStatus.Created,
            ActOfWorkStatus.Updated,
            ActOfWorkStatus.OnSenderSigning,
            ActOfWorkStatus.OnRecipientSigning,
          ].indexOf(actOfWork.statusId!) > -1 && (
            <>
              <Button
                text="Отклонить"
                showLoader={loading}
                variant="outlined"
                onClick={onClickReject}
              />

              <Tooltip
                content={
                  conditionAccepted
                    ? ""
                    : "Необходимо ознакомиться с документом"
                }
                direction={"left"}
              >
                <Button
                  text="Подписать"
                  onClick={onClickSign}
                  disabled={!isRunning || signing || !conditionAccepted}
                  showLoader={signing}
                />
              </Tooltip>
            </>
          )}
        {isAvrRejected && (
          <Button
            text="Создать АВР"
            disabled={signing}
            showLoader={signing}
            onClick={onAvrCreate}
          />
        )}
      </StyledFooter>
      <ModalRevokeOrReject
        open={!!statusChanging}
        title={statusChanging?.title}
        onClose={onCloseModalStatusChanging}
        onSubmit={changeStatusActOfWork}
        buttonText={statusChanging?.btnText || ""}
      />
      <ModalActOfWorkRedirect
        open={showRedirectModal}
        title={"Перенаправление акта"}
        avrId={actOfWork.id}
        companyId={actOfWork.recipientCompany.id}
        onClose={onRedirectModalClose}
      />
      <PreviewModal
        open={!!currentFile}
        file={currentFile}
        onClose={() => setCurrentFile(null)}
      />
    </StyledStep>
  );
}
