import { Dictionary, FileObject } from "./Default";
import { SmartContract } from "./SmartContract";
import { Project } from "./Project";
import { CompanyShort } from "./Company";

export interface ActOfWorkUser {
  id: string;
  lastname: string;
  firstname: string;
  middlename: string;
  phone: string;
}

export interface ActOfWorkSigner {
  avrId: string;
  user: ActOfWorkUser;
  signed: string;
  isSigned: boolean;
  comment: string;
  isRecipient: boolean;
  created: string;
  signFile: FileObject | null;
}

export interface ActOfWorkApprover {
  avrId: string;
  user: ActOfWorkUser;
  userId: string;
  isApproved: boolean | null;
  comment: string | null;
}

export interface ActOfWorkHistory {
  avrId: string;
  statusId: number;
  user: ActOfWorkUser;
  comment: string;
  created: string;
}

export interface ActOfWorkNomenclature {
  avrId: string;
  name: string;
  executionDate: string;
  quantity: number | null;
  price: number | null;
  sum: number | null;
  sumNds: number | null;
  unit: Dictionary;
}

export interface ActOfWorkFile {
  avrId: string;
  isSigned: boolean;
  file: {
    name: string;
    url: string;
  };
}

export interface ActOfWork {
  actId: string;
  actNumber: string;
  approvers?: ActOfWorkApprover[];
  basis: Dictionary | null;
  contract: SmartContract;
  createdDate: string;
  date: string | null;
  startDate: string;
  endDate: string;
  files: ActOfWorkFile[];
  histories: ActOfWorkHistory[];
  id: string;
  initiatorName: string;
  invoiceNumber: string;
  ndsRate: string | null;
  nomenclatures: ActOfWorkNomenclature[];
  number: number | null;
  project: Project;
  registrationNumber: string;
  recipientCompanyAddress: string;
  recipientCompany: CompanyShort;
  senderCompanyAddress: string;
  senderCompany: CompanyShort;
  signedFile: FileObject | null;
  signers: ActOfWorkSigner[];
  statusId: ActOfWorkStatus | null;
  totalQuantity: number | null;
  totalSum: number;
  totalSumNds: number;
}

export enum ActOfWorkStatus {
  Created = 10,
  Updated = 15,
  OnSenderSigning = 20,
  OnRecipientApproval = 30,
  OnRecipientSigning = 40,
  Signed = 50,
  Revoked = 60,
  Rejected = 70,
}

export const ActOfWorkStatusName: Record<ActOfWorkStatus, string> = {
  [ActOfWorkStatus.Created]: "Создан АВР",
  [ActOfWorkStatus.Updated]: "АВР редактирован",
  [ActOfWorkStatus.OnSenderSigning]: "АВР на подписании у контрагента",
  [ActOfWorkStatus.OnRecipientApproval]: "АВР на согласовании у проекта",
  [ActOfWorkStatus.OnRecipientSigning]: "АВР на подписании у проекта",
  [ActOfWorkStatus.Signed]: "АВР подписан обоюдно",
  [ActOfWorkStatus.Revoked]: "Отозван АВР",
  [ActOfWorkStatus.Rejected]: "Подписание АВР отклонено",
};

export const ActOfWorkApproverStatusName: Record<number, string> = {
  0: "На согласовании",
  1: "Согласовано",
  2: "Отклонено",
};
