import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { dateFormat, getFullName } from "@app/helpers";
import { Table } from "@app/components";
import {
  Act,
  ActSignerDTO,
  Task,
  UserTaskStatusesName,
  UserTaskTypeName,
} from "@app/models";
import { getActById } from "@app/api";
import { ActHistory } from "@app/common";

interface Props {
  actId?: Task["id"] | Act["id"];
}

const ActApprovalList = (props: Props) => {
  const { actId } = props;
  const [signers, setSigners] = useState<ActSignerDTO[]>([]);

  const getTableData = useCallback(async (id: string) => {
    const response = await getActById(id);
    if (response.data && response.data.actSignersDto) {
      const sorted = response.data.actSignersDto.sort(
        (a, b) => a.queue - b.queue
      );
      setSigners(sorted);
    }
  }, []);

  useEffect(() => {
    if (!actId) return;
    getTableData(actId).then(() => {});
  }, [actId, getTableData]);

  const tableLabels = useMemo(
    () => [
      "Вид задачи",
      "ФИО ответственного",
      "Результат",
      "Комментарий",
      "Срок",
      "Дата выполнения",
    ],
    []
  );
  const mapTableData = useCallback((item: ActSignerDTO) => {
    const format = "dd.MM.yyyy HH:mm:ss";
    let modifiedDate = item.lastModified;
    if (!modifiedDate && item.termDate !== "0001-01-01T00:00:00") {
      modifiedDate = item.termDate;
    }
    return [
      UserTaskTypeName[item.taskTypeId],
      item.userShort ? getFullName(item.userShort) : item.fullName,
      UserTaskStatusesName[item.status],
      item.comment || "—",
      <span title={dateFormat(item.created, format)}>
        {dateFormat(item.created, "dd.MM.yyyy")}
      </span>,
      <span title={dateFormat(modifiedDate, format)}>
        {dateFormat(modifiedDate, "dd.MM.yyyy")}
      </span>,
    ];
  }, []);

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => signers.map(mapTableData),
    [signers, mapTableData]
  );

  return (
    <div>
      <Table labels={tableLabels} onClick={() => {}} data={tableData} />
      <hr />
      {/* История согласования */}
      <ActHistory actId={actId} />
    </div>
  );
};
export default memo(ActApprovalList);
