import { useEffect, useMemo } from "react";
import {
  IconBag24,
  IconBag36,
  IconBagFilled24,
  IconClock24,
  IconClock36,
  IconClockFilled24,
  IconDocument24,
  IconDocument36,
  IconDocumentChecked24,
  IconDocumentCheckedFilled24,
  IconDocumentEdit24,
  IconDocumentEditFilled24,
  IconDocumentFilled24,
  IconFile24,
  IconFileChecked24,
  IconFileChecked36,
  IconFileCheckedFilled24,
  IconFileFilled24,
  IconHome24,
  IconHomeFilled24,
  IconListChecked24,
  IconListCheckedFilled24,
  IconLocation24,
  IconLocation36,
  IconLocationFilled24,
  IconReport36,
  IconTasks36,
  IconTyre24,
  IconTyre36,
  IconTyreFilled24,
  SVGIconType,
} from "@app/icons";
import { isDispatcher } from "@app/helpers";
import { useUser } from "@app/providers";
import { useLocation } from "react-router-dom";
import {
  ImgActs,
  ImgAvr,
  ImgContracts,
  ImgDictionaries,
  ImgDocs,
  ImgMonitoring,
  ImgReports,
  ImgTasks,
  ImgUchet,
  ImgVehicle,
} from "../assets/images/main";

export interface IMainMenuItem {
  title: string;
  path: string;
  bg: string;
  icon: SVGIconType;
  iconLarge: SVGIconType;
  activeIcon: SVGIconType;
  badge?: number;
}

type ReturnType = {
  routes: IMainMenuItem[];
  routesForMainPage: IMainMenuItem[];
};

export function useMenuList(): ReturnType {
  const { asPartner, user, userCounts, getUserCounts } = useUser();

  const location = useLocation();

  useEffect(() => {
    getUserCounts();
  }, [getUserCounts, location]);

  const mainPageRoute = useMemo(
    () => ({
      title: "Главная",
      path: "/main",
      bg: "/acts.png",
      icon: IconHome24,
      iconLarge: IconHome24,
      activeIcon: IconHomeFilled24,
    }),
    []
  );

  const sharedRouters = useMemo(
    () => [
      {
        title: "Журнал учета техники (наряд)",
        path: "/vehicle-accounting/list/by-dates",
        bg: ImgUchet,
        icon: IconClock24,
        iconLarge: IconClock36,
        activeIcon: IconClockFilled24,
      },
      {
        title: "Табель (расшифровка оказанных услуг)",
        path: "/act-of-provided-services",
        bg: ImgActs,
        icon: IconFileChecked24,
        iconLarge: IconFileChecked36,
        activeIcon: IconFileCheckedFilled24,
      },
      {
        title: "Акт выполненных работ (Р-1)",
        path: "/act-of-provided-works",
        bg: ImgAvr,
        icon: IconDocumentEdit24,
        iconLarge: IconFileChecked36,
        activeIcon: IconDocumentEditFilled24,
      },
      {
        title: "Мои задачи",
        path: "/tasks/contracts",
        bg: ImgTasks,
        icon: IconListChecked24,
        iconLarge: IconTasks36,
        activeIcon: IconListCheckedFilled24,
        badge: userCounts?.allCount,
      },
      {
        title: "Мои документы",
        path: "/documents/contracts",
        bg: ImgDocs,
        icon: IconBag24,
        iconLarge: IconBag36,
        activeIcon: IconBagFilled24,
      },
      {
        title: "Мониторинг",
        path: "/monitoring/vehicles",
        bg: ImgMonitoring,
        icon: IconLocation24,
        iconLarge: IconLocation36,
        activeIcon: IconLocationFilled24,
      },
    ],
    [userCounts]
  );

  const routes = useMemo(() => {
    let routesToReturn = [];

    if (!user) {
      return [];
    }

    if (asPartner) {
      routesToReturn = [
        mainPageRoute,
        {
          title: "Мои организации",
          path: "/organizations",
          bg: ImgActs,
          icon: IconBag24,
          iconLarge: IconBag36,
          activeIcon: IconBagFilled24,
        },
        {
          title: "Моя техника",
          path: "/vehicles",
          bg: ImgVehicle,
          icon: IconTyre24,
          iconLarge: IconTyre36,
          activeIcon: IconTyreFilled24,
        },
        {
          title: "Заказ на технику",
          path: "/vehicle-requests/list",
          bg: ImgVehicle,
          icon: IconFile24,
          iconLarge: IconDocument36,
          activeIcon: IconFileFilled24,
        },
        ...sharedRouters,
      ];

      return routesToReturn;
    }

    routesToReturn = [
      mainPageRoute,
      {
        title: "Заказ техники",
        path: "/vehicles-in-project/requests",
        bg: ImgVehicle,
        icon: IconTyre24,
        iconLarge: IconTyre36,
        activeIcon: IconTyreFilled24,
      },
      ...sharedRouters,
      {
        title: "Отчеты",
        path: "/reports",
        bg: ImgReports,
        icon: IconDocumentChecked24,
        iconLarge: IconReport36,
        activeIcon: IconDocumentCheckedFilled24,
      },
    ];

    if (isDispatcher(user!.role)) {
      routesToReturn.push(
        {
          title: "Справочная информация",
          path: "/dictionaries",
          bg: ImgDictionaries,
          icon: IconClock24,
          iconLarge: IconClock36,
          activeIcon: IconClockFilled24,
        },
        {
          title: "SMART-договоры",
          path: "/smart-contracts",
          bg: ImgContracts,
          icon: IconDocument24,
          iconLarge: IconDocument36,
          activeIcon: IconDocumentFilled24,
        }
      );
    }

    return routesToReturn;
  }, [asPartner, mainPageRoute, sharedRouters, user]);

  const routesForMainPage = routes.filter(
    (item) => !["/dictionaries", "/main"].includes(item.path)
  );

  return { routes, routesForMainPage };
}
