import React, { memo, useCallback, useEffect, useState } from "react";
import {
  DateTimePicker,
  Form,
  Grid,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import * as yup from "yup";
import {
  Characteristic,
  Company,
  Project,
  ServiceType,
  Unit,
  VehicleRequestType,
  VehicleType,
  WorkShift,
} from "@app/models";
import {
  APIResponse,
  createVehicleRequest,
  getCharacteristics,
  getCompaniesFiltered,
  getMyCompanies,
  getMyProjects,
  getProjects,
  getServiceTypes,
  getUnits,
  getVehicleTypes,
  getWorkShifts,
  PageableParams,
} from "@app/api";
import { AxiosError } from "axios";
import { useNotification, useUser } from "@app/providers";
import { getAxiosErrorMessage, isDispatcher } from "@app/helpers";

interface Props {
  onSuccess: () => void;
}

interface Data {
  organization: SelectOption<Company> | null;
  project: SelectOption<Project> | null;
  vehicleType: SelectOption<VehicleType> | null;
  serviceType: SelectOption<ServiceType> | null;
  unit: SelectOption<Unit> | null;
  characteristic: SelectOption<Characteristic> | null;
  workShift: SelectOption<WorkShift> | null;
  quantity: string;
  volume: string;
  startDate: Date | null;
  endDate: Date | null;
  comment: string;
}

const schema = yup.object().shape({
  organization: yup.object().nullable().required("required"),
  project: yup.object().nullable().required(),
  vehicleType: yup.object().nullable().required(),
  serviceType: yup.object().nullable().required(),
  unit: yup.object().nullable().required(),
  quantity: yup.string().required(),
  volume: yup.string().required(),
  startDate: yup.date().nullable().required(),
  endDate: yup.date().nullable().required(),
});

const initialParams = {
  pageNumber: 1,
  pageSize: 10000,
  searchText: "",
};

function FormVehicleRequest(props: Props) {
  const { onSuccess } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const [companies, setCompanies] = useState<SelectOption<any>[]>([]);
  const [myCompanies, setMyCompanies] = useState<SelectOption<any>[]>([]);
  const { pending, onChange, values, errors, validate, setPending } =
    useForm<Data>({
      values: {
        organization: null,
        project: null,
        vehicleType: null,
        serviceType: null,
        unit: null,
        characteristic: null,
        workShift: null,
        quantity: "",
        volume: "",
        startDate: null,
        endDate: null,
        comment: "",
      },
      schema,
    });

  const onVehicleTypeClear = useCallback(() => {
    onChange(null, "characteristic");
    onChange(null, "serviceType");
  }, [onChange]);

  const onCharacteristicClear = useCallback(() => {
    onChange(null, "characteristic");
    onChange(null, "serviceType");
  }, [onChange]);

  const loadCharacteristics = useCallback(
    (params: PageableParams<Characteristic>) => {
      return getCharacteristics({
        ...params,
        typeIds: [
          VehicleRequestType.ALL_CHARACTERISTICS,
          VehicleRequestType.REQUEST_CREATION,
        ],
        vehicleTypeId: values.vehicleType!.value,
      });
    },
    [values.vehicleType]
  );

  const loadServiceTypes = useCallback(
    (params: PageableParams<ServiceType>) => {
      return getServiceTypes({
        ...params,
        characteristicId: values.characteristic!.value,
      });
    },
    [values.characteristic]
  );

  const loadUnits = useCallback(
    (params: PageableParams<Unit>) => {
      return getUnits({
        ...params,
        serviceTypeId: values.serviceType!.value,
      });
    },
    [values.serviceType]
  );

  const loadCompanies = useCallback(async (params: any) => {
    try {
      const response = await getCompaniesFiltered(params);

      const formattedData = response.data.map((company: any) => {
        return {
          value: company.id,
          label:
            `${company.name} ${company.type?.name ?? ""}` +
            ` ${company.bin ? `(${company.bin})` : ""}`,
        };
      });

      setCompanies(formattedData);
    } catch (error) {
      console.error("Ошибка при загрузке списка компаний: ", error);
    }
  }, []);

  const loadMyCompanies = useCallback(async (params: any) => {
    try {
      const response = await getMyCompanies(params);

      const formattedData = response.data.map((company: any) => {
        return {
          value: company.id,
          label:
            `${company.name} ${company.type?.name ?? ""}` +
            ` ${company.bin ? `(${company.bin})` : ""}`,
        };
      });

      setMyCompanies(formattedData);
    } catch (error) {
      console.error("Ошибка при загрузке списка компаний: ", error);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await loadCompanies(initialParams);
      await loadMyCompanies(initialParams);
    })();
  }, [loadCompanies, loadMyCompanies]);

  const onSubmitForm = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      setPending(true);

      const response = await createVehicleRequest({
        organizationId: values.organization!.value,
        projectId: values.project!.value,
        vehicleTypeId: values.vehicleType!.value,
        quantity: values.quantity,
        serviceTypeId: values.serviceType!.value,
        volume: Number(values.volume),
        unitId: values.unit!.value,
        characteristicsId: values.characteristic!.value,
        workShiftId: values.workShift!.value,
        startDate: values.startDate!.toJSON(),
        endDate: values.endDate!.toJSON(),
        comments: values.comment,
      });

      setPending(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: "Заказ на технику добавлен",
        variant: "success",
      });

      onSuccess();
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [onSuccess, showNotification, values, setPending, validate]);

  return (
    <Form onSubmit={onSubmitForm} pending={pending} submitLabel={"Создать"}>
      <Grid>
        <Select<any>
          label="Организация"
          name="organization"
          onChange={onChange}
          value={values.organization}
          error={!!errors.organization}
          helperText={errors.organization}
          valueKey="id"
          labelKey="name"
          options={isDispatcher(user!.role) ? companies : myCompanies}
        />
        <Select<Project>
          label="Проект"
          name="project"
          onChange={onChange}
          value={values.project}
          error={!!errors.project}
          helperText={errors.project}
          valueKey="id"
          labelKey="name"
          loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
        />
        <Select<VehicleType>
          label="Вид техники"
          name="vehicleType"
          onChange={onChange}
          onClear={onVehicleTypeClear}
          value={values.vehicleType}
          error={!!errors.vehicleType}
          helperText={errors.vehicleType}
          valueKey="id"
          labelKey="name"
          loadData={getVehicleTypes}
        />
        <Grid columns={2}>
          <Select<Characteristic>
            label="Характеристика техники"
            name="characteristic"
            onChange={onChange}
            onClear={onCharacteristicClear}
            value={values.characteristic}
            error={!!errors.characteristic}
            helperText={errors.characteristic}
            valueKey="id"
            labelKey="name"
            loadData={loadCharacteristics}
            disabled={!values.vehicleType}
          />
          <Select<ServiceType>
            label="Вид услуги"
            name="serviceType"
            onChange={onChange}
            value={values.serviceType}
            error={!!errors.serviceType}
            helperText={errors.serviceType}
            valueKey="id"
            labelKey="name"
            disabled={!values.characteristic}
            loadData={loadServiceTypes}
          />
          <TextField
            label="Количество единиц техники"
            placeholder="Введите количество (макс. 10)"
            name="quantity"
            type="number"
            onChange={(value, name) => {
              const val = Math.max(1, Math.min(10, Number(value)));
              onChange(val.toString(), name);
            }}
            value={values.quantity}
            error={!!errors.quantity}
            helperText={errors.quantity}
            inputProps={{
              min: 1,
              max: 10,
            }}
          />
          <Select<Unit>
            label="Единица измерения работ"
            name="unit"
            onChange={onChange}
            value={values.unit}
            error={!!errors.unit}
            helperText={errors.unit}
            valueKey="id"
            labelKey="name"
            disabled={!values.serviceType}
            loadData={loadUnits}
          />
          <TextField
            label="Объём работ"
            placeholder="Введите объём"
            name="volume"
            type="number"
            onChange={onChange}
            value={values.volume}
            error={!!errors.volume}
            helperText={errors.volume}
            /*onBlur={() => {
              onChange(
                Math.ceil(Math.abs(+values.volume)).toString(),
                "volume"
              );
            }}*/
            inputProps={{
              min: 1,
            }}
          />
          <Select<WorkShift>
            label="Смена"
            name="workShift"
            onChange={onChange}
            value={values.workShift}
            error={!!errors.workShift}
            helperText={errors.workShift}
            valueKey="id"
            labelKey="name"
            loadData={getWorkShifts}
          />
          <DateTimePicker
            label="Дата начало"
            name="startDate"
            onChange={onChange}
            value={values.startDate}
            error={!!errors.startDate}
            helperText={errors.startDate}
          />
          <DateTimePicker
            label="Дата завершения"
            name="endDate"
            onChange={onChange}
            value={values.endDate}
            error={!!errors.endDate}
            helperText={errors.endDate}
            paperRight={true}
          />
        </Grid>
        <TextField
          label="Комментарий"
          placeholder="Введите текст"
          name="comment"
          maxLength={250}
          onChange={onChange}
          value={values.comment}
          error={!!errors.comment}
          helperText={errors.comment}
          multiline={true}
        />
      </Grid>
    </Form>
  );
}

export default memo(FormVehicleRequest);
