import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Dialog,
  Modal,
  ModalProps,
  ModalTitle,
  Switcher,
} from "@app/components";
import {
  VehicleRequest,
  VehicleRequestOffer,
  VehicleRequestStatus,
} from "@app/models";
import {
  getAxiosErrorMessage,
  isDispatcher,
  isPartner,
  isUser,
} from "@app/helpers";
import {
  APIResponse,
  confirmVehicleRequest,
  confirmVehicleRequestOffer,
  getVehicleRequest,
  rejectVehicleRequest,
} from "@app/api";
import { useNotification, useUser } from "@app/providers";
import { AxiosError } from "axios";
import {
  VehicleRequestDetail,
  VehicleRequestOffers,
  VehicleRequestStatusChip,
} from "@app/common";
import ModalVehicleRequestOffer from "../VehicleRequestOffer";
import { IconPrint16 } from "@app/icons";
import styled from "styled-components";
import { theme } from "styled-tools";
import {
  ModalVehicleOfferApproveOrReject,
  ModalVehicleOfferRedirect,
} from "../index";

const SwitcherContainer = styled.div`
  margin-right: auto;
  padding: 8px 16px;
  gap: 10px;
  border-radius: 100px;
  background-color: ${theme("color.yellowLight")};
  border: 1px solid ${theme("color.orangeLight")};
  //border-image-source: linear-gradient(90deg, #ed4a3f 20%, #f99335 80%);
`;

interface Props extends Omit<ModalProps, "title"> {
  data: Pick<VehicleRequest, "id" | "number" | "status"> | null;
  showOffers: boolean;
  updateData?: (item: VehicleRequest) => void;
}

function ModalVehicleRequestDetail(props: Props) {
  const { data, showOffers, updateData, ...modalProps } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const [request, setRequest] = useState<VehicleRequest | null>(null);
  const [offer, setOffer] = useState<VehicleRequestOffer | null>(null);
  const [pending, setPending] = useState(false);
  const [rejecting, setRejecting] = useState<boolean>(false);
  const [confirming, setConfirming] = useState<boolean>(false);
  const [sendToPartners, setSendToPartners] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [requestOfferModalVisible, setRequestOfferModalVisible] =
    useState(false);
  const [redirectModalVisible, setRedirectModalVisible] = useState(false);
  const [approveRejectModalVisible, setApproveRejectModalVisible] =
    useState(false);
  const [approveRejectModalProps, setApproveRejectModalProps] = useState<{
    isApproved: boolean;
    title: string;
  }>({ isApproved: false, title: "" });

  const onClickRedirect = useCallback(() => {
    setRedirectModalVisible(true);
  }, []);

  const closeRedirectModal = useCallback(() => {
    setRedirectModalVisible(false);
  }, []);

  const onClickReject = useCallback(() => {
    setApproveRejectModalProps({ isApproved: false, title: "Отклонение" });
    setApproveRejectModalVisible(true);
  }, []);

  const onClickApprove = useCallback(() => {
    setApproveRejectModalProps({ isApproved: true, title: "Согласование" });
    setApproveRejectModalVisible(true);
  }, []);

  const closeApproveRejectModal = useCallback(() => {
    setApproveRejectModalVisible(false);
  }, []);

  const onSelectOffer = useCallback((selectedOffer: VehicleRequestOffer) => {
    setOffer(selectedOffer);
  }, []);

  const onClickRejectAlreadyApproved = useCallback(async () => {
    try {
      setRejecting(true);

      const response = await rejectVehicleRequest({
        id: data!.id,
      });

      setRejecting(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: "Заявка на технику отклонена",
        variant: "success",
      });

      if (!!updateData) {
        updateData({
          ...request!,
          status: VehicleRequestStatus.Rejected,
        });
      }

      modalProps.onClose();
    } catch (e) {
      setRejecting(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [request, updateData, modalProps, showNotification, data]);

  const onClickConfirm = useCallback(async () => {
    try {
      setConfirming(true);

      const response = await confirmVehicleRequest({
        id: data!.id,
        sendToPartners,
      });

      setConfirming(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: "Заявка на технику подтверждена",
        variant: "success",
      });

      if (!!updateData) {
        updateData({
          ...request!,
          status: VehicleRequestStatus.AwaitingRequest,
        });
      }

      modalProps.onClose();
    } catch (e) {
      setConfirming(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [data, sendToPartners, showNotification, updateData, modalProps, request]);

  const onClickConfirmRequestOffer = useCallback(async () => {
    try {
      setConfirming(true);

      const response = await confirmVehicleRequestOffer(offer!.id);

      setConfirming(true);

      if (!response.succeeded) {
        showNotification({
          variant: "error",
          message: response.message,
        });

        return;
      }

      showNotification({
        message: "Ценовое предложение на технику выбрано",
        variant: "success",
      });

      if (!!updateData) {
        updateData({
          ...request!,
          status: VehicleRequestStatus.TermAgreement,
        });
      }

      modalProps.onClose();
    } catch (e) {
      setConfirming(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [modalProps, offer, request, showNotification, updateData]);

  const onClickAddOffer = useCallback(() => {
    setRequestOfferModalVisible(true);
  }, []);

  const onSuccessCreateVehicleRequestOffer = useCallback(() => {
    setRequestOfferModalVisible(false);

    modalProps.onClose();
  }, [modalProps]);

  const onClickSendToPartners = useCallback(() => {
    setSendToPartners((prevState) => {
      if (!prevState) {
        setShowConfirmDialog(true);
      }
      return false;
    });
  }, []);

  const onSendToPartnersConfirm = useCallback(() => {
    setSendToPartners(true);
    setShowConfirmDialog(false);
  }, []);

  const closeRequestOfferModal = useCallback(() => {
    setRequestOfferModalVisible(false);
  }, []);

  const onPrintButtonClick = useCallback(() => {
    if (!data) {
      return;
    }
    window.open(`./printing/${data.id}`, "_blank", "noopener,noreferrer");
  }, [data]);

  const getData = useCallback(async () => {
    try {
      setPending(true);
      const response = await getVehicleRequest(data!.id);

      if (!response.succeeded) {
        showNotification({
          variant: "error",
          message: response.message,
        });

        setPending(false);

        return;
      }

      setRequest(response.data);
      setPending(false);
    } catch (e) {
      showNotification({
        variant: "error",
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
      });

      setPending(false);
    }
  }, [showNotification, data]);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];

    if (!data) {
      return returnActions;
    }

    if (
      [
        VehicleRequestStatus.AwaitingConfirmation,
        VehicleRequestStatus.Rejected,
      ].indexOf(data.status) > -1 &&
      isDispatcher(user!.role)
    ) {
      returnActions.push(
        <SwitcherContainer>
          <Switcher
            label="Отправить на портал &nbsp;&nbsp;&nbsp;"
            isOn={sendToPartners}
            reverse={true}
            size={"large"}
            onChange={onClickSendToPartners}
          />
        </SwitcherContainer>
      );
    }
    if (
      [
        VehicleRequestStatus.AwaitingConfirmation,
        VehicleRequestStatus.AwaitingRequest,
      ].indexOf(data.status) > -1
    ) {
      if (isUser(user!.role) || isDispatcher(user!.role)) {
        returnActions.push(
          <Button
            text="Отмена заявки"
            variant="outlined"
            onClick={onClickRejectAlreadyApproved}
            disabled={rejecting || confirming}
            showLoader={rejecting}
          />
        );
      }
    }
    if ([VehicleRequestStatus.UnderApproval].indexOf(data.status) > -1) {
      if (isUser(user!.role)) {
        returnActions.push(
          <Button
            text="Отклонить"
            variant="outlined"
            onClick={onClickReject}
            disabled={rejecting || confirming}
            showLoader={rejecting}
          />
        );
      }
    }

    if (
      [VehicleRequestStatus.UnderApproval].indexOf(data.status) > -1 &&
      (request?.histories?.length || 0) > 0
    ) {
      if (isUser(user!.role)) {
        returnActions.push(
          <Button
            text="Перенаправить"
            variant="outlined"
            onClick={onClickRedirect}
          />
        );
      }
      if (isUser(user!.role)) {
        returnActions.push(
          <Button
            text="Согласовать"
            onClick={onClickApprove}
            showLoader={rejecting}
          />
        );
      }
    }

    if (data.status === VehicleRequestStatus.AwaitingRequest) {
      if (isUser(user!.role) || isDispatcher(user!.role)) {
        returnActions.push(
          <Button
            text="Выбрать"
            onClick={onClickConfirmRequestOffer}
            disabled={rejecting || confirming || !offer}
            showLoader={confirming}
          />
        );
      }

      if (isPartner(user!.role)) {
        returnActions.push(
          <Button text="Подать предложение" onClick={onClickAddOffer} />
        );
      }
    }

    if (
      [
        VehicleRequestStatus.AwaitingConfirmation,
        VehicleRequestStatus.Rejected,
      ].indexOf(data.status) > -1 &&
      isDispatcher(user!.role)
    ) {
      returnActions.push(
        <Button
          text="Подтвердить"
          onClick={onClickConfirm}
          disabled={rejecting || confirming}
          showLoader={confirming || pending}
        />
      );
    }

    return returnActions;
  }, [
    data,
    sendToPartners,
    onClickSendToPartners,
    user,
    rejecting,
    confirming,
    onClickConfirmRequestOffer,
    offer,
    onClickAddOffer,
    onClickConfirm,
    pending,
  ]);

  const modalTitle = useMemo<ReactNode>(() => {
    if (!data) {
      return null;
    }

    return (
      <ModalTitle text={`Заявка на технику № ${data.number}`}>
        {!!data.status && <VehicleRequestStatusChip status={data.status} />}
        {!!data.status &&
          data.status === VehicleRequestStatus.TermAgreement && (
            <Button
              text={"Печатная форма"}
              size={"small"}
              variant={"outlined"}
              startIcon={IconPrint16}
              onClick={onPrintButtonClick}
            />
          )}
      </ModalTitle>
    );
  }, [data, onPrintButtonClick]);

  useEffect(() => {
    if (!!data) {
      setPending(true);
      setRequest(null);

      getData();
    }
  }, [data, getData]);

  if (!data) {
    return null;
  }

  return (
    <>
      <Modal
        {...modalProps}
        title={modalTitle}
        actions={actions}
        size={showOffers ? "xlarge" : "medium"}
        dense={true}
      >
        {!!request && (
          <>
            {!showOffers ? (
              <VehicleRequestDetail request={request} hideHistory={false} />
            ) : (
              <>
                <VehicleRequestOffers
                  request={request}
                  selectedOffer={offer}
                  onSelectOffer={onSelectOffer}
                  onClickAddOffer={onClickAddOffer}
                />
              </>
            )}
            <ModalVehicleRequestOffer
              open={requestOfferModalVisible}
              hideInfo={true}
              onSuccess={onSuccessCreateVehicleRequestOffer}
              request={request}
              onClose={closeRequestOfferModal}
            />
          </>
        )}
        <Dialog
          open={showConfirmDialog}
          title={"Вы точно хотите разместить заказ?"}
          confirmLabel={"Да"}
          cancelLabel={"Нет"}
          onConfirm={onSendToPartnersConfirm}
          onClose={() => {
            setShowConfirmDialog(false);
          }}
        />
      </Modal>
      <ModalVehicleOfferRedirect
        title={"Перенаправление"}
        open={redirectModalVisible}
        onClose={closeRedirectModal}
        vehicleOfferId={request?.id}
        organizationId={request?.organization?.id}
      />
      <ModalVehicleOfferApproveOrReject
        title={approveRejectModalProps.title}
        open={approveRejectModalVisible}
        onClose={closeApproveRejectModal}
        isApproved={approveRejectModalProps.isApproved}
        vehicleOfferId={request?.id}
      />
    </>
  );
}

export default memo(ModalVehicleRequestDetail);
