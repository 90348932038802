import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Grid,
  Modal,
  ModalProps,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import { ShortEmployee } from "@app/models";
import { useNotification, useUser } from "@app/providers";
import {
  APIResponse,
  approverVehicleOffer,
  getCompanyEmployeesShort,
} from "@app/api";
import { getAxiosErrorMessage, getFullName } from "@app/helpers";
import { AxiosError } from "axios";

interface Props extends ModalProps {
  vehicleOfferId?: string;
  organizationId?: string;
  onClose: (val?: boolean) => void;
}

interface Data {
  selectedUser: SelectOption<ShortEmployee> | null;
  comment: string;
}

const ModalVehicleOfferRedirect = (props: Props) => {
  const { onClose, vehicleOfferId, organizationId, ...restProps } = props;
  const { showNotification } = useNotification();
  const { user } = useUser();
  const [employees, setEmployees] = useState<SelectOption<ShortEmployee>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { pending, onChange, errors, values, resetForm } = useForm<Data>({
    values: {
      comment: "",
      selectedUser: null,
    },
  });

  const getCompanyEmployees = useCallback(async (id: string) => {
    const response = await getCompanyEmployeesShort([id]);
    if (response && response.data) {
      setEmployees(
        response.data.map((employee) => ({
          label: getFullName(employee.user),
          value: employee.user.id,
          item: employee,
        }))
      );
    }
  }, []);

  useEffect(() => {
    if (organizationId) {
      getCompanyEmployees(organizationId).then(() => {});
    }
  }, [organizationId, getCompanyEmployees]);

  const onRedirectConfirm = useCallback(async () => {
    if (!vehicleOfferId || !user) {
      return;
    }
    const body = {
      id: vehicleOfferId,
      newUserId: values.selectedUser!.value,
      oldUserId: user.id,
      comment: values.comment,
    };
    setLoading(true);
    approverVehicleOffer(body)
      .then((res) => {
        showNotification({
          variant: "success",
          message: "Перенаправлен",
        });
        onClose(true);
      })
      .catch((e) => {
        setLoading(false);
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      });
  }, [values, onClose, showNotification, vehicleOfferId, user]);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];
    returnActions.push(
      <Button
        text="Перенаправить"
        onClick={onRedirectConfirm}
        disabled={!values.selectedUser}
        showLoader={loading}
      />
    );
    return returnActions;
  }, [loading, onRedirectConfirm]);

  const onModalClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  return (
    <Modal actions={actions} onClose={onModalClose} {...restProps}>
      <Grid>
        <Select<ShortEmployee>
          label="ФИО ответственнога лица"
          placeholder="Выберите из списка"
          name="selectedUser"
          onChange={onChange}
          value={values.selectedUser}
          options={employees}
        />
        <TextField
          label="Комментарий"
          placeholder="Добавьте комментарий (необязательно)"
          value={values.comment}
          name="comment"
          onChange={onChange}
        />
      </Grid>
    </Modal>
  );
};

export default memo(ModalVehicleOfferRedirect);
