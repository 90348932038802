import { Address, Company, UserRoles } from "@app/models";

export const NO_API_DATA = "Нет данных API";
export const NIGHT_SHIFT_ID = "0e75dc72-5375-4bc6-963f-838f18267414";
// export const DAY_SHIFT_PERIOD = "08:00 - 20:00";
// export const NIGHT_SHIFT_PERIOD = "20:00 - 08:00";

// UNITS ID
export const MONTH_UNIT_ID = "26cd4f51-ca03-11e7-80c3-001dd8b71c7e";
export const TN_KM_UNIT_ID = "f0a71129-be26-11e7-aa91-5cb9019dbd39";
export const HOURS_UNIT_ID = "543962f2-b051-11e7-aa8e-5cb9019dbd39";
export const SHIFT_UNIT_ID = "2bd21ae0-28fe-11e8-80c9-001dd8b71c7e";
export const PIECE_UNIT_ID = "bc06086a-c1f1-11e7-aa94-5cb9019dc09d";
export const RACE_UNIT_ID = "787af327-2912-11e8-80c9-001dd8b71c7e";
export const KM_UNIT_ID = "1def722f-b7f9-11e7-aa90-5cb9019dbd39";
export const TN_UNIT_ID = "b9841c3c-8720-11e7-80c4-00155da78904";
export const M3_UNIT_ID = "b9841c38-8720-11e7-80c4-00155da78904";
export const M2_UNIT_ID = "b9841c3b-8720-11e7-80c4-00155da78904";
export const M_UNIT_ID = "b9841c37-8720-11e7-80c4-00155da78904";
export const POG_M_UNIT_ID = "6b1113b3-073c-11e8-80cc-001dd8b71c90";

// export const PIECE_UNIT_ID = "83f812e4-ed83-11e8-80d6-001dd8b71cd8";

export function isUser(role: UserRoles): boolean {
  return role.toUpperCase() === UserRoles.USER;
}

export function isPartner(role: UserRoles): boolean {
  return role.toUpperCase() === UserRoles.PARTNER;
}

export function isDispatcher(role: UserRoles): boolean {
  return role.toUpperCase() === UserRoles.DISPATCHER;
}

export function getFullName<
  T extends {
    firstname: string | null;
    lastname: string | null;
    middlename: string | null;
  }
>(user?: T | null): string {
  if (!user) {
    return "-";
  }

  const { firstname, middlename, lastname } = user;

  return [
    (firstname || " ").trim(),
    (middlename || " ").trim(),
    (lastname || " ").trim(),
  ].join(" ");
}

export function getFullAddress(address?: Address | null): string {
  if (!address) {
    return "-";
  }

  const { street, block, apartment, region } = address;

  return [
    (region.name || " ").trim(),
    (`${street} ${block}` || " ").trim(),
    (apartment || " ").trim(),
  ]
    .filter(Boolean)
    .join(", ");
}

export const companyLabelKeys: (keyof Company)[] = ["name", "bin"];

export const shiftTypes = [
  {
    value: 0,
    label: "Все",
  },
  {
    value: 1,
    label: "День",
  },
  {
    value: 2,
    label: "Ночь",
  },
  {
    value: 3,
    label: "Сутки",
  },
];

export const vehicleStatuses = [
  {
    title: "Техника не работала",
    color: "gray",
  },
  {
    title: "Подтверждено",
    color: "green",
  },
  {
    title: "На проверке",
    color: "yellow",
  },
  {
    title: "Ожидает подтверждения",
    color: "orange",
  },
];

export const emptyPageableResponse = Promise.resolve({
  errors: null,
  message: null,
  pageNumber: 1,
  pageSize: 10,
  recordsFiltered: 0,
  recordsTotal: 0,
  succeeded: true,
  data: [],
});

export * from "./act-of-work";
export * from "./act-of-service";
export * from "./capitalize";
export * from "./constants";
export * from "./currencyFormat";
export * from "./dateFormat";
export * from "./downloadFile";
export * from "./enumToOptions";
export * from "./formatFileSize";
export * from "./generateUniqueId";
export * from "./genericMemo";
export * from "./getAxiosErrorMessage";
export * from "./getDictionaryName";
export * from "./getFilterValues";
export * from "./getNDS";
export * from "./getSum";
export * from "./ncalayer";
export * from "./nomenclature";
export * from "./parseJWT";
export * from "./phoneMask";
export * from "./priceFormat";
export * from "./removeLeadingZero";
export * from "./setMask";
export * from "./setSelectOption";
export * from "./toLatLng";
export * from "./urlParams";
export * from "./wordDeclension";
