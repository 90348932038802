import styled, { css } from "styled-components";
import { theme } from "styled-tools";
import { TableCellProps } from "./types";

export const Row = styled.tr<{
  clickable: boolean;
}>`
  ${(props) =>
    props.clickable
      ? css`
          cursor: pointer;

          &:hover {
            background-color: #eee;
          }
        `
      : ""};
`;

export const TableCell = styled.td<TableCellProps>`
  font-size: 14px;
  line-height: 20px;
  padding: ${(props) => (props.dense ? "12px 4px" : "12px")};
  width: 1%;
  white-space: nowrap;
  text-align: left;
  border-top: 1px solid
    ${(props) =>
      props.noHeader ? theme("color.white") : theme("color.grayLight")};
  font-family: ${theme("fontFamily")};
  color: ${theme("color.dark")};
  max-width: ${(props) => `${props.maxWidth || 345}px`};
`;

export const Wrapper = styled.div<{
  disableOverflow: boolean;
  fixedHeight?: string;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow-x: ${(props) => (props.disableOverflow ? "initial" : "auto")};
  white-space: nowrap;
  // чтобы работало position sticky
  height: ${(props) => (props.fixedHeight ? props.fixedHeight : "auto")};
`;

export const TableRoot = styled.table`
  width: 100%;
  background-color: ${theme("color.white")};
  border-spacing: 0;
`;

export const TableHead = styled.th<{
  fitContent: boolean;
  dense: boolean;
  sticky: boolean;
}>`
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  padding: ${(props) => (props.dense ? "16px 4px" : "14px 12px")};
  text-align: left;
  color: ${theme("color.dark")};
  font-family: ${theme("fontFamily")};
  white-space: pre;
  position: ${(props) => (props.sticky ? "sticky" : "initial")};
  top: 0;
  z-index: 1;
  background-color: ${theme("color.white")};
  ${(props) =>
    props.fitContent
      ? css`
          width: 1%;
          white-space: nowrap;
        `
      : ""}
`;

export const TableExtraRow = styled.tr`
  background-color: ${theme("color.yellowLight")};
`;

export const TableExtraCell = styled.td`
  font-size: 14px;
  line-height: 20px;
  padding: 12px;
  width: 1%;
  white-space: nowrap;
  text-align: left;
  font-family: ${theme("fontFamily")};
  font-weight: bold;
  color: ${theme("color.dark")};
  border-top: 1px solid ${theme("color.grayLight")};
`;

export const EmptyCell = styled.td`
  text-align: center;
  padding: 20px;
  font-family: ${theme("fontFamily")};
  color: ${theme("color.grayDark")};
`;
