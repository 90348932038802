import React, { memo } from "react";
import styled from "styled-components";
import { StyledContainer } from "@app/components";
import { ifProp, theme } from "styled-tools";

const StyledSection = styled.section`
  height: 400px;
  background: linear-gradient(80.42deg, #6174da 22.62%, #9661da 101.87%);
  position: relative;
`;

const StyledGrid = styled(StyledContainer)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  align-items: center;

  @media only screen and (max-width: 576px) {
    padding: 24px;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledTitle = styled.h3`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  margin: 0 0 24px 0;
  color: ${theme("color.white")};

  @media only screen and (max-width: 576px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;

const StyledDots = styled.div`
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  gap: 16px;
`;

const StyledDot = styled.button<{
  active?: boolean;
}>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${ifProp(
    "active",
    "linear-gradient(86.19deg, #ED4A3F 19.16%, #F99335 78.75%);",
    theme("color.white")
  )};
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const VideoContainer = styled.div`
  margin-left: 24px;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 576px) {
    margin: 48px 0 0;
  }
`;

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  min-height: 400px;

  @media only screen and (max-width: 576px) {
    min-height: initial;
    margin: 0;
  }
`;

function SectionWelcome() {
  return (
    <StyledSection>
      <StyledGrid>
        <StyledDots>
          <StyledDot active={true} />
        </StyledDots>
        <div>
          <StyledTitle>
            Dispatch - web-сервис для прозрачного и эффективного управления
            спецтехникой
          </StyledTitle>
        </div>
        <VideoContainer>
          <StyledVideo controls muted autoPlay={true} controlsList="nodownload">
            <source src="/DispatchIntro.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </StyledVideo>
        </VideoContainer>
      </StyledGrid>
    </StyledSection>
  );
}

export default memo(SectionWelcome);
