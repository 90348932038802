import { Project } from "./Project";
import {
  Company,
  CompanyJournal,
  CompanyShort,
  CompanyVehicle,
} from "./Company";
import { ServiceType } from "./Service";
import { Dictionary, FileObject, LatLng, Unit, VehicleFile } from "./Default";
import { Characteristic, CharacteristicVehicle } from "./Characteristic";
import { WorkShift, WorkShiftTime } from "./WorkShift";
import { Nomenclature } from "./Nomenclature";
import { NDS, NdsShort } from "./NDS";
import { Confirmer, UserSimple } from "./User";
import { GPS } from "./GPS";

export enum VehicleStatus {
  Created = 0,
  AwaitingConfirmation = 1,
  Confirmed = 2,
  Rejected = 3,
}

export enum VehicleGpsDataUpdateStatus {
  Unknown1 = 0,
  Unknown2 = 1,
  Unknown3 = 2,
  Unknown4 = 3,
}

export const VehicleStatusName: Record<VehicleStatus, string> = {
  [VehicleStatus.AwaitingConfirmation]: "На рассмотрении",
  [VehicleStatus.Confirmed]: "Подтверждено",
  [VehicleStatus.Rejected]: "Отклонен",
  [VehicleStatus.Created]: "Создан",
};

export enum VehicleRequestStatus {
  UnderApproval = 0,
  AwaitingConfirmation = 1,
  AwaitingRequest = 2,
  TermAgreement = 3,
  ContractSign = 4,
  OrderCompleted = 5,
  Rejected = 6,
  RejectedByCustomer = 7,
}

export interface VehicleRequestStatusOption {
  id: string;
  name: string;
}

export const VehicleRequestStatusName: Record<VehicleRequestStatus, string> = {
  [VehicleRequestStatus.UnderApproval]: "На согласовании у заказчика",
  [VehicleRequestStatus.AwaitingConfirmation]: "На рассмотрении",
  [VehicleRequestStatus.AwaitingRequest]: "Обработка заявки",
  [VehicleRequestStatus.TermAgreement]: "Согласование договора",
  [VehicleRequestStatus.ContractSign]: "Согласование служебной записки",
  [VehicleRequestStatus.OrderCompleted]: "Исполнено",
  [VehicleRequestStatus.Rejected]: "Отменён",
  [VehicleRequestStatus.RejectedByCustomer]: "Отклонен заказчиком",
};

export enum VAERequestStatus {
  AwaitingConfirmation = 1,
  AwaitingRequest = 2,
  TermAgreement = 3,
  ContractSign = 4,
  OrderCompleted = 5,
  Rejected = 6,
}

export const VAERequestStatusName: Record<VAERequestStatus, string> = {
  [VehicleRequestStatus.AwaitingConfirmation]: "На рассмотрении",
  [VehicleRequestStatus.AwaitingRequest]: "В обработке",
  [VehicleRequestStatus.TermAgreement]: "Согласование договора",
  [VehicleRequestStatus.ContractSign]: "Согласование служебной записки",
  [VehicleRequestStatus.OrderCompleted]: "Исполнено",
  [VehicleRequestStatus.Rejected]: "Отменён",
};

export enum VehicleRequestOfferStatus {
  UnderConsideration = 1,
  Selected = 2,
  Rejected = 3,
  Deleted = 4,
  RequestCancelled = 5,
}

export const VReqOfferStatusName: Record<VehicleRequestOfferStatus, string> = {
  [VehicleRequestOfferStatus.Deleted]: "Удален",
  [VehicleRequestOfferStatus.Rejected]: "Отклонен",
  [VehicleRequestOfferStatus.RequestCancelled]: "Отменен",
  [VehicleRequestOfferStatus.Selected]: "Заказ выбран",
  [VehicleRequestOfferStatus.UnderConsideration]: "На рассмотрении",
};

export enum VAERequestOfferStatus {
  UnderConsideration = 1,
  Selected = 2,
  Rejected = 3,
  Deleted = 4,
  RequestCancelled = 5,
}

export enum VehicleZhurnalState {
  SAVE = 1,
  DID_NOT_WORK = 10,
  CONFIRMED = 2,
  CONSIDERATION = 3,
}

export const VehicleZhurnalStateName: Record<VehicleZhurnalState, string> = {
  [VehicleZhurnalState.SAVE]: "Только по столбцу “Не определено”",
  [VehicleZhurnalState.CONFIRMED]: "Только по столбцу “Подтверждено”",
  [VehicleZhurnalState.CONSIDERATION]: "Только по столбцу “На проверке”",
  [VehicleZhurnalState.DID_NOT_WORK]: "Только по столбцу “Не работал”",
};

export const VehicleZhurnalStateNaming: Record<VehicleZhurnalState, string> = {
  [VehicleZhurnalState.SAVE]: "Не определено",
  [VehicleZhurnalState.CONFIRMED]: "Подтверждено",
  [VehicleZhurnalState.CONSIDERATION]: "На проверке",
  [VehicleZhurnalState.DID_NOT_WORK]: "Не работал",
};

export interface Vehicle {
  id: string;
  name: string;
  govNumber: string;
  vehicleType: VehicleTypeShort;
  vehicleTypeId: string;
  vehicleModel: VehicleModel;
  characteristics: string;
  characteristicsId: string;
  gps: any;
  gpsProvider?: null;
  status: VehicleStatus;
  files?: VehicleFile[];
  companies?: any;
}

export interface VehicleListItem {
  id: string;
  name: string;
  govNumber: string;
  vehicleType: string;
  vehicleModel: VehicleModel;
  characteristics: string;
  gps: string | null;
  imeigps: string | null;
  status: VehicleStatus;
  gpsDataUpdated: string | null;
  gpsDataUpdatedStatusId: VehicleGpsDataUpdateStatus;
  lastPositionLatitude: number | null;
  lastPositionLongitude: number | null;
  companies: CompanyShort[];
}

export interface VehicleInfo {
  type: VehicleType;
  model: VehicleModelFull;
  characteristic: CharacteristicVehicle;
  gps: Gps;
  companies: CompanyVehicle[];
  files: VehicleFile[];
  govNumber: string;
  yearOfIssue: string;
  vehicleTypeId: string;
  vehicleModelId: string;
  characteristicsId: string;
  gpsId: string;
  isGpsInstalled: boolean;
  imeiGps: string;
  idInGps: null;
  status: number;
  gpsDataUpdated: string;
  lastPositionLatitude: number;
  lastPositionLongitude: number;
  id: string;
  isDeleted: boolean;
}

interface User {
  id: string;
  lastname: string;
  firstname: string;
  middlename: string | null;
  phone: string | null;
}

interface Approver {
  requestId: string;
  user: User;
  userId: string;
  isApproved: boolean;
  comment: string | null;
}

interface History {
  vehicleRequestId: string;
  statusId: number;
  user: User;
  text: string;
  comment: string;
  created: string;
}

export interface VehicleRequest {
  id: string;
  number: number;
  organization: Company;
  project: Project;
  vehicleType: VehicleType;
  serviceType: ServiceType;
  characteristics: Characteristic;
  status: VehicleRequestStatus;
  workShift: WorkShift;
  quantity: number;
  volume: number;
  unit: Unit | null;
  startDate: string;
  endDate: string;
  comments: string;
  confirmer: Confirmer;
  approvers: Approver[];
  histories: History[];
}

export interface VAERequest
  extends Omit<VehicleRequest, "vehicleType" | "organization" | "status"> {
  status: VAERequestStatus;
  customer: Company;
  vehicle: Vehicle;
  nomenclature: Nomenclature;
  partner: Company;
  partnerId: string;
  nds: NDS;
}

export interface VehicleType {
  id: string;
  name: string;
  isLifting: boolean;
  isSmartContract: boolean;
}

export interface VehicleTypeShort {
  id: string;
  name: string;
  isLifting: boolean;
  isSmartContract: boolean;
}

export interface VehicleShortDto {
  id: string;
  govNumber: string | null;
  name: string | null;
  gps: GPS;
  vehicleModel: VehicleModel;
  vehicleType: VehicleTypeShort;
}

export interface VehicleMark {
  id: string;
  name: string;
}

export interface VehicleModel {
  id: string;
  name: string;
}

export interface VehicleModelFull {
  mark: VehicleMark | null;
  name: string;
  vehicleMarkId: string;
  id: string;
  isDeleted: boolean;
}

export interface Gps {
  type: GpsType;
  name: string;
  typeId: string;
  id: string;
  isDeleted: boolean;
}

export interface GpsType {
  name: string;
  url: string;
  id: string;
  isDeleted: boolean;
}

export interface VehicleItem {
  vehicleJurnalId: string;
  toPay: number;
  total: number;
}

export interface VehicleJournalForAct {
  id: string;
  workDate: string;
  shiftTypeDto: WorkShift;
  startTime: string;
  endTime: string;
  vehicleTypeDto: VehicleType;
  govNumber: string;
  lineData: string;
  gpsData: string;
  unitDto: Unit;
  price: number;
  summa: number;
  summaNds: number;
  total: number;
  isAdditional: boolean;
  vehicleId: string;
}

export interface VehicleJournalList {
  workDate: string;
  companyId: string;
  companyName: string;
  projectId: string;
  projectName: string;
  partnerId?: string;
  partnerName?: string;
  vehicleTypeId?: string;
  vehicleTypeName?: string;
  confirmedCount: number;
  didNotWorkCount: number;
  onConfirmationCount: number;
  saveCount: number;
}

export interface VehicleCharacteristic {
  id: string;
  name: string;
  vehicleType: VehicleType;
}

export interface VehicleJournalDetail {
  id: string;
  company: CompanyJournal;
  comment: string;
  comments: VehicleJournalCommentDto[];
  files: any[];
  vehicle: Vehicle;
  vehicleType: VehicleType;
  vehicleCharacteristic: VehicleCharacteristic;
  partner: CompanyJournal;
  ndsShortDto: NdsShort | null;
  project: Project;
  workDate: string;
  workPerformed: string | null;
  workStartDateTime: string | null;
  workEndDateTime: string | null;
  cargoWeight: number | null;
  distance: number | null;
  priceWithNds: number | null;
  priceWithOutNds: number | null;
  hoursGps: string | null;
  engineOffHours: string | null;
  sum: number | null;
  stateId: VehicleZhurnalState;
  serviceType: ServiceType;
  shiftType: WorkShift;
  workShift: WorkShiftTime;
  unitShort: Unit;
  shiftDuration: string | null;
}

export interface VehicleJournalDetailParams {
  companyId: string;
  projectId: string;
  workDate?: string;
  partnerId?: string;
  vehicleTypeId?: string;
}

export interface VehicleJournalLoadDataRequest {
  vehicleJurnalId: string;
  vehicleId: string;
  startTime: string;
  endTime: string;
}

export interface VehicleJournalConfirmRequest {
  id: string;
  vehicleId: string;
  workStartDateTime: string;
  workEndDateTime: string;
  cargoWeight?: number | null;
  distance?: number | null;
  workPerformed?: string;
  stateId: VehicleZhurnalState;
  comment?: string;
  files?: File[];
}

export interface VehicleJournalNotWorkRequest {
  id: string;
  comment?: string;
  files?: File[];
}

export interface VehicleJournalCommentDto {
  id: string;
  createdBy: string;
  created: string;
  lastModifiedBy: string;
  lastModified: string | null;
  deleted: string | null;
  isDeleted: boolean;
  vehicleJurnalId: string;
  userId: string;
  comment: string;
  user: UserSimple;
}

export interface VehicleRequestOffer {
  id: string;
  startDate: string;
  endDate: string;
  govNumber: string | null;
  number: string;
  price: number;
  priceWithoutNds: number;
  status: VehicleRequestOfferStatus;
  vehicle: Vehicle;
  project: Project;
  nds: NdsShort;
  comments: string;
  company: Company;
  files?: unknown[];
  request: VehicleRequest;
}

export interface VAERequestOffer {
  id: string;
  startDate: string;
  endDate: string;
  price: number;
  priceWithoutNds: number;
  status: VAERequestOfferStatus;
  vehicle: Vehicle;
  nds: NDS;
  comments: string;
  company: Company;
}

export interface VehicleReport {
  drainVolume: number;
  endFuelVolume: string | null;
  engineOffHours: string | null;
  fuelConsumption: string | null;
  shiftDuration: string | null;
  shiftEnd: string | null;
  shiftEngineIdleTime: string | null;
  shiftEngineOperationTime: string | null;
  shiftEngineWorkTime: string | null;
  shiftFueled: number;
  shiftMilleage: number | null;
  shiftStart: string | null;
  startFuelVolume: number | null;
  shiftTravelTime: string | null;
}

export interface VehicleHistory {
  endDate: string;
  id: string;
  number: number;
  priceWithoutNds: number;
  project: Project;
  startDate: string;
  unit: Unit;
}

export interface VehicleMonitoring
  extends Omit<Vehicle, "gps" | "status" | "vehicleType"> {
  companies: Company[];
  gps: string | null;
  imeigps: string | null;
  status: number;
  dateMax: Date | null;
  dateMin: Date | null;
  gpsDataUpdated: string | null;
  gpsDataUpdatedStatusId: number;
  lastPositionLatitude: number | null;
  lastPositionLongitude: number | null;
  vehicleType: string;
}

export interface VehicleTrackPoint extends LatLng {
  dateTime: number;
  speed: number;
}

export interface VehicleTrackState extends VehicleTrackPoint {
  address?: string;
}

export type VehicleTrack = VehicleMonitoring &
  VehicleTrackState & {
    points: VehicleTrackPoint[];
  };

export interface VehicleJournalForActV2 {
  vehicleTypeId: string;
  vehicleTypeName: string | null;
  govNumber: string | null;
  totalWorkPerformed: number;
  totalHoursGps: number;
  totalShiftDuration: number;
  unitDto: Unit;
  totalPrice: number | null;
  totalSumma: number | null;
  totalSummaNds: number | null;
  totalToPay: number | null;
  childCount: number;
  items: ActVehicleItemV2[] | null;
  ids: string[] | null;
  characteristic?: Dictionary | null;
  serviceType?: ServiceType | null;
}

export interface ActVehicleItemV2 {
  id: string;
  workDate: string;
  shiftTypeDto: WorkShift;
  startTime: string;
  endTime: string;
  vehicleTypeDto: VehicleType;
  nomenclature: Nomenclature | null;
  serviceType: ServiceType;
  characteristic: Characteristic;
  govNumber: string;
  lineData: string;
  gpsData: string;
  unitDto: Unit;
  price: number;
  summa: number;
  summaNds: number;
  total: number;
  isAdditional: boolean;
  vehicleId: string;
  workPerformed: string | null;
  hoursGps: string | null;
  shiftDuration: string | null;
  comment: string | null;
  files: FileObject[] | null;
  _toPay?: number;
  toPay: number;
}

export interface JournalFileData {
  id: string;
  name: string;
  url: string;
}

export interface FileWithUrl extends File {
  id: string;
  url: string;
}

export enum VehicleRequestType {
  ALL_CHARACTERISTICS = 10,
  REQUEST_CREATION = 20,
}

export interface VehicleFileData {
  VehicleId: string;
  File: string;
  TypeId: string;
  CompanyId?: string;
  ExpirationDate?: string;
}

export interface FileResponse {
  name: string;
  description: string | null;
  path: string;
  id: string;
  isDeleted: boolean;
}

export interface TypeResponse {
  name: string;
  isExpirationDateRequired: boolean;
  id: string;
  isDeleted: boolean;
}

export interface AddVehicleFileResponse {
  file: FileResponse;
  type: TypeResponse;
  company: string;
  vehicleId: string;
  fileId: string;
  typeId: string;
  companyId: string;
  expirationDate: string;
  id: string;
  isDeleted: boolean;
}
