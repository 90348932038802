import React, { memo, useCallback, useEffect, useState } from "react";
import {
  DateTimePicker,
  Grid,
  MultiSelect,
  Select,
  SelectOption,
  TextField,
} from "@app/components";
import {
  Characteristic,
  Company,
  CompanyVehicle,
  GPS,
  VehicleModel,
  VehicleType,
} from "@app/models";
import styled from "styled-components";
import {
  getCharacteristics,
  getGps,
  getMyCompanies,
  getVehicleModels,
  getVehicleTypes,
  PageableParams,
} from "@app/api";

type DataErrors = {
  [key in keyof any]?: string;
};

interface Props {
  dataOfOrganization: any;
  dataParams?: any;
  companies?: any;
  dataErrors: DataErrors;
  handleRemoveOrganization: (orgId: string) => void;
  onDataChange: (value: any[keyof any], key: keyof any) => void;
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledHeader = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 8px;
`;

const initialParams = {
  pageNumber: 1,
  pageSize: 10000,
  searchText: " ",
};

function Step1(props: Props) {
  const {
    dataParams,
    dataErrors,
    onDataChange,
    handleRemoveOrganization,
    companies,
  } = props;
  const [errors, setErrors] = useState<DataErrors>({});
  const [myCompanies, setMyCompanies] = useState<any[]>([]);
  const [gpsProviders, setGpsProviders] = useState<SelectOption<any>[]>([]);
  const [vehicleModels, setVehicleModels] = useState<SelectOption<any>[]>([]);
  const [isOtherAdded, setIsOtherAdded] = useState(false);

  useEffect(() => {
    setErrors(dataErrors);
  }, [dataErrors]);

  const handleSelectChange = (newValue: Company[], name: string) => {
    const removedItems = dataParams.organization.filter(
      (org: { id: string; item: CompanyVehicle; name: string }) =>
        !newValue.some((newOrg: any) => newOrg.id === org.id)
    );
    removedItems.forEach((removedOrg: any) => {
      handleRemoveOrganization(removedOrg.id);
    });

    onDataChange(newValue, name);
  };

  const handleChange = (value: any, name: string) => {
    if (name === "vehicleType") {
      onDataChange(null, "vehicleModel");
      onDataChange(null, "characteristic");
    }

    if (name === "govNumber") {
      value = value.replace(/\s/g, "");
    }
    onDataChange(value, name);
  };

  const loadCharacteristics = useCallback(
    (params: PageableParams<Characteristic>) => {
      return getCharacteristics({
        ...params,
        vehicleTypeId: dataParams.vehicleType!.value,
        typeIds: [10, 30],
      });
    },
    [dataParams.vehicleType]
  );

  const loadGpsProviders = useCallback(async (params: PageableParams) => {
    try {
      const response = await getGps(params);

      // Скрываем "Spectrum Umit Wialon"
      const filteredData = response.data
        .filter((gps: any) => gps.name !== "Spectrum Umit Wialon")
        .map((gps: any) => ({
          value: gps.id,
          label: gps.name,
          item: gps,
        }));

      const sortedData = filteredData.sort((a, b) => {
        if (a.value === "c190e403-3c02-11e9-80d4-001dd8b71e32") {
          return -1;
        }
        return 0;
      });

      setGpsProviders(sortedData);
    } catch (error) {
      console.error("Ошибка при загрузке списка gps: ", error);
    }
  }, []);

  const loadMyCompanies = useCallback(async (params: PageableParams) => {
    try {
      const response = await getMyCompanies(params);

      const filteredCompanies = response.data.filter(
        (company: Company) => company.companyStatus === 2
      );

      const formattedData = filteredCompanies.map((company: any) => {
        return {
          id: company.id,
          name:
            `${company.name} ${company.type?.name ?? ""}` +
            ` ${company.bin ? `(${company.bin})` : ""}`,
          item: company,
        };
      });

      setMyCompanies(formattedData);
    } catch (error) {
      console.error("Ошибка при загрузке списка: ", error);
    }
  }, []);

  const loadVehicleModels = useCallback(
    async (params: PageableParams<VehicleModel>) => {
      try {
        const response = await getVehicleModels(params);

        const formattedOptions: SelectOption<VehicleModel>[] =
          response.data.map((model) => ({
            label: model.name,
            value: model.id,
            item: model,
          }));

        const additionalOption: SelectOption<VehicleModel> = {
          label: "Иная",
          value: "a4cf594d-e4e4-4bc3-ad56-cf6aeb2d571e",
        };

        const optionsWithOther = [additionalOption, ...formattedOptions];

        setVehicleModels(optionsWithOther);
        setIsOtherAdded(true);
      } catch (error) {
        console.error("Ошибка при загрузке моделей: ", error);
      }
    },
    []
  );

  useEffect(() => {
    (async () => {
      await loadMyCompanies(initialParams);
      await loadVehicleModels(initialParams);
      await loadGpsProviders(initialParams);
    })();
  }, [loadMyCompanies, loadVehicleModels, loadGpsProviders]);

  const companyStatuses = companies.reduce(
    (acc: any, company: CompanyVehicle) => {
      acc[company.companyId] = company.statusId;
      return acc;
    },
    {}
  );

  return (
    <div>
      <Header>
        <StyledHeader>Общая информация</StyledHeader>
        <Grid>
          <MultiSelect<Company>
            label="Организация"
            onChange={(value) => {
              handleSelectChange(value, "organization");
            }}
            showChips={true}
            options={myCompanies}
            values={dataParams.organization}
            error={!!errors.organization}
            companies={companies}
            companyStatuses={companyStatuses}
            helperText={errors.organization}
            onClear={() => handleSelectChange([], "organization")}
          />
          <Grid columns={2}>
            <Select<VehicleType>
              label="Вид техники"
              name="vehicleType"
              onChange={handleChange}
              value={dataParams.vehicleType}
              error={!!errors.vehicleType}
              helperText={errors.vehicleType}
              valueKey="id"
              labelKey="name"
              loadData={getVehicleTypes}
            />
            <Select<VehicleModel>
              label="Модель"
              name="vehicleModel"
              onChange={handleChange}
              value={dataParams.vehicleModel}
              error={!!errors.vehicleModel}
              helperText={errors.vehicleModel}
              valueKey="id"
              labelKey="name"
              options={vehicleModels}
            />
          </Grid>
          <Grid columns={3}>
            <Select<Characteristic>
              label="Характеристика техники"
              name="characteristic"
              onChange={handleChange}
              value={dataParams.characteristic}
              error={!!errors.characteristic}
              helperText={errors.characteristic}
              valueKey="id"
              labelKey="name"
              disabled={!dataParams.vehicleType}
              loadData={loadCharacteristics}
            />
            <TextField
              label="Гос номер"
              placeholder="Введите гос номер"
              name="govNumber"
              onChange={handleChange}
              value={dataParams.govNumber}
              error={!!errors.govNumber}
              helperText={errors.govNumber}
            />
            <DateTimePicker
              label="Год выпуска ТС"
              name="yearOfIssue"
              onChange={handleChange}
              value={dataParams?.yearOfIssue}
              error={!!errors.yearOfIssue}
              helperText={errors.yearOfIssue}
              hideTime={true}
            />
          </Grid>
          <Select<GPS>
            label="Провайдер GPS"
            name="gps"
            onChange={handleChange}
            value={dataParams.gps}
            error={!!errors.gps}
            helperText={errors.gps}
            valueKey="id"
            labelKey="name"
            options={gpsProviders}
          />
        </Grid>
      </Header>
    </div>
  );
}

export default memo(Step1);
