import { memo, useCallback, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { TabRoute, TabRouter } from "@app/common";
import { LayoutDefault } from "@app/layouts";
import { Button } from "@app/components";
import { IconPrint16 } from "@app/icons";
import { BlockingModal } from "@app/modals";
import { useUser } from "@app/providers";
import ListView, { FILTER_TYPES } from "./components/ListView";
import { moduleNotAvailable, needToSignAdhesion } from "@app/helpers";

function PageVehicleAccounting() {
  const { hasUnsignedAdhesion } = useUser();

  const routes = useMemo<TabRoute[]>(
    () => [
      {
        label: "По датам",
        path: "/vehicle-accounting/list/by-dates",
      },
      {
        label: "По контрагентам",
        path: "/vehicle-accounting/list/by-partners",
      },
      {
        label: "По видам техники",
        path: "/vehicle-accounting/list/by-vehicles",
      },
    ],
    []
  );

  const onPrintButtonClick = useCallback(() => {
    window.open("/app/vehicle-accounting/printing", "_blank");
  }, []);

  return (
    <LayoutDefault
      title="Журнал учета техники (наряд)"
      rightControls={[
        <Button
          key={"btn"}
          text={"Печатная форма"}
          size={"small"}
          variant={"outlined"}
          startIcon={IconPrint16}
          onClick={onPrintButtonClick}
        />,
      ]}
    >
      <BlockingModal
        show={hasUnsignedAdhesion}
        title={moduleNotAvailable}
        message={needToSignAdhesion}
        redirectPath={"/tasks/accession"}
      />
      <TabRouter routes={routes}>
        <Routes>
          {/*<Route path="/dashboard" element={<DashboardView />} />*/}
          <Route
            path="/list/by-dates"
            element={
              <ListView key="001" filterTypeIndex={FILTER_TYPES.BY_DATES} />
            }
          />
          <Route
            path="/list/by-partners"
            element={
              <ListView key="002" filterTypeIndex={FILTER_TYPES.BY_PARTNERS} />
            }
          />
          <Route
            path="/list/by-vehicles"
            element={
              <ListView key="003" filterTypeIndex={FILTER_TYPES.BY_VEHICLES} />
            }
          />
        </Routes>
      </TabRouter>
    </LayoutDefault>
  );
}

export default memo(PageVehicleAccounting);
