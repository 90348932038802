import React, { memo, ReactNode, useCallback, useMemo, useState } from "react";
import {
  Button,
  Grid,
  ListItem,
  Modal,
  ModalProps,
  ModalTitle,
} from "@app/components";
import { Adhesion, Task } from "@app/models";
import { ContractTranscriptView, TaskStatusChip } from "@app/common";
import {
  blobToBase64,
  dateFormat,
  getAxiosErrorMessage,
  getFullName,
} from "@app/helpers";
import styled from "styled-components";
import { theme } from "styled-tools";
import { APIResponse, getFileById, signAdhesionContract } from "@app/api";
import { AxiosError } from "axios";
import { useNcaLayerClient, useNotification } from "@app/providers";

interface Props extends Omit<ModalProps, "title" | "onCLose"> {
  task: Task;
  contract: Adhesion | undefined;
  onClose: (val?: boolean) => void;
}

const StyledInfoBlock = styled.div`
  padding: 16px 24px;
  background-color: ${theme("color.background")};
`;

const StyledContent = styled.div`
  padding: 16px;
`;

const ModalAdhesionTaskDetail = (props: Props) => {
  const { open, task, contract, onClose } = props;
  const { isRunning, signCMS } = useNcaLayerClient();
  const [signing, setSigning] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { showNotification } = useNotification();

  const modalTitle = useMemo<ReactNode>(() => {
    return (
      <ModalTitle text={task.name || ""}>
        <TaskStatusChip status={task.status} />
      </ModalTitle>
    );
  }, [task.name, task.status]);

  const signContract = useCallback(
    async (signedFile: string) => {
      if (signedFile && task?.id) {
        try {
          setLoading(true);
          const body = {
            taskId: task.id,
            signedFile,
          };
          const res = await signAdhesionContract(body);

          setSigning(false);
          setLoading(false);

          if (res && res.succeeded) {
            showNotification({
              message: "Договор подписан успешно",
              variant: "success",
            });
            onClose(true);
          }
        } catch (e) {
          setSigning(false);
          setLoading(false);

          showNotification({
            message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
            variant: "error",
          });
        }
      } else {
        setSigning(false);
      }
    },
    [onClose, showNotification, task?.id]
  );

  const onSigningStart = useCallback(async () => {
    if (!isRunning) {
      return;
    }
    try {
      setSigning(true);
      if (contract?.fileId) {
        const res = await getFileById(contract.fileId);
        const base64 = await blobToBase64(res);

        if (!base64) {
          showNotification({
            message: "Возникли проблемы с файлом для подписи",
            variant: "error",
          });
          return;
        }

        const [signedFile] = await signCMS([base64]);

        await signContract(signedFile);
      }
    } catch (e) {
      setSigning(false);
    }
  }, [contract?.fileId, isRunning, showNotification, signCMS, signContract]);

  const modalActions = useMemo(
    () => [
      <Button
        text="Подписать"
        disabled={loading || signing}
        showLoader={loading || signing}
        onClick={onSigningStart}
      />,
    ],
    [loading, onSigningStart, signing]
  );

  return (
    <Modal
      open={open}
      title={modalTitle}
      size={"medium"}
      actions={modalActions}
      dense
      onClose={onClose}
    >
      <StyledInfoBlock>
        <Grid columns={5}>
          <ListItem
            subtitle="Инициатор"
            title={getFullName(task.initiator)}
            reverse
          />
          <ListItem
            subtitle="Дата отправки"
            title={
              contract?.created
                ? dateFormat(contract?.created, "dd.MM.yyyy ")
                : "—"
            }
            reverse
          />
        </Grid>
      </StyledInfoBlock>
      <StyledContent>
        {contract && (
          <ContractTranscriptView
            files={[contract.file]}
            pdfFiles={[contract.pdfFile]}
            signedPdfFiles={[]}
          />
        )}
      </StyledContent>
    </Modal>
  );
};

export default memo(ModalAdhesionTaskDetail);
