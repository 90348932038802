import { ActVehicleItemV2, VehicleJournalForActV2 } from "@app/models";
import * as Styled from "./styled";
import {
  IconChevronDown24,
  IconDay24,
  IconNight24,
  IconPlay24,
} from "@app/icons";
import {
  currencyFormat,
  dateFormat,
  joinValues,
  NIGHT_SHIFT_ID,
  roundToTwo,
} from "@app/helpers";
import { TableRowFile } from "@app/common";
import React, { ReactNode } from "react";

export const getMapTableData = (
  array: ActVehicleItemV2[],
  onPlayClick: (act: ActVehicleItemV2) => void
) => {
  return array.map((item, index) => {
    if (!item) {
      return [];
    }

    const onClick = () => onPlayClick(item);

    return [
      <Styled.PlayBtn onClick={onClick}>
        <IconPlay24 />
      </Styled.PlayBtn>,
      `${index + 1}`,
      `${dateFormat(item.workDate, "dd.MM.yyyy")}`,
      item.shiftTypeDto?.id === NIGHT_SHIFT_ID ? (
        <IconNight24 />
      ) : (
        <IconDay24 />
      ),
      joinValues([
        dateFormat(item.startTime, "HH:mm"),
        dateFormat(item.endTime, "HH:mm"),
      ]),
      <Styled.WrappedCell>
        {joinValues([item.vehicleTypeDto, item.nomenclature])}
      </Styled.WrappedCell>,
      item.characteristic?.name,
      item.serviceType?.name,
      item.govNumber,
      item.workPerformed,
      item.hoursGps ? item.hoursGps.toString().slice(0, -3) : 0,
      item.shiftDuration ? item.shiftDuration.slice(0, -3) : 0,
      item.unitDto?.name,
      currencyFormat(item.price),
      item.toPay,
      currencyFormat(roundToTwo(item.summa)),
      currencyFormat(roundToTwo(item.summaNds)),
      currencyFormat(roundToTwo(item.summa)), // total
      item.comment,
      <TableRowFile files={item.files} />,
    ];
  });
};

export const mapTableCollapsedData = (
  item: VehicleJournalForActV2
): ReactNode[] => {
  if (!item) {
    return [];
  }

  return [
    <Styled.ParentCell>
      <IconChevronDown24 />
    </Styled.ParentCell>,
    <Styled.ParentCell colSpan={3} style={{ minWidth: "170px" }}>
      Итого по ед. изм.
    </Styled.ParentCell>,
    <Styled.ParentCell />,
    <Styled.ParentCell>{item.vehicleTypeName}</Styled.ParentCell>,
    <Styled.ParentCell>{item.characteristic?.name}</Styled.ParentCell>,
    <Styled.ParentCell />,
    <Styled.ParentCell>{item.govNumber}</Styled.ParentCell>,
    <Styled.ParentCell>{item.totalWorkPerformed}</Styled.ParentCell>,
    <Styled.ParentCell>{item.totalHoursGps}</Styled.ParentCell>,
    <Styled.ParentCell>{item.totalShiftDuration}</Styled.ParentCell>,
    <Styled.ParentCell>{item.unitDto?.name}</Styled.ParentCell>,
    <Styled.ParentCell />,
    <Styled.ParentCell>{currencyFormat(item.totalToPay)}</Styled.ParentCell>,
    <Styled.ParentCell>{currencyFormat(item.totalSumma)}</Styled.ParentCell>,
    <Styled.ParentCell>{currencyFormat(item.totalSummaNds)}</Styled.ParentCell>,
    <Styled.ParentCell>{currencyFormat(item.totalSumma)}</Styled.ParentCell>,
    <Styled.ParentCell />,
    <Styled.ParentCell />,
  ];
};
