import {
  Button,
  DateTimePicker,
  Grid,
  Select,
  TextField,
} from "@app/components";
import {
  StyledContentStep2,
  StyledNomenclature,
  StyledRemoveButton,
} from "../styled";
import { getUnits } from "@app/api";
import { MONTH_UNIT_ID } from "@app/helpers";
import { IconTrash24 } from "@app/icons";
import { NomenclatureData } from "../../constants";

type NomenclatureListViewProps = {
  nomenclatures: NomenclatureData[];
  nomenclaturesError: { [key: string]: string };
  disabled: boolean;
  withRemoveButton: boolean;
  isActOfService: boolean;
  addButtonComponent: () => JSX.Element | null;
  onChange: (value: any, name: string, index: number) => void;
  onRemove: (index: number) => void;
};

const NomenclatureListView = (props: NomenclatureListViewProps) => {
  const {
    nomenclatures,
    nomenclaturesError,
    disabled,
    withRemoveButton,
    isActOfService,
    addButtonComponent,
    onChange,
    onRemove,
  } = props;

  const onChangeNomenclature =
    (index: number) => (value: any, name: string) => {
      onChange(value, name, index);
    };

  const onRemoveNomenclature = (index: number) => () => {
    onRemove(index);
  };

  return (
    <StyledContentStep2>
      <Grid>
        {nomenclatures.map((item, itemIndex) => (
          <StyledNomenclature
            key={itemIndex.toString()}
            withRemoveButton={withRemoveButton}
          >
            <TextField
              label="№"
              value={(itemIndex + 1).toString()}
              disabled={true}
            />
            <span title={item.name}>
              <TextField
                label="Услуга"
                name="name"
                value={item.name}
                disabled={disabled}
                helperText={nomenclaturesError[`${itemIndex}.name`]}
                error={!!nomenclaturesError[`${itemIndex}.name`]}
                onChange={onChangeNomenclature(itemIndex)}
              />
            </span>
            <DateTimePicker
              label="Дата выполнения"
              hideTime={true}
              name="executionDate"
              value={item.executionDate}
              disabled={disabled}
              onChange={onChangeNomenclature(itemIndex)}
            />
            <Select
              label="Ед. изм"
              value={item.unit}
              name="unit"
              loadData={getUnits}
              labelKey="name"
              valueKey="id"
              disabled={disabled}
              helperText={nomenclaturesError[`${itemIndex}.unit`]}
              error={!!nomenclaturesError[`${itemIndex}.unit`]}
              onChange={onChangeNomenclature(itemIndex)}
            />
            <TextField
              label="Кол-во"
              value={item.unit?.value === MONTH_UNIT_ID ? "1" : item.quantity}
              priceFormat={true}
              name="quantity"
              disabled={disabled || item.unit?.value === MONTH_UNIT_ID}
              helperText={nomenclaturesError[`${itemIndex}.quantity`]}
              error={!!nomenclaturesError[`${itemIndex}.quantity`]}
              onChange={onChangeNomenclature(itemIndex)}
            />
            <TextField
              label="Цена"
              value={item.unit?.value === MONTH_UNIT_ID ? item.sum : item.price}
              priceFormat={true}
              name="price"
              disabled={disabled}
              helperText={nomenclaturesError[`${itemIndex}.price`]}
              error={!!nomenclaturesError[`${itemIndex}.price`]}
              onChange={onChangeNomenclature(itemIndex)}
            />
            <TextField
              label="Сумма"
              value={item.sum}
              priceFormat={true}
              disabled={true}
              onChange={onChangeNomenclature(itemIndex)}
            />
            <TextField
              label="в т.ч. НДС"
              value={item.sumNds}
              priceFormat={true}
              disabled={true}
              onChange={onChangeNomenclature(itemIndex)}
            />
            {!disabled && !isActOfService && (
              <StyledRemoveButton>
                <Button
                  startIcon={IconTrash24}
                  variant="text"
                  onClick={onRemoveNomenclature(itemIndex)}
                />
              </StyledRemoveButton>
            )}
          </StyledNomenclature>
        ))}
        {addButtonComponent()}
      </Grid>
    </StyledContentStep2>
  );
};

export default NomenclatureListView;
