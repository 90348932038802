import React from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { IconInfo16 } from "@app/icons";

type Props = {
  title: string;
  description: string;
  onClick?: () => void;
};

const StyledAlertBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid ${theme("color.grayLight")};
  background: ${theme("color.white")};

  p {
    font-size: 12px;
    line-height: 16px;
  }
`;

const StyledAlertBoxTexts = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAlertBoxIcon = styled.div`
  display: flex;
  height: 24px;
  min-width: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: ${theme("color.dark")};
  cursor: pointer;

  animation: pulse 1s infinite;

  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    50% {
      transform: scale(1.05);
      box-shadow: 0 0 0 14px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

export function AlertBox(props: Props) {
  const { title, description, onClick: propsOnClick } = props;

  function onClick() {
    if (propsOnClick) {
      propsOnClick();
    }
  }

  return (
    <>
      <StyledAlertBox>
        <StyledAlertBoxIcon onClick={onClick}>
          <IconInfo16 />
        </StyledAlertBoxIcon>
        <StyledAlertBoxTexts>
          <p>{title}</p>
          <p>{description}</p>
        </StyledAlertBoxTexts>
      </StyledAlertBox>
    </>
  );
}
