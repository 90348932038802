import React, { memo, useEffect, useState } from "react";
import { Info, Loader, Table } from "@app/components";
import {
  dateFormat,
  dateFormatWithTimezone,
  getDictionaryName,
  getFullName,
  isUser,
} from "@app/helpers";
import { VehicleRequest } from "@app/models";
import styled from "styled-components";
import { prop, switchProp, theme } from "styled-tools";
import { useUser } from "@app/providers";

interface Props {
  request: VehicleRequest;
  variant?: "default" | "secondary";
  columns?: number;
  hideHistory?: boolean;
}

const StyledVehicleRequestDetail = styled.div<
  Pick<Props, "variant" | "columns">
>`
  padding: 16px 24px;
  box-sizing: border-box;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(${prop("columns")}, 1fr);
  background-color: ${switchProp("variant", {
    default: theme("color.white"),
    secondary: "#F5F6F8",
  })};
`;

const StyledTitle = styled.p`
  margin-top: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.dark")};
`;

function VehicleRequestDetail(props: Props) {
  const {
    request,
    variant = "default",
    columns = 1,
    hideHistory = false,
  } = props;
  const [loading, setLoading] = useState(true);
  const { user } = useUser();
  const isCustomer = user && isUser(user.role);
  const [historyData, setHistoryData] = useState<string[][]>([]);

  useEffect(() => {
    setTimeout(() => {
      const data = request.histories.map((history) => [
        dateFormatWithTimezone(history.created, "dd.MM.yyyy HH:mm"),
        getFullName(history.user),
        history.text,
        history.comment,
      ]);
      setHistoryData(data);
      setLoading(false);
    }, 1000);
  }, [request.histories]);

  const historyLabels = ["Дата выполнения", "ФИО", "Статус", "Комментарий"];

  if (loading) {
    return <Loader size={"default"} />;
  }

  return (
    <StyledVehicleRequestDetail variant={variant} columns={columns}>
      <Info
        variant={variant}
        label="Организация"
        value={getDictionaryName(request.organization, "-").replace(
          "Товарищество с ограниченной ответственностью",
          ""
        )}
      />
      <Info
        variant={variant}
        label="Проект"
        value={getDictionaryName(request.project, "-")}
      />
      <Info
        variant={variant}
        label="Вид техники"
        value={getDictionaryName(request.vehicleType, "-")}
      />
      <Info
        variant={variant}
        label="Характеристика"
        value={getDictionaryName(request.characteristics, "-")}
      />
      <Info
        variant={variant}
        label="Вид услуги"
        value={getDictionaryName(request.serviceType, "-")}
      />
      <Info
        variant={variant}
        label="Объём работ"
        value={request.volume.toString()}
      />
      <Info
        variant={variant}
        label="Дата начало"
        value={dateFormat(request.startDate, "dd.MM.yyyy HH:mm")}
      />
      <Info
        variant={variant}
        label="Дата завершения"
        value={dateFormat(request.endDate, "dd.MM.yyyy HH:mm")}
      />
      <Info
        variant={variant}
        label="Единица измерения работ"
        value={getDictionaryName(request.unit, "-")}
      />
      {/*<Info*/}
      {/*  variant={variant}*/}
      {/*  label="Ориентировачная цена без НДС"*/}
      {/*  value={NO_API_DATA}*/}
      {/*/>*/}
      {/*<Info*/}
      {/*  variant={variant}*/}
      {/*  label="Ориентировачная цена с НДС"*/}
      {/*  value={NO_API_DATA}*/}
      {/*/>*/}
      <Info
        variant={variant}
        label="Комментарий"
        value={!!request.comments ? request.comments : "-"}
      />
      {!!request.confirmer && (
        <>
          <Info
            variant={variant}
            label="ФИО диспетчера"
            value={getFullName(request.confirmer)}
          />
          <Info
            variant={variant}
            label="Контакты диспетчера"
            value={request.confirmer.phone || "-"}
          />
        </>
      )}
      {!hideHistory && (
        <>
          <StyledTitle>История согласования</StyledTitle>
          <Table labels={historyLabels} data={historyData} />
        </>
      )}
    </StyledVehicleRequestDetail>
  );
}

export default memo(VehicleRequestDetail);
