import { Project } from "./Project";
import { Company, CompanyAddress } from "./Company";
import { Confirmer } from "./User";
import { Vehicle, VehicleType } from "./Vehicle";
import { ServiceType } from "./Service";
import { WorkShift } from "./WorkShift";
import { FileObject, Unit } from "./Default";
import { Characteristic } from "./Characteristic";
import { Nomenclature } from "./Nomenclature";
import { AdvancedPaymentType } from "./PaymentType";
import { NdsShort } from "./NDS";
import { CollateralType } from "./CollateralType";
import { BankAccount } from "./Bank";
import { UserTaskStatuses, UserTaskType } from "./Task";
import { ContractDraftShort } from "./Contract";

export enum SmartContractStatus {
  Draft = 1,
  Singing = 2,
  CustomerSigned = 3,
  PartnerSigned = 4,
  Signed = 5,
  Revoked = 6,
  Rejected = 7,
  OnApproval = 8,
  Approved = 9,
  RejectedApproval = 10,
}

export const SmartContractStatusName: Record<SmartContractStatus, string> = {
  [SmartContractStatus.Draft]: "Создан",
  [SmartContractStatus.Singing]: "На подписании",
  [SmartContractStatus.CustomerSigned]: "Подписан заказчиком",
  [SmartContractStatus.PartnerSigned]: "Подписан исполнителем",
  [SmartContractStatus.Signed]: "Подписан обоюдно",
  [SmartContractStatus.Revoked]: "Отозван",
  [SmartContractStatus.Rejected]: "Отклонён",
  [SmartContractStatus.OnApproval]: "На согласовании",
  [SmartContractStatus.Approved]: "Согласован",
  [SmartContractStatus.RejectedApproval]: "Отклонен при согласовании",
};

export enum SmartContractVehicleType {
  WheeledVehicle = 1,
  GPM = 2,
  WheeledExtra = 3,
  GPMExtra = 4,
  Termination = 5,
  PriceChangeWheeledExtra = 6,
  PriceChangeGPMExtra = 7,
}

export const SmartContractVehicleTypeName: Record<
  SmartContractVehicleType,
  string
> = {
  [SmartContractVehicleType.WheeledVehicle]: "Колесная техника",
  [SmartContractVehicleType.GPM]: "ГПМ",
  [SmartContractVehicleType.WheeledExtra]: "(Доп. соглашение) Колесная техника",
  [SmartContractVehicleType.GPMExtra]: "(Доп. соглашение) - ГПМ",
  [SmartContractVehicleType.PriceChangeWheeledExtra]:
    "(Доп. соглашение. Изменение цены) Колесная техника",
  [SmartContractVehicleType.PriceChangeGPMExtra]:
    "(Доп. соглашение. Изменение цены) - ГПМ",
  [SmartContractVehicleType.Termination]: "Расторжение",
};

export enum SmartContractReasonType {
  // Изменение цены
  PriceChange = 10,
  // Расторжение
  Termination = 20,
  // Другое
  Other = 30,
}

export type SmartContractAdditionalReason = {
  id: string;
  name: string;
  typeId: SmartContractReasonType;
  stepIndex: number;
};

export type SmartContractSigner = Confirmer & {
  workBasis: string;
};

export type SmartContractVehicle = Pick<Vehicle, "govNumber"> & {
  vehicleId?: string;
  requestId?: string;
  vehicleType: VehicleType;
  serviceType: ServiceType;
  workShift: WorkShift;
  unit: Unit | null;
  characteristics: Characteristic | null;
  nomenclature: Nomenclature | null;
  volume: number;
  height: number;
  craneBoomReach: number;
  loadCapacity: number;
  startDate: string | null;
  endDate: string | null;
  price: number;
  priceWithoutNds: number;
  totalPrice?: number;
  totalPriceWithoutNds?: number;
  requestType: 1 | 2;
  vehicle?: Vehicle | null;
};

export type Approver = {
  user: Confirmer;
  taskTypeId: UserTaskType;
  date: string;
  status: UserTaskStatuses;
  comment: string;
  ord: number;
};

export type SmartContractHistory = {
  comment: string;
  created: string;
  contractDraftId: string;
  statusId: SmartContractStatus;
  user: Confirmer;
  text: string;
};

export interface SmartContract {
  id: string;
  contractDate: string;
  customer: Company;
  endDate: string;
  name: string;
  number: string;
  partner: Company;
  project: Project;
  parent: ContractDraftShort | null;
  startDate: string;
  status: SmartContractStatus;
  contractType: SmartContractVehicleType;
  customerSigner: SmartContractSigner | null;
  partnerSigner: SmartContractSigner | null;
  customerAddress: CompanyAddress | null;
  customerBankAccount: BankAccount | null;
  partnerAddress: CompanyAddress | null;
  partnerBankAccount: BankAccount | null;
  vehicles: SmartContractVehicle[];
  histories: SmartContractHistory[];
  approvers: Approver[];
  advancePaymentAmount: number | null;
  advancePaymentType: AdvancedPaymentType | null;
  amount: number | null;
  nds: NdsShort | null;
  collateralType: CollateralType;
  isOrderedApproval: boolean;
  isContractAccepted: boolean;
  file: {
    id: string;
    name: string;
    url: string;
  } | null;
  pdf: {
    id: string;
    name: string;
    url: string;
  } | null;
  signFile: {
    id: string;
    name: string;
    url: string;
  } | null;
  signedPdf: {
    id: string;
    name: string;
    url: string;
  } | null;
  files: FileObject[];
  otherFiles: FileObject[];
  pdfFiles: FileObject[] | null;
  signedPdfFiles: FileObject[] | null;
  signFiles: FileObject[] | null;
  additionalContractReasons: SmartContractAdditionalReason[];
  additionalContracts: unknown[];
  contractDraftNotifications: { userShort: Confirmer }[];
}
