import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Dialog, Modal, ModalProps } from "@app/components";
import styled from "styled-components";
import FileComponent from "../../components/File/File";
import { VehicleJournalDetail } from "@app/models";
import {
  createVehicleFile,
  getVehicleFileTypes,
  removeVehicleFile,
  uploadVehicleFile,
} from "@app/api";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import { useNotification } from "@app/providers";

interface FileType {
  id: string;
  name: string;
}

interface UploadedFileResponse {
  id: string;
  name: string;
  description: string | null;
  path: string;
  isDeleted: boolean;
}

interface VehicleFile {
  fileId: string;
  typeId: string;
}

interface Props extends Omit<ModalProps, "title" | "onClose"> {
  data: any;
  files: File[];
  onClose: (files: File[], updatedData?: VehicleJournalDetail) => void;
  updateJournalData: () => void;
}

const StyledDropZone = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 12px;
  background: #ffffff;
  border: 1px dashed #d8d8d8;
  border-radius: 4px;

  input {
    display: none;
  }
`;

const StyledDropZoneTitle = styled.div`
  color: #636463;

  .link {
    color: #4f8bff;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const StyledDropZoneCaption = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #acacac;
`;

function ModalVehicleJournalFiles(props: Props) {
  const { showNotification } = useNotification();
  const {
    data,
    files: initialFiles,
    onClose,
    updateJournalData,
    ...modalProps
  } = props;
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [selectedFileIndex, setSelectedFileIndex] = useState<number | null>(
    null
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFiles(initialFiles);
  }, [initialFiles]);

  const onClick = useCallback((): void => {
    inputRef.current!.click();
  }, []);

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const fileList = e.target.files;
      if (!fileList) {
        return;
      }
      setFiles([...files, ...Array.from(fileList)]);
      e.target.value = "";
    },
    [files]
  );

  const onFileDelete = useCallback((index: number) => {
    setSelectedFileIndex(index);
    setConfirmDialogOpen(true);
  }, []);

  const onConfirmDelete = useCallback(async (): Promise<void> => {
    if (selectedFileIndex !== null) {
      // @ts-ignore типы объектов прописаны неверно
      const fileId = files[selectedFileIndex].id;
      const findFile = data.files.find((item: any) => item.id === fileId);

      if (findFile?.vehicleJournalFileId) {
        try {
          await removeVehicleFile(findFile?.vehicleJournalFileId);

          showNotification({
            message: "Файл успешно удален",
            variant: "success",
          });

          const updatedFiles = files.slice();
          updatedFiles.splice(selectedFileIndex, 1);

          setFiles(updatedFiles);
          onClose(updatedFiles);
        } catch (error) {
          setConfirmDialogOpen(false);
          showNotification({
            message: getAxiosErrorMessage(error as AxiosError<any>),
            variant: "error",
          });
        }
      }
    }
    setConfirmDialogOpen(false);
  }, [selectedFileIndex, files, showNotification, data.files, onClose]);

  const onSave = useCallback(async (): Promise<void> => {
    setLoading(true);
    const newUploadedFiles: string[] = [];
    const filesData: VehicleFile[] = [];

    try {
      const vehicleFileTypesResponse = await getVehicleFileTypes();
      const vehicleFileTypes: FileType[] = vehicleFileTypesResponse.data;
      const requiredTypeId = vehicleFileTypes[0]?.id;

      if (!requiredTypeId) {
        new Error("Required typeId not found");
        return;
      }

      for (const file of files) {
        const formData = new FormData();
        formData.append("files", file);

        const uploadResponse = await uploadVehicleFile(formData);
        const fileDataArray: UploadedFileResponse[] = uploadResponse.data;

        for (const fileData of fileDataArray) {
          const fileId = fileData.id;
          newUploadedFiles.push(fileId);

          filesData.push({
            fileId: fileId,
            typeId: requiredTypeId,
          });
        }
      }

      const journalFile: { vehicleJournalId: string; files: VehicleFile[] } = {
        vehicleJournalId: data.id,
        files: filesData,
      };

      await createVehicleFile(journalFile);
      setUploadedFiles([...uploadedFiles, ...newUploadedFiles]);
      showNotification({
        message: "Файл успешно создан",
        variant: "success",
      });

      const updatedData: any = await updateJournalData();
      onClose(files, updatedData ?? data);
    } catch (error) {
      showNotification({
        message: getAxiosErrorMessage(error as AxiosError<any>),
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [
    files,
    data,
    onClose,
    uploadedFiles,
    updateJournalData,
    showNotification,
  ]);

  const actions = useMemo(
    () => [<Button text="Сохранить" onClick={onSave} showLoader={loading} />],
    [onSave, loading]
  );

  if (!modalProps.open) {
    return null;
  }

  return (
    <>
      <Modal
        {...modalProps}
        title="Документы"
        actions={actions}
        size="small"
        onClose={() => onClose(files)}
      >
        <StyledDropZone>
          <StyledDropZoneTitle>
            <span className="link" onClick={onClick}>
              Загрузите
            </span>{" "}
            документ или перетащите его сюда
          </StyledDropZoneTitle>
          <StyledDropZoneCaption>
            Форматы: doc, docx, pdf, png, jpeg; Максимальный размер: 3 мб
          </StyledDropZoneCaption>
          <input
            type="file"
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
            ref={inputRef}
            onChange={onChangeInput}
            multiple={false}
          />
          {files.map((file, fileIndex) => (
            <FileComponent
              key={fileIndex.toString()}
              file={file}
              fileIndex={fileIndex}
              onDelete={onFileDelete}
            />
          ))}
        </StyledDropZone>
      </Modal>

      <Dialog
        open={confirmDialogOpen}
        title="Подтвердите удаление"
        subtitle="Вы уверены, что хотите удалить этот файл?"
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={onConfirmDelete}
        loading={loading}
        confirmLabel="Удалить"
        cancelLabel="Отмена"
      />
    </>
  );
}

export default memo(ModalVehicleJournalFiles);
