import { ActOfWork, Dictionary } from "@app/models";
import { AvrCreateForm, NomenclatureData } from "./constants";
import { SelectOption } from "@app/components";
import { ActOfProvidedWorkDTO } from "@app/api";
import { dateFormat, MONTH_UNIT_ID } from "@app/helpers";

export const nomenclatureInit = {
  name: "",
  executionDate: null,
  unit: null,
  quantity: "",
  price: "",
  sum: "",
  sumNds: "",
};

export const prepareNomenclatures = (
  actOfWork: ActOfWork | null
): NomenclatureData[] => {
  if (!actOfWork) {
    return [];
  }

  return actOfWork.nomenclatures.map((item) => ({
    name: item.name,
    executionDate: new Date(item.executionDate),
    unit: {
      label: item.unit.name,
      value: item.unit.id,
    },
    quantity: item.quantity ? item.quantity.toString() : "",
    price: item.price ? item.price.toString() : "",
    sum: item.sum ? item.sum.toString() : "",
    sumNds:
      item.sumNds !== null
        ? item.sumNds === 0
          ? "0"
          : item.sumNds.toString()
        : "",
  }));
};

export const getNomenclatureSum = (
  item: NomenclatureData,
  nds?: SelectOption<Dictionary> | null
) => {
  try {
    if (item.sum && item.sumNds) {
      return nds ? item.sumNds : item.sum;
    }

    const price = parseFloat(item.price.replace(",", "."));
    const quantity = parseFloat(item.quantity.replace(",", "."));

    let sum = price * quantity;

    if (nds) {
      const ndsRate = parseFloat(nds.label.replace("%", "")) / 100;
      sum = (sum / (1 + ndsRate)) * ndsRate;
    }

    if (isNaN(sum)) {
      return "0";
    }

    return sum.toFixed(2).replace(".", ",");
  } catch (e) {
    return "0";
  }
};

export const getTotalSum = (
  nomenclatures: NomenclatureData[],
  nds?: SelectOption<Dictionary> | null
) => {
  try {
    return nomenclatures
      .reduce(
        (acc, item) =>
          acc + Number(getNomenclatureSum(item, nds).replace(",", ".")),
        0
      )
      .toFixed(2)
      .replace(".", ",");
  } catch (e) {
    return "-";
  }
};

export const getTotalCount = (nomenclatures: NomenclatureData[]) => {
  try {
    const unit = nomenclatures[0].unit?.value || "";

    if (!unit || !nomenclatures.every((item) => item.unit?.value === unit)) {
      return "-";
    }

    return nomenclatures
      .reduce((acc, item) => acc + Number(item.quantity), 0)
      .toFixed(2);
  } catch (e) {
    return "-";
  }
};

export const getTotalPrice = (nomenclatures: NomenclatureData[]) => {
  try {
    const price = nomenclatures[0].price || "0";

    if (!price || !nomenclatures.every((item) => item.price === price)) {
      return "-";
    }

    return price;
  } catch (e) {
    return "-";
  }
};

export const getAvrPayload = (
  values: AvrCreateForm,
  nomenclatures: NomenclatureData[],
  basisId: string,
  actId?: string
): ActOfProvidedWorkDTO => {
  return {
    senderCompanyId: values.senderCompany?.value,
    recipientCompanyId: values.recipientCompany?.value,
    projectId: values.project?.value,
    actId: actId || "",
    registrationNumber: values.registrationNumber,
    date: dateFormat(values.date, "yyyy-MM-dd'T'HH:mm:ss"),
    startDate: dateFormat(values.startDate, "yyyy-MM-dd'T'HH:mm:ss"),
    endDate: dateFormat(values.endDate, "yyyy-MM-dd'T'HH:mm:ss"),
    contractId: values.contract?.value,
    senderCompanyAddress: values.senderCompanyAddress,
    recipientCompanyAddress: values.recipientCompanyAddress,
    ndsRate: values.nds!.label,
    basisId,
    invoiceNumber: values.invoiceNumber,
    nomenclatures: nomenclatures.map((item) => {
      const isMonth = item.unit?.value === MONTH_UNIT_ID;
      const price = Number(item.price.replace(",", "."));
      const sum = Number(getNomenclatureSum(item).replace(",", "."));
      const sumNds = Number(
        getNomenclatureSum(item, values.nds).replace(",", ".")
      );
      return {
        name: item.name,
        executionDate: item.executionDate!.toJSON(),
        unitId: item.unit?.value,
        quantity: isMonth ? 1 : Number(item.quantity.replace(",", ".")),
        price: isMonth ? sum : price,
        sum,
        sumNds,
      };
    }),
  };
};
