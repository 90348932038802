import React, { memo, useCallback, useState } from "react";
import { TextField } from "@app/components";
import styled from "styled-components";
import "./styles.scss";
import { IconSearch24 } from "@app/icons";

interface Props {
  initialText?: string;
  placeholder?: string;
  onSearch: (searchText: string) => void;
  withSearchButton?: boolean;
}

const StyledToolbarSearch = styled.div`
  display: flex;
  align-items: center;
  width: 480px;
`;

function ToolbarSearch(props: Props) {
  const {
    initialText = "",
    placeholder = "",
    onSearch,
    withSearchButton = false,
  } = props;
  const [searchText, setSearchText] = useState(initialText);

  const onChangeSearchText = useCallback(
    (text: string) => {
      setSearchText(text);

      if (!!onSearch && !text) {
        onSearch("");
      }
    },
    [onSearch]
  );

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      const { key } = e;

      if (!!onSearch && key === "Enter") {
        onSearch(searchText);
      }
    },
    [onSearch, searchText]
  );

  const onClickSearch = useCallback(() => {
    if (onSearch) {
      onSearch(searchText);
    }
  }, [onSearch, searchText]);

  return (
    <StyledToolbarSearch>
      <TextField
        value={searchText}
        placeholder={placeholder}
        onChange={onChangeSearchText}
        onKeyPress={onKeyPress}
      />
      {withSearchButton && (
        <button
          className="toolbar-search__button"
          onClick={onClickSearch}
          disabled={!searchText.trim()}
          aria-label="Поиск"
        >
          <IconSearch24 color={"#000"} />
        </button>
      )}
    </StyledToolbarSearch>
  );
}

export default memo(ToolbarSearch);
