import { RefObject, useCallback, useEffect, useState } from "react";

type ReturnType = [boolean, () => void];

export const useOutsideClick = (
  wrapperRef: RefObject<HTMLDivElement>
): ReturnType => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const onClick = useCallback(() => {
    setIsVisible((prev) => !prev);
  }, []);

  const onClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target as HTMLElement)
      ) {
        setIsVisible(false);
      }
    },
    [wrapperRef]
  );

  useEffect(() => {
    document.addEventListener("click", onClickOutside, true);

    return () => {
      document.removeEventListener("click", onClickOutside, true);
    };
  }, [onClickOutside]);

  return [isVisible, onClick];
};
