import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Button,
  Grid,
  PhoneField,
  TextField,
  Toggle,
  useForm,
} from "@app/components";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotification, useUser } from "@app/providers";
import { APIResponse, SignInData } from "@app/api";
import * as yup from "yup";
import "./styles.scss";
import { AxiosError } from "axios";
import { getAxiosErrorMessage } from "@app/helpers";
import { IconEye24, IconEyeClosed24 } from "@app/icons";
import {
  ModalEnterPhone,
  ModalPasswordRecovery,
  ModalSMSVerification,
} from "@app/modals";
import qs from "qs";

const schema = yup.object().shape({
  username: yup.string().required("Поле обязательно к заполнению"),
  password: yup.string().required("Поле обязательно к заполнению"),
});

function FormSignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useUser();
  const { showNotification } = useNotification();
  const [hidePassword, setHidePassword] = useState(true);
  const [showPhoneEnterModal, setShowPhoneEnterModal] = useState(false);
  const [showRecoveryModal, setShowRecoveryModal] = useState(false);
  const [showSMSVerifyModal, setShowSMSVerifyModal] = useState(false);
  const { values, errors, onChange, validate, setPending, pending } =
    useForm<SignInData>({
      values: {
        username: "",
        password: "",
        asPartner: true,
      },
      schema,
    });

  const onChangeAsPartner = useCallback(
    (val: boolean) => {
      onChange(val, "asPartner");
    },
    [onChange]
  );

  const onClickSubmit = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      setPending(true);

      await signIn({
        username: values.username.substring(1),
        password: values.password,
        asPartner: values.asPartner,
      });

      setPending(false);

      if (location.search) {
        const params = qs.parse(location.search, { ignoreQueryPrefix: true });
        if (params && params.returnUrl) {
          navigate(`${params.returnUrl}`);
          return;
        }
      }

      navigate("/main");
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [
    validate,
    setPending,
    signIn,
    values,
    location,
    navigate,
    showNotification,
  ]);

  const onAppendClick = useCallback(() => {
    setHidePassword((prevState) => !prevState);
  }, []);

  const onLabelLinkClick = useCallback(() => {
    setShowPhoneEnterModal(true);
  }, []);

  const onPhoneEnterModalClose = useCallback((val?: boolean) => {
    setShowPhoneEnterModal(false);
    if (val) {
      setShowSMSVerifyModal(true);
    }
  }, []);

  const onDialogClose = useCallback((val?: boolean) => {
    setShowSMSVerifyModal(false);
    if (val) {
      setShowRecoveryModal(true);
    }
  }, []);

  const onRecoveryModalClose = useCallback((val?: boolean) => {
    setShowRecoveryModal(false);
    if (val) {
      // navigate?
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        onClickSubmit();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClickSubmit]);

  return (
    <div className="f-sign-in">
      <form onSubmit={onClickSubmit}>
        <Grid gap={24}>
          <Grid>
            <Toggle
              negativeLabel="Партнер"
              positiveLabel="Застройщик"
              value={!values.asPartner}
              onClick={(val) => onChangeAsPartner(!val)}
            />

            <PhoneField
              label="Номер телефона"
              placeholder="Введите номер телефона"
              value={values.username}
              name="username"
              error={!!errors.username}
              helperText={errors.username}
              onChange={(value) => onChange(value, "username")}
            />
            <TextField
              label="Пароль"
              placeholder="Введите пароль"
              value={values.password}
              name="password"
              onChange={(value) => onChange(value, "password")}
              secureTextEntry={hidePassword}
              error={!!errors.password}
              helperText={errors.password}
              labelLinkText="Забыли пароль?"
              appendIcon={hidePassword ? <IconEye24 /> : <IconEyeClosed24 />}
              onAppendClick={onAppendClick}
              onLabelLinkClick={onLabelLinkClick}
            />
          </Grid>
          <Button
            text="Войти"
            disabled={pending}
            showLoader={pending}
            onClick={onClickSubmit}
          />
        </Grid>
      </form>
      <ModalEnterPhone
        open={showPhoneEnterModal}
        onClose={onPhoneEnterModalClose}
      />
      <ModalSMSVerification
        open={showSMSVerifyModal}
        persistent
        onClose={onDialogClose}
      />
      <ModalPasswordRecovery
        open={showRecoveryModal}
        onClose={onRecoveryModalClose}
      />
    </div>
  );
}

export default memo(FormSignIn);
