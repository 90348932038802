import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useNotification, useUser } from "@app/providers";
import { getActById } from "@app/api";
import {
  ActDetail,
  ActVehicleItemV2,
  UserTaskStatusesName,
  UserTaskTypeName,
  VehicleJournalForActV2,
} from "@app/models";
import { Grid, ListItem, Table } from "@app/components";
import { ActHistory } from "@app/common";
import {
  actTableLabels,
  currencyFormat,
  dateFormat,
  excludeVAT,
  getActOfWorkNomenclaturePrice,
  getActOfWorkNomenclatureQuantity,
  getActOfWorkPayAmount,
  getActOfWorkPrice,
  getFullName,
  NIGHT_SHIFT_ID,
  roundToTwo,
} from "@app/helpers";
import { IconDay24, IconNight24 } from "@app/icons";
import styled from "styled-components";
import { theme } from "styled-tools";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  table {
    page-break-inside: auto;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  td {
    border-bottom: 1px solid ${theme("color.grayLight")};
  }

  hr {
    border-top: 1px solid ${theme("color.grayLight")};
    width: 100%;
    float: left;
    height: 0;
  }

  @page {
    size: A4 landscape;
  }

  @media print {
    padding: 0;
    button {
      display: none;
    }
  }
`;

const StyledTableHead = styled.th`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 14px 8px;
  text-align: left;
`;

const StyledTableExtraCell = styled.td`
  padding: 8px;
  width: 1%;
  white-space: nowrap;
  text-align: left;
  font-family: ${theme("fontFamily")};
  font-weight: bold;
  color: ${theme("color.dark")};
  border-top: 1px solid ${theme("color.grayLight")};
  background-color: ${theme("color.yellowLight")};
`;

const ParentCell = styled.td`
  font-size: 14px;
  font-weight: 600;
  padding: 8px;
  border-top: 1px solid ${theme("color.grayLight")};
  background-color: ${theme("color.blueLight")};
`;

const ChildCell = styled.td<{ odd: boolean }>`
  font-size: 14px;
  padding: 0 8px;
  font-family: ${theme("fontFamily")};
  color: ${theme("color.dark")};
  background-color: ${(props) =>
    theme(props.odd ? "color.background" : "color.white")};
`;

const PrintButton = styled.button`
  border: none;
  background: lightgray;
  outline: none;
  cursor: pointer;
`;

const CustomCell = styled.div<{ width: number }>`
  width: ${(props) => `${props.width}px`};
  white-space: break-spaces;
  p {
    font-size: 14px;
    padding: 0 12px;
    font-family: ${theme("fontFamily")};
    color: ${theme("color.dark")};
  }
`;

const WrappedCell = styled.div`
  max-width: 340px;
  font-size: 14px;
  line-height: 16px;
  white-space: normal;
`;

const OneLineCell = styled.div`
  white-space: nowrap;
`;

const ThinCell = styled.div`
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

function PageActPrinting() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticated } = useUser();
  const [, setPending] = useState<boolean>(true);
  const [act, setAct] = useState<ActDetail | null>(null);
  const [actVehicles, setActVehicles] = useState<VehicleJournalForActV2[]>([]);
  const { showNotification } = useNotification();

  const signersTableLabels = [
    "№",
    "Вид задачи",
    "ФИО",
    "Результат",
    "Дата выполнения",
    "Комментарий",
  ];

  const getActDetails = useCallback(
    (id: string) => {
      getActById(id)
        .then(({ succeeded, data }) => {
          setPending(false);

          if (!succeeded) {
            showNotification({
              message: "Error",
              variant: "error",
            });
            return;
          }

          setAct(data);
          if (data.actVehiclesDto) {
            setActVehicles(data.actVehicleGroupDto ?? []);
          }

          setTimeout(() => {
            // window.print();
          }, 700);
        })
        .catch(() => {
          showNotification({
            message: "Error",
            variant: "error",
          });
          setPending(false);
        });
    },
    [showNotification]
  );

  useEffect(() => {
    if (params.actId) {
      if (authenticated) {
        getActDetails(params.actId);
      } else {
        navigate(`/sign-in`);
      }
    } else {
      navigate("/");
    }
  }, [authenticated, getActDetails, location, navigate, params]);

  const getMapTableData = useCallback((array: ActVehicleItemV2[]) => {
    return array.map((item, index) => {
      if (!item) {
        return [];
      }

      return [
        "",
        `${index + 1}`,
        `${dateFormat(item.workDate, "dd.MM.yyyy")}`,
        item.shiftTypeDto?.id === NIGHT_SHIFT_ID ? (
          <IconNight24 />
        ) : (
          <IconDay24 />
        ),
        <p style={{ minWidth: "82px", fontSize: "12px" }}>
          {dateFormat(item.startTime, "HH:mm")} -{" "}
          {dateFormat(item.endTime, "HH:mm")}
        </p>,
        <WrappedCell>
          {[item.vehicleTypeDto?.name, item.nomenclature?.name]
            .filter(Boolean)
            .join(" - ")}
        </WrappedCell>,

        <ThinCell>{item.characteristic?.name}</ThinCell>,
        item.serviceType?.name,
        <OneLineCell>{item.govNumber}</OneLineCell>,
        item.workPerformed,
        item.hoursGps || 0,
        item.shiftDuration,
        item.unitDto?.name,
        currencyFormat(getActOfWorkPrice(item)),
        getActOfWorkPayAmount(item),
        currencyFormat(roundToTwo(item.summa)),
        currencyFormat(roundToTwo(item.summaNds)),
        currencyFormat(roundToTwo(item.summa)), // total
        <ThinCell>{item.comment}</ThinCell>,
      ];
    });
  }, []);

  const reduceTableData = useCallback(
    (item: VehicleJournalForActV2) => {
      if (item && item.items) {
        return getMapTableData(item.items);
      }

      return [];
    },
    [getMapTableData]
  );

  const mapTableCollapsedData = useCallback((item: VehicleJournalForActV2) => {
    if (!item) {
      return [];
    }

    return [
      <ParentCell />,
      <ParentCell colSpan={3} style={{ minWidth: "170px" }}>
        Итого по ед. изм.
      </ParentCell>,
      <ParentCell />,
      <ParentCell>{item.vehicleTypeName}</ParentCell>,
      <ParentCell>{item.characteristic?.name}</ParentCell>,
      <ParentCell />,
      <ParentCell>{item.govNumber}</ParentCell>,
      <ParentCell>{item.totalWorkPerformed}</ParentCell>,
      <ParentCell>{item.totalHoursGps}</ParentCell>,
      <ParentCell>{item.totalShiftDuration}</ParentCell>,
      <ParentCell>{item.unitDto?.name}</ParentCell>,
      <ParentCell>{getActOfWorkNomenclaturePrice(item)}</ParentCell>,
      <ParentCell>{getActOfWorkNomenclatureQuantity(item)}</ParentCell>,
      <ParentCell>
        {currencyFormat(roundToTwo(item.totalSumma || 0))}
      </ParentCell>,
      <ParentCell>
        {currencyFormat(roundToTwo(item.totalSummaNds || 0))}
      </ParentCell>,
      <ParentCell>
        {currencyFormat(roundToTwo(item.totalSumma || 0))}
      </ParentCell>,
      <ParentCell />,
    ];
  }, []);

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => actVehicles.map(mapTableCollapsedData),
    [actVehicles, mapTableCollapsedData]
  );

  const tableDataChildren = useMemo<(string | ReactNode)[][][]>(
    () => actVehicles.map(reduceTableData),
    [actVehicles, reduceTableData]
  );

  const reduceItemsSum = useCallback(
    (vehicleItems: ActVehicleItemV2[] | null, key: keyof ActVehicleItemV2) => {
      if (!vehicleItems) return 0;

      return vehicleItems.reduce((sum, item) => {
        if (key === "summaNds") {
          return sum + excludeVAT(item.summa, act?.nds.rate);
        }

        return sum + ((item[key] || 0) as number);
      }, 0);
    },
    [act?.nds.rate]
  );

  const reduceSum = useCallback(
    (key: keyof ActVehicleItemV2) => {
      const summa = actVehicles.reduce((acc, cur) => {
        return acc + reduceItemsSum(cur.items, key);
      }, 0);
      return currencyFormat(roundToTwo(summa));
    },
    [actVehicles, reduceItemsSum]
  );

  const tableExtraRow = useMemo<(string | ReactNode)[]>(
    () =>
      actTableLabels.slice(0, -1).map((_, index) => {
        if (index === 1) {
          return "Итого";
        } else if (index === 15) {
          return reduceSum("summa");
        } else if (index === 16) {
          return reduceSum("summaNds");
        } else if (index === 17) {
          return reduceSum("summa"); // total
        }
        return "";
      }),
    [reduceSum]
  );

  const constructivesTableLabels = useMemo<(string | ReactNode)[]>(
    () => ["№", "Конструктив", "Участок/Пятно", "Сумма", "", ""],
    []
  );

  const constructivesTableData = useMemo(
    () =>
      act?.actConstructiveDto?.map((item, index) => [
        `${index + 1}.`,
        <CustomCell width={320}>
          <p>{item.constructive.name}</p>
        </CustomCell>,
        <CustomCell width={320}>
          <p>{item.projectSite || "—"}</p>
        </CustomCell>,
        <CustomCell width={180}>
          <p>{currencyFormat(item.sum)}</p>
        </CustomCell>,
      ]),
    [act?.actConstructiveDto]
  );

  const constructiveTableExtraRow = useMemo<(string | ReactNode)[]>(
    () =>
      constructivesTableLabels.map((_, index) => {
        if (index === 1) {
          return "Итого:";
        }
        if (index === 3) {
          const sum = act?.actConstructiveDto?.reduce(
            (acc, cur) => acc + cur.sum,
            0
          );
          return (
            <CustomCell width={180}>
              <p>{currencyFormat(sum || 0)}</p>
            </CustomCell>
          );
        }
        return "";
      }),
    [act?.actConstructiveDto, constructivesTableLabels]
  );

  return (
    <Container>
      <h2>{`Табель оказанных услуг №${act?.actNumber || ""}`}</h2>
      <Grid columns={4}>
        <ListItem title={act?.company.name} subtitle={"Организация"} />
        <ListItem title={act?.project?.name} subtitle={"Проект"} />
        <ListItem title={act?.partner?.name} subtitle={"Исполнитель"} />
        <ListItem title={act?.contract?.name} subtitle={"Договор"} />
      </Grid>
      <table>
        <thead>
          <tr>
            {actTableLabels.slice(0, -1).map((label, labelIndex) => (
              <StyledTableHead key={labelIndex.toString()}>
                {label}
              </StyledTableHead>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableExtraRow.map((val, index) => (
            <StyledTableExtraCell colSpan={1} key={index.toString()}>
              {val}
            </StyledTableExtraCell>
          ))}
          {tableData.map((values, valuesIndex) => (
            <React.Fragment key={valuesIndex.toString()}>
              <tr key={valuesIndex.toString()}>
                {values.map((value, valueIndex) => (
                  <React.Fragment key={valueIndex.toString()}>
                    {value}
                  </React.Fragment>
                ))}
              </tr>
              {tableDataChildren[valuesIndex].map((node, nodeIndex) => (
                <tr key={`${valuesIndex * 10 + nodeIndex}`}>
                  {node.map((nodeValue, nodeValueIndex) => (
                    <ChildCell
                      key={`${nodeIndex}${nodeValueIndex}`}
                      odd={nodeIndex % 2 !== 0}
                    >
                      {nodeValue}
                    </ChildCell>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <hr />
      {/* CONSTRUCTIVES */}
      {!!act?.actConstructiveDto?.length && (
        <>
          <h3>Распределение по конструктивам</h3>
          <Grid columns={2}>
            <Table
              labels={constructivesTableLabels}
              data={constructivesTableData}
              disableOverflow={true}
              extraRow={constructiveTableExtraRow}
            />
          </Grid>
        </>
      )}
      <hr />
      {/* SIGNERS */}
      <h3>Лица согласования</h3>
      <table>
        <thead>
          <tr>
            {signersTableLabels.map((label, labelIndex) => (
              <StyledTableHead key={labelIndex.toString()}>
                {label}
              </StyledTableHead>
            ))}
          </tr>
        </thead>
        <tbody>
          {(act?.actSignersDto || []).map((signer, sIndex) => {
            let modifiedDate = signer.lastModified;
            if (!modifiedDate && signer.termDate !== "0001-01-01T00:00:00") {
              modifiedDate = signer.termDate;
            }
            return (
              <tr key={sIndex.toString()}>
                <td>{sIndex + 1}</td>
                <td>{UserTaskTypeName[signer.taskTypeId]}</td>
                <td>
                  {signer.userShort
                    ? getFullName(signer.userShort)
                    : signer.fullName}
                </td>
                <td>{UserTaskStatusesName[signer.status]}</td>
                <td>{dateFormat(modifiedDate, "dd.MM.yyyy")}</td>
                <td>
                  <i>{signer.comment}</i>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <hr />
      {/* История согласования */}
      <ActHistory actId={act?.id} printing={true} />
      {/*  */}
      <PrintButton onClick={() => window.print()}>Печать</PrintButton>
    </Container>
  );
}

export default memo(PageActPrinting);
