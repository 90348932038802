import { SelectOption } from "components/Select";
import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import FileComponent from "../../../components/File/File";
import { Loader } from "@app/components";
import { CompanyFileTypes } from "@app/models";

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const StyledHeader = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 16px;
`;

const StyledDropZone = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 12px;
  background: #ffffff;
  border: 1px dashed #d8d8d8;
  border-radius: 4px;
  margin-bottom: 16px;
  height: 100px;

  input {
    display: none;
  }
`;

const StyledDropZoneTitle = styled.div`
  color: #636463;

  .link {
    color: #4f8bff;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #1c2246;
  margin-bottom: 12px;
`;

const StyledDropZoneCaption = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #acacac;
`;

interface Props {
  filterParams?: any;
  files: { [key: string]: File[] };
  setFiles: React.Dispatch<React.SetStateAction<{ [key: string]: File[] }>>;
  companyFileTypes: SelectOption<CompanyFileTypes>[];
  onFileDelete: (companyFileTypeId: string, fileIndex: number) => void;
  setIsStep2Saved: React.Dispatch<React.SetStateAction<boolean>>;
}

function Step2(props: Props) {
  const {
    filterParams,
    onFileDelete,
    files,
    setFiles,
    companyFileTypes,
    setIsStep2Saved,
  } = props;
  const [loadingFileType, setLoadingFileType] = useState<string | null>(null);

  const onChangeInput = useCallback(
    (companyFileTypeId: string) =>
      async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
          setLoadingFileType(companyFileTypeId);
          const newFiles = Array.from(e.target.files);
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setFiles((prevFiles) => ({
            ...prevFiles,
            [companyFileTypeId]: [
              ...(prevFiles[companyFileTypeId] || []),
              ...newFiles,
            ],
          }));
          setIsStep2Saved(false);
          setLoadingFileType(null);
        }
      },
    []
  );

  return (
    <StyledContent>
      <StyledHeader>Документы</StyledHeader>
      {companyFileTypes.map((companyFileType, companyFileTypeIndex) => (
        <StyledDropZone key={companyFileTypeIndex}>
          {loadingFileType === companyFileType.value ? (
            <Loader size="small" />
          ) : (
            <>
              <StyledTitle>{companyFileType.label}</StyledTitle>
              {!files[companyFileType.value] ||
              files[companyFileType.value].length === 0 ? (
                <>
                  <StyledDropZoneTitle>
                    <span
                      className="link"
                      onClick={() => {
                        const fileInput = document.getElementById(
                          `file-input-${
                            companyFileType && companyFileType.value
                          }`
                        );
                        if (fileInput) {
                          fileInput.click();
                        }
                      }}
                    >
                      Загрузите
                    </span>{" "}
                    документ или перетащите его сюда
                  </StyledDropZoneTitle>
                  <StyledDropZoneCaption>
                    Форматы: doc, docx, pdf, png, jpeg; Максимальный размер: 3
                    мб
                  </StyledDropZoneCaption>
                </>
              ) : null}
              <input
                type="file"
                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                id={`file-input-${companyFileType.value}`}
                onChange={onChangeInput(companyFileType.value)}
              />
              {files[companyFileType.value]?.map((file, fileIndex) => (
                <FileComponent
                  key={fileIndex}
                  file={file}
                  onDelete={() =>
                    onFileDelete(companyFileType.value, fileIndex)
                  }
                />
              ))}
            </>
          )}
        </StyledDropZone>
      ))}
    </StyledContent>
  );
}

export default memo(Step2);
