import React, { useCallback, useEffect, useState } from "react";
import { AxiosError } from "axios";
import {
  Button,
  DateTimePicker,
  Grid,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import { CompanyShort, Dictionary, Project, SmartContract } from "@app/models";
import {
  APIResponse,
  createActOfProvidedWork,
  getCompaniesFiltered,
  getContractsForAct,
  getMyCompanies,
  getMyProjects,
  getNdsByCompany,
  getProjects,
  PageableParams,
  PageableResponse,
  updateActOfProvidedWork,
} from "@app/api";
import {
  getAxiosErrorMessage,
  isDispatcher,
  isUser,
  setSelectOption,
} from "@app/helpers";
import { useNotification, useUser } from "@app/providers";
import { IconPlus20 } from "@app/icons";
import {
  StyledFooter,
  StyledHeader,
  StyledStep2,
  StyledTotal,
  StyledTotalText,
} from "../styled";
import { ActCompanyAddress } from "@app/common";
import {
  AvrCreateForm,
  avrCreateSchema,
  getAvrFormValues,
  NomenclatureData,
  Step2Props,
} from "../../constants";
import {
  getAvrPayload,
  getTotalCount,
  getTotalPrice,
  getTotalSum,
  nomenclatureInit,
  prepareNomenclatures,
} from "../../helper";
import NomenclatureListView from "./NomenclatureListView";

export default function (props: Step2Props) {
  const {
    onChange: propsOnChange,
    actOfWork,
    basis,
    disabled,
    setActOfWork,
    isActOfService,
  } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const [nomenclatures, setNomenclatures] = useState(
    prepareNomenclatures(actOfWork)
  );
  const [nomenclaturesError, setNomenclaturesError] = useState<{
    [key: string]: string;
  }>({});
  const { values, onChange, errors, pending, setPending, validate, setValues } =
    useForm<AvrCreateForm>({
      values: getAvrFormValues(actOfWork),
      schema: avrCreateSchema,
    });

  const [isNextDisabled, setIsNextDisabled] = useState(true);

  useEffect(() => {
    const checkFormValidity = async () => {
      const isValid = await validate();
      setIsNextDisabled(!isValid);
    };

    checkFormValidity();
  }, [values, validate]);

  const onClickBack = useCallback(() => {
    propsOnChange(1);
  }, [propsOnChange]);

  const resetSenderCompany = useCallback(() => {
    setValues({
      senderCompany: null,
      nds: null,
    });
  }, [setValues]);

  const onChangeSenderCompany = useCallback(
    async (value: SelectOption<CompanyShort> | null) => {
      try {
        onChange(value, "senderCompany");

        if (value) {
          const { succeeded, data: ndsData } = await getNdsByCompany(
            value.value
          );
          if (succeeded) {
            onChange(setSelectOption(ndsData), "nds");
          } else {
            resetSenderCompany();
          }
        } else {
          onChange(null, "nds");
        }
      } catch (e) {
        resetSenderCompany();
      }
    },
    [onChange, resetSenderCompany]
  );

  const onChangeNomenclature = useCallback(
    (value: any, name: string, index: number) => {
      setNomenclatures((prevNomenclatures) =>
        prevNomenclatures.map((item, itemIndex) => ({
          ...item,
          [name]:
            itemIndex === index ? value : item[name as keyof NomenclatureData],
        }))
      );
    },
    []
  );

  const onClickSave = useCallback(async () => {
    try {
      const isValid = await validate();

      const tempNomenclaturesError: { [key: string]: string } = {};

      if (nomenclatures.length === 0) {
        showNotification({
          variant: "error",
          message: "Добавьте номенклатуры",
        });

        return;
      }
      if (!basis) {
        showNotification({
          variant: "error",
          message: "Не указано основание",
        });

        return;
      }

      nomenclatures.forEach((item, itemIndex) => {
        Object.keys(item).forEach((fieldName) => {
          if (
            ["executionDate", "sum", "sumNds"].indexOf(
              fieldName as keyof NomenclatureData
            ) === -1 &&
            !item[fieldName as keyof NomenclatureData]
          ) {
            tempNomenclaturesError[`${itemIndex}.${fieldName}`] =
              "Обязательное поле";

            return;
          }
        });
      });

      setNomenclaturesError(tempNomenclaturesError);

      if (!isValid || Object.keys(tempNomenclaturesError).length > 0) {
        return;
      }

      setPending(true);

      let response, message;

      const data = getAvrPayload(
        values,
        nomenclatures,
        basis.id,
        actOfWork?.actId
      );

      if (actOfWork?.id) {
        response = await updateActOfProvidedWork({
          ...data,
          id: actOfWork.id,
        });
        message = "АВР успешно сохранен!";
      } else {
        response = await createActOfProvidedWork(data);
        message = "АВР успешно обновлен!";
      }
      showNotification({
        message,
        variant: "success",
      });

      setActOfWork(response.data);

      propsOnChange(3);
      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [
    validate,
    nomenclatures,
    setPending,
    values,
    actOfWork,
    setActOfWork,
    propsOnChange,
    showNotification,
    basis,
  ]);

  const onClickAddNomenclature = useCallback(() => {
    setNomenclatures((prevNomenclatures) => [
      ...prevNomenclatures,
      nomenclatureInit,
    ]);
  }, []);

  const onClickRemoveNomenclature = (index: number) => {
    setNomenclatures((prevNomenclatures) =>
      prevNomenclatures.filter((item, itemIndex) => itemIndex !== index)
    );
  };

  const onClickNext = useCallback(async () => {
    if (!disabled) {
      await onClickSave();
    }
  }, [disabled, onClickSave]);

  const getContracts = useCallback(() => {
    return getContractsForAct({
      partnerId: values?.senderCompany?.value,
      projectId: values?.project?.value,
      customerId: values?.recipientCompany?.value,
    });
  }, [values]);

  const renderSaveButton = useCallback(() => {
    if (disabled) {
      return null;
    }

    return (
      <Button
        text="Сохранить"
        onClick={onClickSave}
        disabled={pending}
        showLoader={pending}
      />
    );
  }, [disabled, onClickSave, pending]);

  const renderAddNomenclatureButton = useCallback(() => {
    if (!!actOfWork?.actId || disabled) {
      return null;
    }

    return (
      <div>
        <Button
          startIcon={IconPlus20}
          onClick={onClickAddNomenclature}
          disabled={pending}
        />
      </div>
    );
  }, [actOfWork?.actId, disabled, onClickAddNomenclature, pending]);

  const getCompanyList = (): ((
    params: PageableParams<CompanyShort>
  ) => Promise<PageableResponse<CompanyShort>>) => {
    // @ts-ignore не совпадают типы списка компании
    return isDispatcher(user!.role) ? getCompaniesFiltered : getMyCompanies;
  };

  return (
    <StyledStep2>
      <StyledHeader>
        <Grid>
          <Grid columns={5}>
            <Select<CompanyShort>
              label="Заказчик"
              name="recipientCompany"
              onChange={onChange}
              value={values.recipientCompany}
              error={!!errors.recipientCompany}
              helperText={errors.recipientCompany}
              valueKey="id"
              labelKeys={["name", "bin"]}
              labelKeysSeparator={" / "}
              loadData={getCompanyList()}
              disabled={pending || !!actOfWork?.actId || disabled}
            />
            <Select<CompanyShort>
              label="Исполнитель"
              name="senderCompany"
              onChange={onChangeSenderCompany}
              value={values.senderCompany}
              error={!!errors.senderCompany}
              helperText={errors.senderCompany}
              valueKey="id"
              labelKeys={["name", "bin"]}
              labelKeysSeparator={" / "}
              loadData={getCompanyList()}
              disabled={pending || !!actOfWork?.actId || disabled}
            />
            <DateTimePicker
              label="Дата начала"
              name="startDate"
              onChange={onChange}
              value={values.startDate}
              error={!!errors.startDate}
              helperText={errors.startDate}
              hideTime={true}
              disabled={pending || !!actOfWork?.actId || disabled}
            />
            <DateTimePicker
              label="Дата завершения"
              name="endDate"
              minDate={values.startDate}
              onChange={onChange}
              value={values.endDate}
              error={!!errors.endDate}
              helperText={errors.endDate}
              hideTime={true}
              disabled={pending || !!actOfWork?.actId || disabled}
            />
            <DateTimePicker
              label="Дата выставления АВР"
              name="date"
              onChange={onChange}
              value={values.date}
              error={!!errors.date}
              helperText={errors.date}
              hideTime={true}
              disabled={pending || disabled}
              paperRight={true}
            />
          </Grid>
          <Grid columns={5}>
            <Select<Project>
              label="Проект"
              name="project"
              onChange={onChange}
              value={values.project}
              error={!!errors.project}
              helperText={errors.project}
              valueKey="id"
              labelKey="name"
              loadData={
                !isDispatcher(user!.role) && isUser(user!.role)
                  ? getMyProjects
                  : getProjects
              }
              disabled={
                pending ||
                !!actOfWork?.actId ||
                disabled ||
                !values.recipientCompany
              }
            />
            <Select<SmartContract>
              label="Договор"
              name="contract"
              onChange={onChange}
              value={values.contract}
              error={!!errors.contract}
              helperText={errors.contract}
              loadData={getContracts}
              valueKey="id"
              labelKey="name"
              disabled={
                pending ||
                !!actOfWork?.actId ||
                disabled ||
                !values.senderCompany ||
                !values.recipientCompany ||
                !values.project
              }
            />
            <Select<Dictionary>
              label="Ставка НДС"
              name="nds"
              onChange={onChange}
              value={values.nds}
              valueKey="name"
              labelKey="name"
              disabled={true}
            />
            <TextField
              label="Номер АВР"
              name="registrationNumber"
              onChange={onChange}
              value={values.registrationNumber}
              error={!!errors.registrationNumber}
              helperText={errors.registrationNumber}
              disabled={pending || disabled}
            />
            {actOfWork?.recipientCompany.generateInvoiceInAvr ? (
              <TextField
                label="Номер счета на оплату"
                placeholder="Например: 001"
                name="invoiceNumber"
                value={values.invoiceNumber}
                error={!!errors.invoiceNumber}
                helperText={errors.invoiceNumber}
                disabled={pending || disabled}
                onChange={onChange}
              />
            ) : null}
          </Grid>
          <Grid columns={2}>
            <ActCompanyAddress
              label="Юридический адрес заказчика"
              name="recipientCompanyAddress"
              placeholder="Выберите адрес заказчика"
              onChange={onChange}
              value={values.recipientCompanyAddress}
              error={!!errors.recipientCompanyAddress}
              helperText={errors.recipientCompanyAddress}
              disabled={pending || disabled || !values.recipientCompany}
              companyId={values.recipientCompany?.value || ""}
            />
            <ActCompanyAddress
              label="Юридический адрес исполнителя"
              name="senderCompanyAddress"
              onChange={onChange}
              value={values.senderCompanyAddress}
              error={!!errors.senderCompanyAddress}
              helperText={errors.senderCompanyAddress}
              disabled={pending || disabled || !values.senderCompany}
              companyId={values.senderCompany?.value || ""}
            />
          </Grid>
        </Grid>
      </StyledHeader>
      <NomenclatureListView
        nomenclatures={nomenclatures}
        nomenclaturesError={nomenclaturesError}
        disabled={pending || !!actOfWork?.actId || disabled}
        withRemoveButton={!disabled && !isActOfService}
        addButtonComponent={renderAddNomenclatureButton}
        isActOfService={isActOfService}
        onChange={onChangeNomenclature}
        onRemove={onClickRemoveNomenclature}
      />
      {nomenclatures.length > 0 && (
        <StyledTotal>
          <StyledTotalText>Итого</StyledTotalText>
          <TextField
            disabled={true}
            value={getTotalCount(nomenclatures)}
            priceFormat={true}
          />
          <TextField
            disabled={true}
            value={getTotalPrice(nomenclatures)}
            priceFormat={true}
          />
          <TextField
            disabled={true}
            value={getTotalSum(nomenclatures)}
            priceFormat={true}
          />
          <TextField
            disabled={true}
            value={getTotalSum(nomenclatures, values.nds)}
            priceFormat={true}
          />
        </StyledTotal>
      )}
      <StyledFooter>
        <Button text="Назад" onClick={onClickBack} variant="text" />
        {renderSaveButton()}
        <Button
          text="Далее"
          onClick={onClickNext}
          disabled={pending || isNextDisabled}
        />
      </StyledFooter>
    </StyledStep2>
  );
}
