import React, { memo, useCallback, useMemo, useState } from "react";
import { LayoutDefault } from "@app/layouts";
import { TemplateSearch } from "@app/templates";
import { getVehicles } from "@app/api";
import { CompanyShort, VehicleListItem } from "@app/models";
import { Button } from "@app/components";
import { IconInfo20, IconPlus20 } from "@app/icons";
import ModalCreateVehicle from "../../modals/CreateVehicle/CreateVehicle";
import styled from "styled-components";
import ModalVehicleInformation from "../../modals/VehicleInformation";
import VehicleAlert from "./components/VehicleAlert";
import { ModalAddVehicleInstruction } from "@app/modals";

interface Props {}

const AlertRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;
  max-width: 1256px;
`;

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButton = styled.div`
  margin-left: 16px;
`;

function PageVehicles(_props: Props) {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  // const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const [vehicleItem, setVehicleItem] = useState<VehicleListItem | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [instructionModalVisible, setInstructionModalVisible] =
    useState<boolean>(false);

  const mapTableData = useCallback((item: VehicleListItem) => {
    return [
      item.vehicleType,
      item.characteristics,
      item.govNumber,
      item.vehicleModel?.name,
      item.gps,
      item.companies.map((company: CompanyShort) => company.name).join(", "),
    ];
  }, []);

  const closeInstructionModal = useCallback(() => {
    setInstructionModalVisible(false);
  }, []);

  const onClickInstruction = useCallback(() => {
    setInstructionModalVisible(true);
  }, []);

  const onAlertClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const onModalClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const onClickAdd = useCallback(() => {
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setVehicleItem(null);
    setModalVisible(false);
  }, []);

  const onItemClick = useCallback((item: VehicleListItem) => {
    setVehicleItem(item);
    setModalVisible(true);
  }, []);

  const tableLabels = useMemo(
    () => [
      "Вид техники",
      "Характеристика",
      "Гос номер",
      "Модель",
      "Провайдер GPS",
      "Организации",
    ],
    []
  );

  const leftControls = useMemo(
    () => [
      <ButtonsRow>
        <Button
          text="Добавить спецтехнику"
          startIcon={IconPlus20}
          onClick={onClickAdd}
        />
        <StyledButton>
          <Button
            text="Смотреть инструкцию"
            variant="newDesign"
            startIcon={IconInfo20}
            onClick={onClickInstruction}
          />
        </StyledButton>
      </ButtonsRow>,
    ],
    [onClickAdd, onClickInstruction]
  );

  const bottomControls = useMemo(
    () => [
      <AlertRow>
        <VehicleAlert onClick={onAlertClick} />
      </AlertRow>,
    ],
    [onAlertClick]
  );

  return (
    <LayoutDefault title="Моя техника">
      <TemplateSearch<VehicleListItem>
        toolbarProps={{
          leftControls: leftControls,
          bottomControls: bottomControls,
          searchPlaceholder:
            "Вид техники, характеристика, гос номер, модель, провайдер GPS",
        }}
        getData={getVehicles}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        onClick={onItemClick}
      />
      <ModalCreateVehicle
        open={modalVisible}
        size={"medium"}
        data={vehicleItem}
        onClose={closeModal}
      />

      <ModalVehicleInformation open={showModal} onClose={onModalClose} />
      <ModalAddVehicleInstruction
        open={instructionModalVisible}
        onClose={closeInstructionModal}
      />
      {/*<ModalVehicleDetail*/}
      {/*  open={detailVisible}*/}
      {/*  onClose={closeDetailModal}*/}
      {/*  data={vehicleItem}*/}
      {/*/>*/}
    </LayoutDefault>
  );
}

export default memo(PageVehicles);
