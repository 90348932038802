import { IconMail24, IconPhone24 } from "@app/icons";
import styled from "styled-components";
import { theme } from "styled-tools";
import { contactsInfo } from "@app/helpers";

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme("color.grayLight")};
  border-radius: 16px;
  padding: 16px;
  gap: 16px;

  h3 {
    margin: 0 0 4px;
  }
`;

const SectionInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledText = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: unset;
  color: ${theme("color.dark")};

  &:hover {
    text-decoration: underline;
  }
`;

const ContactsInfo = () => {
  return (
    <StyledContent>
      <h2>Контакты</h2>
      <InfoRow>
        <h3>Служба поддержки</h3>
        <StyledText href={"tel:+77710460533"}>
          <IconPhone24 />
          +7 771 046 05 33
        </StyledText>
        <StyledText href={"mailto:support@dispatchcds.com"}>
          <IconMail24 />
          support@dispatchcds.com
        </StyledText>
      </InfoRow>
      <InfoRow>
        <h3>Контакты для сотрудничества</h3>
        {contactsInfo.map((item, index) => (
          <SectionInfo key={index.toString()}>
            <p>{item.name}</p>
            <StyledText href={`tel:${item.phone}`}>
              <IconPhone24 />
              {item.phone}
            </StyledText>
            <StyledText href={`mailto:${item.email}`}>
              <IconMail24 />
              {item.email}
            </StyledText>
          </SectionInfo>
        ))}
      </InfoRow>
    </StyledContent>
  );
};

export default ContactsInfo;
