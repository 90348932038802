import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { AxiosError } from "axios";
import { LayoutDefault } from "@app/layouts";
import {
  APIResponse,
  deleteSmartContract,
  getCompanies,
  getMyCompanies,
  getMyProjects,
  getProjects,
  getSmartContract,
  getSmartContracts,
} from "@app/api";
import { TemplateSearch } from "@app/templates";
import {
  Button,
  Combobox,
  DateTimePicker,
  DefaultObject,
  Dialog,
  Select,
  SelectOption,
} from "@app/components";
import { IconPlus20, IconTrash24 } from "@app/icons";
import {
  Company,
  Project,
  SmartContract,
  SmartContractStatus,
  SmartContractStatusName,
  SmartContractVehicleType,
  SmartContractVehicleTypeName,
} from "@app/models";
import { ModalSmartContractDetail } from "@app/modals";
import {
  companyLabelKeys,
  dateFormat,
  enumToArray,
  getAxiosErrorMessage,
  isDispatcher,
} from "@app/helpers";
import { MoreMenu, SmartContractStatusChip } from "@app/common";
import {
  NotificationTypeNames,
  NotificationTypes,
} from "../../providers/Firebase/Firebase";
import { useNotification, useUser } from "@app/providers";
import { useDebounce } from "@app/hooks";

const FILTER_STORAGE_KEY = "smartContractsFilters";

interface FilterParamsProps {
  numbers: string[];
  companies: Company[] | null;
  partners: Company[] | null;
  projects: Project[] | null;
  statuses: DefaultObject<SmartContractStatus>[] | null;
  vehicleTypes: DefaultObject<SmartContractVehicleType>[] | null;
  startDate: Date | null;
  endDate: Date | null;
  isAdditional: SelectOption<boolean>;
}

interface FilterValuesProps {
  Numbers?: string[];
  CustomerIds: string[];
  PartnerIds: string[];
  ProjectIds: string[];
  StatusIds: (string | number)[];
  isAdditional?: boolean;
  EContractTemplateTypeIds: (string | number)[];
  ContractDateMin?: string;
  ContractDateMax?: string;
}

const initialFiltersParams = JSON.stringify({
  companies: [],
  numbers: [],
  endDate: null,
  partners: [],
  projects: [],
  startDate: null,
  isAdditional: undefined,
  statuses: [],
  vehicleTypes: [],
});

function PageSmartContracts() {
  const { asPartner, user } = useUser();

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [contract, setContract] = useState<Partial<SmartContract> | null>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<FilterParamsProps>(() => {
    const savedFilters = localStorage.getItem(FILTER_STORAGE_KEY);
    return savedFilters
      ? JSON.parse(savedFilters)
      : JSON.parse(initialFiltersParams);
  });

  const { showNotification } = useNotification();

  const menuItems = useCallback(
    (item: SmartContract) => [
      {
        icon: IconTrash24,
        label: "Удалить",
        onClick: () => {
          setContract(item);
          setShowDialog(true);
        },
      },
    ],
    []
  );

  const tableLabels = useMemo(
    () => [
      "№",
      "Заказчик",
      "Исполнитель/Контрагент",
      "Проект",
      "Дата создания",
      "Дата начала",
      "Дата завершения",
      "Статус",
      "",
    ],
    []
  );

  const mapTableData = useCallback(
    (item: SmartContract) => {
      return [
        item.number || "-",
        item.customer.name,
        item.partner.name,
        item.project.name,
        item.contractDate ? dateFormat(item.contractDate, "dd.MM.yyyy") : "-",
        item.startDate ? dateFormat(item.startDate, "dd.MM.yyyy") : "-",
        item.endDate ? dateFormat(item.endDate, "dd.MM.yyyy") : "-",
        <SmartContractStatusChip status={item.status} />,
        !asPartner && <MoreMenu items={menuItems(item)} />,
      ];
    },
    [asPartner, menuItems]
  );

  const onModalClose = useCallback(
    (refresh = false) => {
      setModalVisible(false);
      setContract(null);
      if (refresh) {
        setFilterParams({ ...filterParams });
      }
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete("contractId");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
    },
    [filterParams]
  );

  const onClickAdd = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onItemClick = useCallback((clickedContract: SmartContract) => {
    setModalVisible(true);
    setContract(clickedContract);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("contractId", clickedContract.id);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    for (const [key, value] of queryParams) {
      if (key === "contractId") {
        getSmartContract(value)
          .then((res) => {
            if (res.succeeded) {
              onItemClick(res.data);
            }
          })
          .catch((e) => {
            showNotification({
              message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
              variant: "error",
            });
          });
      }
    }
  }, [onItemClick, showNotification]);

  const onDeleteConfirm = useCallback(async () => {
    if (!contract?.id) {
      return;
    }
    try {
      setPending(true);
      const response = await deleteSmartContract(contract.id);
      if (response && response.data) {
        setContract(null);
        setShowDialog(false);
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }
      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [contract, showNotification]);

  const leftControls = useMemo(
    () => [
      <Button
        text="Создать smart-договор"
        startIcon={IconPlus20}
        onClick={onClickAdd}
      />,
    ],
    [onClickAdd]
  );

  const onFilterChange = useCallback(
    (value: any, key: string) => {
      const updatedFilters = { ...filterParams, [key]: value };
      setFilterParams(updatedFilters);
      localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(updatedFilters));
    },
    [filterParams]
  );

  const onFilterClear = useCallback(() => {
    const clearedFilters = JSON.parse(initialFiltersParams);
    setFilterParams(clearedFilters);
    localStorage.removeItem(FILTER_STORAGE_KEY);
  }, []);

  const filtersValue = useDebounce(
    useMemo(
      (): FilterValuesProps => ({
        Numbers: filterParams.numbers,
        CustomerIds: filterParams.companies?.map((item) => item.id) || [],
        PartnerIds: filterParams.partners?.map((item) => item.id) || [],
        ProjectIds: filterParams.projects?.map((item) => item.id) || [],
        StatusIds: filterParams.statuses?.map((item) => item.id) || [],
        isAdditional: filterParams.isAdditional?.value,
        EContractTemplateTypeIds:
          filterParams.vehicleTypes?.map((item) => item.id) || [],
        ContractDateMin: filterParams.startDate
          ? dateFormat(filterParams.startDate, "yyyy-MM-dd")
          : undefined,
        ContractDateMax: filterParams.endDate
          ? dateFormat(filterParams.endDate, "yyyy-MM-dd")
          : undefined,
      }),
      [filterParams]
    ),
    600
  );

  const filters = useMemo(
    () => [
      <Combobox
        label="Номер"
        name="numbers"
        compliant={true}
        values={filterParams.numbers}
        onChange={onFilterChange}
      />,
      <Combobox<Company>
        label="Заказчик"
        name="companies"
        values={filterParams.companies}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
        onChange={onFilterChange}
      />,
      <Combobox<Company>
        label="Исполнитель"
        name="partners"
        values={filterParams.partners}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
        onChange={onFilterChange}
      />,
      <Combobox<Project>
        label="Проект"
        name="projects"
        values={filterParams.projects}
        loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
        onChange={onFilterChange}
      />,
      <Combobox<DefaultObject<SmartContractStatus>>
        label="Статус"
        name="statuses"
        values={filterParams.statuses}
        options={enumToArray(SmartContractStatusName)}
        onChange={onFilterChange}
      />,
      <Combobox<DefaultObject<SmartContractVehicleType>>
        label="Тип техники"
        name="vehicleTypes"
        values={filterParams.vehicleTypes}
        options={enumToArray(SmartContractVehicleTypeName)}
        onChange={onFilterChange}
      />,
      <DateTimePicker
        label="Дата начала"
        name="startDate"
        onChange={onFilterChange}
        value={filterParams.startDate}
        hideTime
      />,
      <DateTimePicker
        label="Дата завершения"
        name="endDate"
        onChange={onFilterChange}
        value={filterParams.endDate}
        hideTime
      />,
      <Select<boolean>
        label="Тип договора"
        name="isAdditional"
        onChange={onFilterChange}
        options={[
          { label: "Все", value: undefined },
          { label: "Основной договор", value: false },
          { label: "Доп. соглашение", value: true },
        ]}
        value={filterParams.isAdditional}
      />,
    ],
    [
      filterParams.companies,
      filterParams.endDate,
      filterParams.isAdditional,
      filterParams.numbers,
      filterParams.partners,
      filterParams.projects,
      filterParams.startDate,
      filterParams.statuses,
      filterParams.vehicleTypes,
      onFilterChange,
      user,
    ]
  );

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <LayoutDefault title="Smart-договоры">
      <TemplateSearch<SmartContract>
        toolbarProps={{
          leftControls: leftControls,
          searchPlaceholder: "№ договора, организация, проект, контрагент",
        }}
        filterProps={{
          filters,
          filterParams: filtersValue,
          onClear: onFilterClear,
        }}
        getData={getSmartContracts}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        onClick={onItemClick}
        refreshEventName={
          NotificationTypeNames[NotificationTypes.ContractCreated]
        }
      />
      <ModalSmartContractDetail
        open={modalVisible}
        data={contract as SmartContract}
        onClose={onModalClose}
      />
      <Dialog
        open={showDialog}
        title={"Удалить договор?"}
        subtitle={`Договор №${contract?.number} будет удален`}
        loading={pending}
        icon={IconTrash24}
        onConfirm={onDeleteConfirm}
        onClose={() => {
          setShowDialog(false);
        }}
      />
    </LayoutDefault>
  );
}

export default memo(PageSmartContracts);
