import {
  addMinutes,
  format,
  lastDayOfMonth,
  parseISO,
  startOfMonth,
} from "date-fns";

export function dateFormat(
  date: string | Date | null,
  dateFormat: string
): string {
  try {
    if (!date) {
      return "—";
    }
    if (typeof date === "string") {
      return format(new Date(date), dateFormat);
    }

    return format(date, dateFormat);
  } catch (e) {
    return "Неверный формат даты";
  }
}

export function dateFormatWithTimezone(
  date: string | Date | null,
  dateFormat: string
): string {
  try {
    if (!date) {
      return "—";
    }

    let parsedDate: Date;

    if (typeof date === "string") {
      const timezoneOffset = date.slice(-6); // Извлечь часовой пояс (например, "+05:00" или "-03:00")
      const [hoursOffset, minutesOffset] = timezoneOffset
        .split(":")
        .map((part) => parseInt(part, 10));
      const totalMinutesOffset = hoursOffset * 60 + minutesOffset;

      parsedDate = addMinutes(parseISO(date), totalMinutesOffset);
    } else {
      parsedDate = date;
    }

    return format(parsedDate, dateFormat);
  } catch (e) {
    return "Неверный формат даты";
  }
}

export function dateFormatOffset(
  date: string | Date | null | undefined,
  dateFormat = "dd.MM.yyyy HH:mm"
): string {
  try {
    if (!date) {
      return "—";
    }

    if (typeof date === "string") {
      const dt = new Date(date);
      const time = dt.getTime();
      // offset in milliseconds
      const offset = dt.getTimezoneOffset() * 60 * 1000;
      return format(time - offset, dateFormat);
    }

    return format(date, dateFormat);
  } catch (e) {
    return "Неверный формат даты";
  }
}

export function dateUTCFormat(
  date: string | Date | null,
  dateFormat: string
): string {
  try {
    if (!date) {
      return "—";
    }

    let adjustedDate;
    if (typeof date === "string") {
      if (date.endsWith("Z")) {
        const parsedDate = new Date(date);
        // -5 hours
        adjustedDate = new Date(parsedDate.getTime() - 5 * 60 * 60 * 1000);
      } else {
        adjustedDate = new Date(date);
      }
    } else {
      adjustedDate = date;
    }

    return format(adjustedDate, dateFormat);
  } catch (e) {
    return "Неверный формат даты";
  }
}

export function dateStatisticFormat(
  date: string | Date | null,
  dateFormat: string
): string {
  try {
    if (!date) {
      return "—";
    }

    let adjustedDate;
    if (typeof date === "string") {
      if (date.endsWith("Z")) {
        const parsedDate = new Date(date);
        // -5 hours
        adjustedDate = new Date(parsedDate.getTime() - 5 * 60 * 60 * 1000);
      } else {
        adjustedDate = new Date(date);
      }
    } else {
      adjustedDate = date;
    }

    return format(adjustedDate, dateFormat);
  } catch (e) {
    return "Неверный формат даты";
  }
}

export const getFirstDayOfMonth = (date: Date = new Date()) => {
  return startOfMonth(date);
};
export const getLastDayOfMonth = (date: Date = new Date()) => {
  return lastDayOfMonth(date);
};

export const attachTimeAndDate = (dateVal: Date, timeVal: string): string => {
  let date = new Date(dateVal.getTime());
  const timeParts = timeVal.split(":");
  date.setHours(Number(timeParts[0]), Number(timeParts[1]), 0, 0);
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

  return date.toJSON();
};
