import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { VehicleRequestOffer, VehicleRequestOfferStatus } from "@app/models";
import {
  Button,
  DocFile,
  Grid,
  Info,
  Modal,
  ModalProps,
  ModalTitle,
  Tabs,
} from "@app/components";
import { currencyFormat, dateFormat, getDictionaryName } from "@app/helpers";
import styled from "styled-components";
import { getVehicleRequestOfferById } from "@app/api";
import { VReqOfferStatusChip } from "@app/common";

interface Props extends Omit<ModalProps, "title"> {
  data: VehicleRequestOffer;
  onEdit: (item: VehicleRequestOffer) => void;
  onDelete: (item: VehicleRequestOffer) => void;
}

const StyledContainer = styled.div`
  padding: 24px;
`;

function InformationTab(props: Pick<Props, "data">) {
  const { data: offer } = props;
  return (
    <Grid columns={3} style={{ marginTop: "16px" }}>
      <Info
        variant={"secondary"}
        label="Организация"
        value={getDictionaryName(offer.request.organization, "-").replace(
          "Товарищество с ограниченной ответственностью",
          ""
        )}
      />
      <Info
        variant={"secondary"}
        label="Характеристика"
        value={getDictionaryName(offer.request.characteristics, "-")}
      />
      <Info
        variant={"secondary"}
        label="Дата начала"
        value={dateFormat(offer.request.startDate, "dd.MM.yyyy HH:mm")}
      />
      <Info
        variant={"secondary"}
        label="Проект"
        value={getDictionaryName(offer.project, "-")}
      />
      <Info
        variant={"secondary"}
        label="Смена"
        value={getDictionaryName(offer.request.workShift, "-")}
      />
      <Info
        variant={"secondary"}
        label="Дата завершения"
        value={dateFormat(offer.request.endDate, "dd.MM.yyyy HH:mm")}
      />
      <Info
        variant={"secondary"}
        label="Вид техники"
        value={getDictionaryName(offer.request.vehicleType, "-")}
      />
      <Info
        variant={"secondary"}
        label="Объем работ"
        value={offer.request.volume.toString()}
      />
      <Info
        variant={"secondary"}
        label="Локация"
        value={offer.project.region?.name || "—"}
      />
      <Info
        variant={"secondary"}
        label="Вид услуги"
        value={getDictionaryName(offer.request.serviceType, "-")}
      />
      <Info
        variant={"secondary"}
        label="Единица измерения работ"
        value={getDictionaryName(offer.request.unit, "-")}
      />
      <Info
        variant={"secondary"}
        label="Комментарий"
        value={offer.request.comments || "—"}
      />
    </Grid>
  );
}

function ContactsTab(props: Pick<Props, "data">) {
  const { data: offer } = props;
  return (
    <Grid columns={3} style={{ marginTop: "16px" }}>
      <Info variant={"secondary"} label="Начальник участка" value={"—"} />
      <Info
        variant={"secondary"}
        label="Менеджер"
        value={offer.request.confirmer.phone || "—"}
      />
    </Grid>
  );
}

const MyVehicleOfferDetail = (props: Props) => {
  const { data, onEdit, onDelete, ...restProps } = props;

  const [offer, setOffer] = useState<VehicleRequestOffer>(data);

  const tabLabels = useMemo<string[]>(() => ["Информация", "Контакты"], []);

  useEffect(() => {
    getVehicleRequestOfferById(data.id).then((res) => {
      if (res.succeeded) {
        setOffer(res.data);
      }
    });
  }, [data.id]);

  const onEditClick = useCallback(() => onEdit(data), [data, onEdit]);

  const onDeleteClick = useCallback(() => onDelete(data), [data, onDelete]);

  const modalTitle = useMemo(() => {
    if (!offer) {
      return "Загружается...";
    }
    return (
      <ModalTitle text={`Заявка на технику №${offer.number}`}>
        <VReqOfferStatusChip status={offer.status} />
      </ModalTitle>
    );
  }, [offer]);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];

    if (!offer) {
      return returnActions;
    }

    // TODO: возможно статусы не совпадают
    if (offer.status === VehicleRequestOfferStatus.UnderConsideration) {
      returnActions.push(
        <Button text="Редактировать" variant="outlined" onClick={onEditClick} />
      );
    }

    returnActions.push(
      <Button text="Удалить" variant="text" onClick={onDeleteClick} />
    );

    return returnActions;
  }, [offer, onDeleteClick, onEditClick]);

  return (
    <Modal
      title={modalTitle}
      size="large"
      dense={true}
      headerContent={
        <Tabs labels={tabLabels} transparent>
          <InformationTab data={offer} />
          <ContactsTab data={offer} />
        </Tabs>
      }
      actions={actions}
      {...restProps}
    >
      <StyledContainer>
        <Grid gap={16}>
          <h6>Мое предложение</h6>
          <Info label="Компания" value={getDictionaryName(data.company)} />
          <Info label="Техника" value={getDictionaryName(offer.vehicle)} />
          <Info
            label="Цена"
            value={currencyFormat(
              offer.nds.isNds ? offer.price : offer.priceWithoutNds
            )}
          />
          <Info label="Ставка НДС" value={offer?.nds.name} />
          <Info label="Примечание" value={offer.request.comments} />
          {offer.files?.map((_file, fileIndex) => (
            <DocFile key={fileIndex.toString()} file={null} />
          ))}
        </Grid>
      </StyledContainer>
    </Modal>
  );
};

export default memo(MyVehicleOfferDetail);
