import React, { memo } from "react";
import "./styles.scss";
import styled, { css } from "styled-components";
import { ifProp, switchProp, theme } from "styled-tools";

export type ChipColor =
  | "default"
  | "primary"
  | "primaryDark"
  | "success"
  | "danger"
  | "info"
  | "yellow"
  | "warning";

export type ChipVariant = "default" | "secondary";
export type TextSize = "xs" | "sm" | "md" | "xl" | "extra";

interface Props {
  text: string;
  color?: ChipColor;
  variant?: ChipVariant;
  textSize?: TextSize;
  className?: string;
  bold?: boolean;
}

const StyledChip = styled.span<Pick<Props, "color" | "variant">>`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  ${switchProp("variant", {
    default: css`
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      border-radius: 4px;
      padding: 6px 8px;

      &::before {
        background-color: ${switchProp("color", {
          default: theme("color.grayLight"),
          primary: theme("color.blue"),
          primaryDark: theme("color.dark"),
          info: theme("color.blueGreen"),
          success: theme("color.green"),
          danger: theme("color.red"),
          warning: theme("color.orangeLight"),
          yellow: theme("color.yellowAccent"),
        })};
      }
    `,
    secondary: css`
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      border-radius: 56px;
      padding: 6px 8px;
      border: 1px solid ${theme("color.gray")};

      &::before {
        background-color: ${switchProp("color", {
          default: theme("color.white"),
          primary: theme("color.blue"),
          primaryDark: theme("color.dark"),
          info: theme("color.blueGreen"),
          success: theme("color.green"),
          danger: theme("color.red"),
          warning: theme("color.orangeLight"),
          yellow: theme("color.yellowAccent"),
        })};
        opacity: 0.3;
      }
    `,
  })}
`;

const StyledChipText = styled.span<
  Pick<Props, "color" | "variant" | "textSize"> & {
    bold: boolean;
  }
>`
  position: relative;
  font-size: ${switchProp("textSize", {
    xs: "12px",
    sm: "14px",
    md: "16px",
    xl: "20px",
    extra: "30px",
  })};
  line-height: ${switchProp("textSize", {
    xl: "20px",
    extra: "30px",
  })};
  font-weight: ${ifProp("bold", 600, 400)};

  color: ${switchProp("variant", {
    default: switchProp(
      "color",
      {
        yellow: theme("color.dark"),
        default: theme("color.dark"),
      },
      theme("color.white")
    ),
    secondary: ifProp(
      {
        color: "default",
      },
      theme("color.grayDark"),
      theme("color.dark")
    ),
  })};
`;

function Chip(props: Props) {
  const {
    text,
    color = "default",
    variant = "default",
    textSize = "xs",
    className = "",
    bold = false,
  } = props;

  return (
    <StyledChip color={color} variant={variant} className={className.trim()}>
      <StyledChipText
        color={color}
        variant={variant}
        bold={bold}
        textSize={textSize}
      >
        {text}
      </StyledChipText>
    </StyledChip>
  );
}

export default memo(Chip);
