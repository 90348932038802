import React, { memo } from "react";
import styled from "styled-components";
import { Container } from "@app/components";
import { theme } from "styled-tools";
import Partners1Src from "./partners-1.png";
import Partners2Src from "./partners-2.png";
import Partners3Src from "./partners-3.png";
import PartnersMobile from "./partners-mobile.png";
import SectionTitle from "../SectionTitle";
import { useWindowWide } from "@app/hooks";

const StyledSection = styled.section`
  padding: 100px 0;
  box-sizing: border-box;

  @media only screen and (max-width: 576px) {
    padding: 32px 0;
  }
`;

const StyledTitle = styled(SectionTitle)`
  text-align: center;
  margin: 0 0 32px 0;

  @media only screen and (max-width: 576px) {
    margin: 0 0 16px 0;
  }
`;

const StyledText = styled(SectionTitle)`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 60px 0;
  color: ${theme("color.grayDark")};
`;

const StyledGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  justify-content: center;

  img {
    width: 100%;
  }
`;

function SectionPartners() {
  const isMobile = useWindowWide(576);

  if (isMobile) {
    return (
      <StyledSection>
        <Container>
          <StyledTitle>Компании, которые уже используют Dispatch</StyledTitle>
          <StyledText>Уже более 25+ компаний испытали эффективность</StyledText>
          <img src={PartnersMobile} width="100%" alt="" />
        </Container>
      </StyledSection>
    );
  }

  return (
    <StyledSection>
      <Container>
        <StyledTitle>Компании, которые уже используют Dispatch</StyledTitle>
        <StyledText>Уже более 25+ компаний испытали эффективность</StyledText>
        <StyledGrid>
          <img src={Partners1Src} alt="" />
          <img src={Partners2Src} alt="" />
          <img src={Partners3Src} alt="" />
        </StyledGrid>
      </Container>
    </StyledSection>
  );
}

export default memo(SectionPartners);
