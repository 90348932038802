import { excludeVAT, MONTH_UNIT_ID } from "./index";
import { getDaysInMonth } from "date-fns";
import {
  ActVehicleItemV2,
  NdsShort,
  VehicleJournalForActV2,
} from "@app/models";

export const actTableLabels = [
  "",
  "№",
  "Дата",
  "Смена",
  "Время работы",
  "Вид техники",
  "Характеристика",
  "Вид услуги",
  "Гос номер",
  "Данные линии",
  "Моточасы GPS",
  "Длительность смены по GPS",
  "Ед. изм.",
  "Цена аренды",
  "Данные к оплате",
  "Сумма",
  "Сумма НДС",
  "Итого к оплате",
  "Комментарий",
  "",
];

export const getVehicleItemsTotal = (
  items: ActVehicleItemV2[] | null,
  ndsRate: NdsShort["rate"]
) => {
  return (items || []).map((item) => {
    let sum = item.toPay * (item.price || 0);

    if (item.unitDto?.id === MONTH_UNIT_ID) {
      const days = getDaysInMonth(new Date(item.workDate));
      const toPayPerDay = days ? (item.price || 0) / days : 0;
      sum = item.toPay * toPayPerDay;
    }

    item.summa = sum;
    item.summaNds = excludeVAT(sum, ndsRate);
    item.total = sum;

    return {
      ...item,
    };
  });
};

export const getActVehicleFiles = (actVehicles: VehicleJournalForActV2[]) => {
  const itemsMap = actVehicles.flatMap((item) => item.items);
  return itemsMap.flatMap((item) => item?.files ?? []);
};
