import { memo } from "react";
import styled from "styled-components";
import { Container } from "@app/components";
import { IconMail24, IconPhone24 } from "@app/icons";
import IconMail from "./mail-send.svg";
import { theme } from "styled-tools";
import { contactsInfo } from "@app/helpers";

const StyledSection = styled.section`
  display: flex;
  flex-direction: row;

  padding: 32px;
  margin: 60px 0 100px;
  gap: 60px;
  border-radius: 24px;

  box-sizing: border-box;
  background: linear-gradient(90deg, #6174da 20%, #9661da 100%);

  @media only screen and (max-width: 576px) {
    margin: 16px 0;
  }
`;

const SectionImage = styled.img`
  margin-right: 84px;

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const SectionInfoRow = styled.div`
  max-width: 216px;
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const SectionInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  color: ${theme("color.white")};

  p {
    font-weight: 600;
  }
`;

const StyledTitle = styled.h1`
  margin: 0 0 20px;
  font-weight: 500;
  text-transform: uppercase;

  @media only screen and (max-width: 576px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const StyledText = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: unset;
  color: ${theme("color.white")};

  &:hover {
    text-decoration: underline;
  }
`;

function SectionPartnership() {
  return (
    <Container>
      <StyledSection id="section-partnership">
        <SectionInfo>
          <StyledTitle>Контакты для сотрудничества</StyledTitle>
          <SectionInfoRow>
            {contactsInfo.map((item, index) => (
              <SectionInfo key={index.toString()}>
                <p>{item.name}</p>
                <StyledText href={`tel:${item.phone}`}>
                  <IconPhone24 />
                  {item.phone}
                </StyledText>
                <StyledText href={`mailto:${item.email}`}>
                  <IconMail24 />
                  {item.email}
                </StyledText>
              </SectionInfo>
            ))}
          </SectionInfoRow>
        </SectionInfo>
        <SectionImage src={IconMail} alt="" />
      </StyledSection>
    </Container>
  );
}

export default memo(SectionPartnership);
