import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  VehicleRequest,
  VehicleRequestOffer,
  VehicleRequestOfferStatus,
  VehicleRequestStatus,
} from "@app/models";
import { getVehicleRequestOffers, PageableParams } from "@app/api";
import VehicleRequestDetail from "../VehicleRequestDetail";
import { TemplateSearch } from "@app/templates";
import styled from "styled-components";
import { Button, Checkbox, Table } from "@app/components";
import {
  dateFormat,
  getDictionaryName,
  getFullName,
  isDispatcher,
  priceFormat,
} from "@app/helpers";
import { useUser } from "@app/providers";
import { IconMore24, IconPencil24 } from "@app/icons";
import { ModalVehicleRequestOfferEdit } from "@app/modals";
import { theme } from "styled-tools";

interface Props {
  request: VehicleRequest;
  selectedOffer: VehicleRequestOffer | null;
  onSelectOffer: (offer: VehicleRequestOffer) => void;
  onClickAddOffer: () => void;
}

const StyledSearchTemplateWrapper = styled.div`
  height: 100%;
  padding: 0 8px 16px 8px;
`;

const StyledTitle = styled.p`
  margin-top: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.dark")};
`;

const StyledModalWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledModal = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 25px;
  left: -110px;
  background-color: white;
  border: none;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

function VehicleRequestOffers(props: Props) {
  const { user } = useUser();
  const { request, selectedOffer, onSelectOffer, onClickAddOffer } = props;
  const [isModalVisible, setModalVisible] = useState(false);
  const [requestOfferEditModalVisible, setRequestOfferEditModalVisible] =
    useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [historyData, setHistoryData] = useState<string[][]>([]);

  const onClickOffer = useCallback(
    (offer: VehicleRequestOffer) => {
      onSelectOffer(offer);
    },
    [onSelectOffer]
  );

  const onClickEdit = useCallback(() => {
    setRequestOfferEditModalVisible(true);
  }, []);

  const closeEditModal = useCallback(() => {
    setRequestOfferEditModalVisible(false);
  }, []);

  const onClickMoreIcon = useCallback((item: any) => {
    setSelectedItem(item);
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    },
    [closeModal]
  );

  const onSuccessUpdateVehicleRequestOffer = useCallback(() => {
    setRequestOfferEditModalVisible(false);
    window.location.reload();
  }, []);

  useEffect(() => {
    if (isModalVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalVisible, handleClickOutside]);

  const mapOffersTableData = useCallback(
    (item: VehicleRequestOffer) => {
      return [
        <Checkbox
          editable={false}
          type="radio"
          checked={
            (!!selectedOffer && item.id === selectedOffer.id) ||
            item.status === VehicleRequestOfferStatus.Selected
          }
        />,
        getDictionaryName(item.company, "-"),
        "-",
        item.vehicle.govNumber || "-",
        !!item.priceWithoutNds
          ? priceFormat(item.priceWithoutNds.toString())
          : "-",
        getDictionaryName(item.vehicle.gps, "-"),
        priceFormat(item.price.toString()),
        getDictionaryName(item.nds, "-"),
        item.comments || "-",
        item.status === VehicleRequestOfferStatus.UnderConsideration ||
        request.status === 3 ? (
          <StyledModalWrapper>
            <IconMore24 onClick={() => onClickMoreIcon(item)} />
            {isModalVisible && selectedItem.id === item.id && (
              <StyledModal ref={modalRef} onClick={onClickEdit}>
                <IconPencil24 />
                Редактировать
              </StyledModal>
            )}
          </StyledModalWrapper>
        ) : null,
      ];
    },
    [
      isModalVisible,
      onClickEdit,
      onClickMoreIcon,
      request.status,
      selectedItem,
      selectedOffer,
    ]
  );

  const getData = useCallback(
    async (params: PageableParams) => {
      const data = await getVehicleRequestOffers({
        ...params,
        pageNumber: 1,
        pageSize: 50,
        searchText: "",
        requestId: request.id,
      });
      return data;
    },
    [request]
  );

  const leftControls = useMemo<ReactNode[]>(() => {
    if (
      [
        VehicleRequestStatus.AwaitingConfirmation,
        VehicleRequestStatus.AwaitingRequest,
      ].indexOf(request.status) > -1 &&
      isDispatcher(user!.role)
    ) {
      return [
        <Button
          text="Добавить предложение"
          onClick={onClickAddOffer}
          size="small"
        />,
      ];
    }

    return [];
  }, [request, onClickAddOffer, user]);

  useEffect(() => {
    setTimeout(() => {
      const data = request.histories.map((history) => [
        dateFormat(history.created, "dd.MM.yyyy HH:mm"),
        history.text,
        getFullName(history.user),
      ]);
      setHistoryData(data);
      setLoading(false);
    }, 1000);
  }, [request.histories]);

  const tableLabels = useMemo<string[]>(
    () => [
      "",
      "Абонент",
      "Рейтинг",
      "Гос номер",
      "Цена без НДС",
      "Провайдер GPS",
      "Цена с НДС",
      "Ставка НДС",
      "Примечание",
      "",
    ],
    []
  );

  return (
    <>
      <VehicleRequestDetail
        columns={5}
        request={request}
        variant="secondary"
        hideHistory={true}
      />
      <StyledSearchTemplateWrapper>
        <>
          <StyledTitle>История согласования</StyledTitle>
          <Table
            labels={["Дата выполнения", "Статус", "ФИО"]}
            data={historyData}
          />
        </>
        <TemplateSearch<VehicleRequestOffer>
          shadow={false}
          tableLabels={tableLabels}
          getData={getData}
          mapTableData={mapOffersTableData}
          searchBar={false}
          onClick={onClickOffer}
          toolbarProps={{
            title: "Ценовые предложения",
            leftControls,
          }}
        />
      </StyledSearchTemplateWrapper>
      <ModalVehicleRequestOfferEdit
        hideInfo={true}
        onSuccess={onSuccessUpdateVehicleRequestOffer}
        offer={selectedItem}
        request={request}
        open={requestOfferEditModalVisible}
        onClose={closeEditModal}
      />
    </>
  );
}

export default memo(VehicleRequestOffers);
